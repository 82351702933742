import React, { useEffect } from "react";
import CompetitionsGrid from "./competitions-grid";

export default function CompetitionsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="collections-page" style={{ position: "relative" }}>
      <div id="stars"></div>

      <CompetitionsGrid />
    </div>
  );
}

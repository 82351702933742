import { createRef } from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";

import RegHelper from "../../../utils/registrationHelper";
import RegisterButton from "./register-button";
import RegisterSection from "./register-section";
import ManageTeamSection from "./manage-section";

import prizepool from "../../../assets/svg/prizepool.svg";
import teamsize from "../../../assets/svg/teamsize.svg";
import location from "../../../assets/svg/location.svg";
import "./index.css";

const CompetitionPopup = ({ competition, closeFunc }) => {
   const [activeSection, setActiveSection] = useState("About");
   const [hasRegistered, setHasRegistered] = useState(null);
   let divRef = createRef();

   useEffect(() => {
      // To reset scroll when stack content is changed
      divRef.current.scrollTop = 0;

      RegHelper.asyncHasRegisteredForEvent(competition.id).then((res) => {
         setHasRegistered(res);
      });
   });

   var entry = competition.entryFee + " /-";
   var size;
   var registerationClosesText = "Registration closes on ";
   if (competition.entryFee === null) {
      entry = "Free";
   }
   if (competition.teamSize === null) {
      size = "Individual";
   } else if (competition.teamSize === 100) {
      size = "No limit";
      registerationClosesText = "";
   } else {
      size = competition.teamSize + " team members";
   }

   const AboutSection = () => {
      return (
         <div>
            <div className='about-section section'>{competition.about.split(".").slice(1).join(".")}</div>
            {competition.registrationEndDate && (
               <>
                  {" "}
                  {competition.registrationOpen ? (
                     <div className='about-section section'>
                        <i>
                           {registerationClosesText && (
                              <>
                                 {registerationClosesText} {dayjs(competition.registrationEndDate).format("D MMM")}
                              </>
                           )}
                        </i>
                     </div>
                  ) : (
                     <></>
                  )}
               </>
            )}
            <div className='about-extra '>
               <div className='about-extra-content'>
                  {competition.datetime && (
                     <span className='date'>
                        <div className='about-extra-header'>Date</div>
                        <span>{dayjs(competition.datetime).format("D MMM")}</span>
                     </span>
                  )}
               </div>
               <div className='about-extra-content'>
                  {competition.datetime && (
                     <span className='date'>
                        <div className='about-extra-header'>Time</div>
                        <span>{dayjs(competition.datetime).format("hh:mm a")}</span>
                     </span>
                  )}
               </div>
               <div className='about-extra-content'>
                  {
                     <span className='venue'>
                        <div className='about-extra-header'>Entry Fee</div>
                        <span>{entry}</span>
                     </span>
                  }
               </div>
            </div>
         </div>
      );
   };

   const FormatSection = () => {
      return <div className='format-section section '> {competition.format ? competition.format : "Coming soon"}</div>;
   };

   const RulesSection = () => {
      return <div className='rules-section section '> {competition.rules ? competition.rules : "Coming soon"}</div>;
   };

   const ContactSection = () => {
      return (
         <div className='contact-section section'>
            {competition.eventHead1 !== null && (
               <div>
                  <span className='name'>{competition.eventHead1.name}</span>
                  {/* <a className="email" href={"mailto:" + competition.eventHead1.email}>{competition.eventHead1.email}</a> */}
                  <span className='role'> Event Head</span>
                  <a style={{ textDecoration: "none" }} href={"mailto:" + competition.eventHead1.email}>
                     <span className='email phone'>{competition.eventHead1.email}</span>
                  </a>
                  <a style={{ textDecoration: "none" }} href={"tel:" + competition.eventHead1.phoneNumber}>
                     <span className='phone'>{competition.eventHead1.phoneNumber}</span>
                  </a>
               </div>
            )}
            {competition.eventHead2 !== null && (
               <div className='eventHead2'>
                  <span className='name'>{competition.eventHead2.name}</span>
                  {/* <a className="email" href={"mailto:" + competition.eventHead2.email}>{competition.eventHead2.email}</a> */}
                  <span className='role'> Event Head</span>
                  <a style={{ textDecoration: "none" }} href={"mailto:" + competition.eventHead2.email}>
                     <span className='email phone'>{competition.eventHead2.email}</span>
                  </a>
                  <a style={{ textDecoration: "none" }} href={"tel:" + competition.eventHead2.phoneNumber}>
                     <span className='phone'>{competition.eventHead2.phoneNumber}</span>
                  </a>
               </div>
            )}
         </div>
      );
   };

   const ResultsSection = () => {
      return <div className='rules-section section '> {competition.result ? competition.result : "Not yet published"}</div>;
   };

   return (
      <div data-aos='fade-up' className='popup-bg'>
         <div className='popup-content-container'>
            <div className='header-container'>
               <div className='row'>
                  <div className='col-md-3 col-sm-10'>
                     <div className='image-title-box'>
                        <img className='competition-image' src={competition.icon} alt={competition.name} height='100' />
                        {/* <img className='competition-image' src={excel2021_logo} alt={competition.name} /> */}
                     </div>
                  </div>
                  <div className='col-md-6 col-sm-10 competition-tile-div text-center'>
                     <h2 className='competition-name'>{competition.name}</h2>
                     <span className='competition-category'>{competition.category.replace(/_/g, " ").toUpperCase()}</span>
                  </div>
                  <div className='col-lg-3 col-12 competition-tile-div '>
                     <div className='close-container' onClick={closeFunc}>
                        <svg className='close-button' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 252 252'>
                           <path d='M126 0C56.523 0 0 56.523 0 126s56.523 126 126 126 126-56.523 126-126S195.477 0 126 0zm0 234c-59.551 0-108-48.449-108-108S66.449 18 126 18s108 48.449 108 108-48.449 108-108 108z' />
                           <path d='M164.612 87.388a9 9 0 00-12.728 0L126 113.272l-25.885-25.885a9 9 0 00-12.728 0 9 9 0 000 12.728L113.272 126l-25.885 25.885a9 9 0 006.364 15.364 8.975 8.975 0 006.364-2.636L126 138.728l25.885 25.885c1.757 1.757 4.061 2.636 6.364 2.636s4.606-.879 6.364-2.636a9 9 0 000-12.728L138.728 126l25.885-25.885a9 9 0 00-.001-12.727z' />
                        </svg>
                     </div>
                     <div className='about-extra '>
                        <div className='about-extra-content '>
                           {competition.prizeMoney && (
                              <div className='prize-money'>
                                 <img src={prizepool} width='22' height='22' alt='' />
                                 <span className='about-extra-header'> {competition.prizeMoney}/- prize pool</span>
                              </div>
                           )}
                        </div>

                        <div className='about-extra-content '>
                           <div className='prize-money'>
                              <img src={teamsize} width='22' height='22' alt='' />
                              <span className='about-extra-header'> {size} </span>
                           </div>
                        </div>
                        <div className='about-extra-content '>
                           <div className='prize-money'>
                              <img src={location} width='22' height='22' alt='' />
                              <span className='about-extra-header'> {competition.venue} </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className='reg-prize-box text-center'>
                  {competition.registrationLink ? (
                     <button className='register-button'>
                        <a target='_blank' style={{ textDecoration: "none", color: "white" }} href={competition.registrationLink}>
                           {competition.button}
                        </a>
                     </button>
                  ) : (
                     <RegisterButton competition={competition} hasRegistered={hasRegistered} setHasRegistered={setHasRegistered} setActiveSection={setActiveSection} />
                  )}
               </div>

               <hr className='competition-popup-hr' />
            </div>

            <div className='popup-content-rows'>
               <div className='col-lg-3 col-12'>
                  <div className='popup-sidebar'>
                     <div className='popup-stack-switcher'>
                        <ul className='popup-nav'>
                           <li className={activeSection === "About" ? "active" : ""} onClick={() => setActiveSection("About")}>
                              About
                           </li>
                           <li className={activeSection === "Format" ? "active" : ""} onClick={() => setActiveSection("Format")}>
                              Format
                           </li>
                           <li className={activeSection === "Rules" ? "active" : ""} onClick={() => setActiveSection("Rules")}>
                              Rules
                           </li>
                           <li className={activeSection === "Contact" ? "active" : ""} onClick={() => setActiveSection("Contact")}>
                              Contact
                           </li>
                           <li className={activeSection === "Results" ? "active" : ""} onClick={() => setActiveSection("Results")}>
                              Results
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div className='col-lg-9 col-12'>
                  <div className='popup-stack' ref={divRef}>
                     {
                        {
                           About: <AboutSection />,
                           Format: <FormatSection />,
                           Rules: <RulesSection />,
                           Contact: <ContactSection />,
                           Register: <RegisterSection competition={competition} setHasRegistered={setHasRegistered} setActiveSection={setActiveSection} />,
                           Manage: <ManageTeamSection competition={competition} />,
                           Results: <ResultsSection />,
                        }[activeSection]
                     }
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CompetitionPopup;

import dayjs from "dayjs";
import React, { useEffect, useState, useRef } from "react";
import "./ScheduleCard.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
// import Aos from "aos";
// import "aos/dist/aos.css";

import excelIcon from "../../assets/svg/excel2021.svg";

const FlipScheduleImage = ({ event }) => {
   const [isLoading, setIsLoading] = useState(true);
   const img = new Image();

   useEffect(() => {
      img.src = event.icon;
      img.onload = () => setIsLoading(false);
   });

   if (isLoading) return <img className='scheduleflipIcon' src={excelIcon} alt={event.name} />;

   return (
      <div className='scheduleflipIconbg '>
         <img className='scheduleflipIcon' src={event.icon} alt={event.name} />
      </div>
   );
};

const ScheduleCard = ({ event }) => {
   const [isVisible, setVisible] = useState(true);
   const ScheduleRef = useRef();
   useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
         entries.forEach((entry) => setVisible(entry.isIntersecting));
      });
      observer.observe(ScheduleRef.current);
   }, []);
   return (
      <div className='shedule__container'>
         <div className={`scheduleCardContainer purecss-zoom-in-section ${isVisible ? "is-visible" : ""}`} ref={ScheduleRef}>
            <FlipScheduleImage event={event} />
            <div className='scheduleContent '>
               <div className='scheduleCardTitle '>{event.name}</div>
               <div className='scheduleCardRound '>{event.round}</div>
            </div>
            <div className='scheduleCardTime'>
               <span>{dayjs(event.datetime).format("D MMM")}</span>
               <div className='time'>{dayjs(event.datetime).format("h:mm a")}</div>
            </div>
            <br /> <br />
            <div className='outerVenue '>
               <div className='scheduleCardVenue'> {useWindowDimensions().width > 600 ? event.venue : ""} </div>
            </div>
         </div>
         <hr />
      </div>
   );
};

export default ScheduleCard;

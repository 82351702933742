import { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import "../competition-card/index.css";
import "../competition-card/card_flip.css";
import CompetitionPopup from "../competition-popup";
import excelIcon from "../../../assets/svg/excel2021.svg";

import Aos from "aos";
import "aos/dist/aos.css";

const FlipImage = ({ competition }) => {
   const [isLoading, setIsLoading] = useState(true);
   const img = new Image();

   useEffect(() => {
      img.src = competition.icon;
      img.onload = () => setIsLoading(false);
   });

   if (isLoading) return <img src={excelIcon} alt={competition.name} />;

   return (
      <div>
         <img className='eventCardImg' height='100' width='100' src={competition.icon} alt={competition.name} />
      </div>
   );
};

const CompetitionCard = ({ competition }) => {
   let category;
   if (competition.category == "cs_tech") {
      category = "CS Tech";
   } else {
      category = competition.category.replace(/_/g, " ");
   }
   useEffect(() => {
      Aos.init({ duration: 1000 });
   }, []);
   const [open, setOpen] = useState(false);

   return (
      <div data-aos='zoom-in' className='competition-card-container'>
         <div className='card-container2 competition-card'>
            <div className='card'>
               <div className='side'>
                  {" "}
                  <div className='icon'>
                     <FlipImage competition={competition} />
                  </div>
                  <div className='title'>
                     <span>{competition.name}</span>
                  </div>
                  <div className='category'>
                     <span>{category}</span>
                  </div>
               </div>
               <div className='side back' onClick={() => setOpen(true)}>
                  <p className='card-flip-title'>
                     {" "}
                     <b>{competition.name}</b>
                  </p>
                  {competition.about.split(".")[0]}.
               </div>
            </div>
         </div>

         <Popup open={open} onClose={() => setOpen(false)} closeOnDocumentClick closeOnEscape nested>
            <CompetitionPopup open={open} competition={competition} closeFunc={() => setOpen(false)} />
         </Popup>
      </div>
   );
};

export default CompetitionCard;

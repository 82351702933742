import { useState, createRef, useEffect } from "react";
import dayjs from "dayjs";
import "./index.css";

import prizepool from "../../../assets/svg/prizepool.svg";
import clock from "../../../assets/svg/clock.svg";
import location from "../../../assets/svg/location.svg";

const EventPopup = ({ event, closeFunc }) => {
   const [activeSection, setActiveSection] = useState("About");
   let divRef = createRef();
   var entryFee = event.entryFee + "/-";

   if (event.entryFee === null) {
      entryFee = "Free";
   }

   useEffect(() => {
      // To reset scroll when stack content is changed
      divRef.current.scrollTop = 0;
   });

   const AboutSection = () => {
      return (
         <div>
            <div className='about-section section'>{event.about.split(".").slice(1).join(".")}</div>
         </div>
      );
   };

   const ContactSection = () => {
      return (
         <div className='contact-section section'>
            {event.eventHead1 !== null && (
               <div>
                  <span className='name'>{event.eventHead1.name}</span>
                  <span className='role'> Event Head</span>
                  <a className='email' href={"mailto:" + event.eventHead1.email}>
                     {event.eventHead1.email}
                  </a>
                  <a style={{ textDecoration: "none" }} href={"tel:" + event.eventHead1.phoneNumber}>
                     <span className=' phone'>{event.eventHead1.phoneNumber}</span>
                  </a>
               </div>
            )}
            {event.eventHead2 !== null && (
               <div className='eventHead2'>
                  <span className='name'>{event.eventHead2.name}</span>
                  <span className='role'> Event Head</span>
                  <a className='email' href={"mailto:" + event.eventHead2.email}>
                     {event.eventHead2.email}
                  </a>
                  <a style={{ textDecoration: "none" }} href={"tel:" + event.eventHead2.phoneNumber}>
                     <span className='phone'>{event.eventHead2.phoneNumber}</span>
                  </a>
               </div>
            )}
         </div>
      );
   };

   return (
      <div data-aos='fade-up' className='popup-bg'>
         <div className='popup-content-container'>
            <div className='header-container'>
               <div className='row'>
                  <div className='col-md-3 col-sm-10'>
                     <div className='image-title-box'>
                        <img className='event-img' src={event.icon} alt={event.name} height='100' />
                     </div>
                  </div>
                  <div className='col-md-6 col-sm-10 competition-tile-div text-center'>
                     <h2 className='competition-name'>{event.name}</h2>

                     {event.category !== "other" && <div className='competition-category'>{event.category.replace(/_/g, " ").toUpperCase()}</div>}
                     {event.button && (
                        <>
                           {" "}
                           {event.eventStatus !== "yet_to_start" ? (
                              <button className='register-button'>
                                 <a style={{ textDecoration: "none", color: "white" }} target='_blank' href={event.registrationLink}>
                                    {event.button}
                                 </a>{" "}
                              </button>
                           ) : (
                              <></>
                           )}
                        </>
                     )}
                  </div>
                  <div className='col-lg-3 col-12 competition-tile-div '>
                     <div className='close-container' onClick={closeFunc}>
                        <svg className='close-button' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 252 252'>
                           <path d='M126 0C56.523 0 0 56.523 0 126s56.523 126 126 126 126-56.523 126-126S195.477 0 126 0zm0 234c-59.551 0-108-48.449-108-108S66.449 18 126 18s108 48.449 108 108-48.449 108-108 108z' />
                           <path d='M164.612 87.388a9 9 0 00-12.728 0L126 113.272l-25.885-25.885a9 9 0 00-12.728 0 9 9 0 000 12.728L113.272 126l-25.885 25.885a9 9 0 006.364 15.364 8.975 8.975 0 006.364-2.636L126 138.728l25.885 25.885c1.757 1.757 4.061 2.636 6.364 2.636s4.606-.879 6.364-2.636a9 9 0 000-12.728L138.728 126l25.885-25.885a9 9 0 00-.001-12.727z' />
                        </svg>
                     </div>
                     <div className='about-extra '>
                        <div className='about-extra-content '>
                           <div className='prize-money'>
                              <img src={prizepool} width='22' height='22' alt='' />
                              <span className='about-extra-header'> Entry fee: {entryFee} </span>
                           </div>
                        </div>

                        <div className='about-extra-content '>
                           <div className='prize-money'>
                              <img src={clock} width='22' height='22' alt='' />
                              <span className='about-extra-header'> {dayjs(event.datetime).format("D MMM")}</span>
                           </div>
                        </div>
                        <div className='about-extra-content '>
                           <div className='prize-money'>
                              <img src={location} width='22' height='22' alt='' />
                              <span className='about-extra-header'> {event.venue} </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <hr className='competition-popup-hr' />
            </div>

            <div className='popup-content-rows'>
               <div className='col-lg-3 col-12'>
                  <div className='popup-sidebar'>
                     <div className='popup-stack-switcher'>
                        <ul className='popup-nav'>
                           <li className={activeSection === "About" ? "active" : ""} onClick={() => setActiveSection("About")}>
                              About
                           </li>
                           <li className={activeSection === "Contact" ? "active" : ""} onClick={() => setActiveSection("Contact")}>
                              Contact
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div className='col-lg-9 col-12'>
                  <div className='popup-stack' ref={divRef}>
                     {
                        {
                           About: <AboutSection />,
                           Contact: <ContactSection />,
                        }[activeSection]
                     }
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default EventPopup;

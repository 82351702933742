import { useState, useEffect } from "react";
import Popup from "reactjs-popup";

import EventPopup from "../event-popup";
import "./index.css";

import Aos from "aos";
import "aos/dist/aos.css";
import excelIcon from "../../../assets/svg/excel2021.svg";

const FlipImage = ({ event }) => {
   const [isLoading, setIsLoading] = useState(true);
   const img = new Image();

   useEffect(() => {
      img.src = event.icon;
      img.onload = () => setIsLoading(false);
   });

   if (isLoading) return <img className='eventCardImg' height='100' width='100' src={excelIcon} alt={event.name} />;

   return (
      <div>
         <img className='eventCardImg' height='100' width='100' src={event.icon} alt={event.name} />
      </div>
   );
};
const EventCard = ({ event }) => {
   useEffect(() => {
      Aos.init({ duration: 1800 });
   }, []);
   const [open, setOpen] = useState(false);

   return (
      <div onClick={() => setOpen(true)}>
         <div data-aos='fade-in' className='eventCardContainer '>
            <div className='eventCardHeaderBox'>
               <div className='eventCardImg'>
                  {" "}
                  <FlipImage event={event} />
               </div>
               <span className='eventCardType'>{event.eventType}</span>
            </div>
            <div className='eventInfoBox'>
               <span className='eventCardTitle'>{event.name}</span>
               <span className='eventCardInfo'>{event.about.split(".")[0]}.</span>
            </div>
         </div>
         <Popup open={open} onClose={() => setOpen(false)} closeOnDocumentClick closeOnEscape nested>
            <EventPopup open={open} event={event} closeFunc={() => setOpen(false)} />
         </Popup>
      </div>
   );
};

export default EventCard;

import React from "react";

import SmallSidebar from "./SmallSidebar";
import Bottombar from "./Bottombar";

import useWindowDimensions from "../../hooks/useWindowDimensions";

const Navbar = () => {
   // In case anyone wonders what value to use here checkout the numbers in the mixins file
   return useWindowDimensions().width < 600 ? <Bottombar /> : <SmallSidebar />;
};

export default Navbar;

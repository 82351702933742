import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import abt from "./animations/abt";

import excel2020logo from "../../../src/assets/png/excel2020.png";
import excel2019logo from "../../../src/assets/png/excel2019.png";
import excel2018logo from "../../../src/assets/png/excel2018.png";
import excel2017logo from "../../../src/assets/png/excel2017.png";

function About() {
   const EventPhaseOne = useRef(null);
   useEffect(() => {
      const anime1 = lottie.loadAnimation({
         container: EventPhaseOne.current,
         renderer: "svg",
         loop: true,
         autoplay: true,
         animationData: abt,
      });

      return () => {
         anime1.destroy();
      }; // clean up for unmounting
   }, []);
   return (
      <div className='container-fluid home-div about-section'>
         <div className='row'>
            <div className='abt-content col-md-8 col-sm-10'>
               {" "}
               <h3 className='home-page-title'>Our legacy</h3>
               <div className='home-page-description '>
                  <p>
                     Excel is the nation’s second and South India’s first ever tech fest of its kind. The inspiration for Excel struck the students of Govt. Model Engineering College in 2001, and hence was born this extravaganza. Since then, Excel has been a truly unique event that has garnered the
                     attention of talented students, speakers and reputed companies. Be it technical events or non-technical galas, we take pride in putting together wholesome events that mesmerize you, from top to bottom.{" "}
                  </p>

                  <p>
                     <b>And what's more?</b> <br /> We are back again this year with the 22nd edition of Excel to inspire, innovate and engineer!
                  </p>
               </div>
            </div>
            <div className='abt-animation col-md-4 col-sm-10'>
               <br />
               <div className='' ref={EventPhaseOne}></div>
            </div>
            <div className=' col-md-8 col-sm-10 prev-logo '>
               <a href='https://2020.excelmec.org/#/'>
                  <img className='prev_excel' src={excel2020logo} alt='excel2020logo' />
               </a>
               <a href='https://2019.excelmec.org/#/'>
                  <img className='prev_excel' src={excel2019logo} alt='excel2019logo' />
               </a>
               <a href='https://2018.excelmec.org/#/'>
                  <img className='prev_excel ' src={excel2018logo} alt='excel2018logo' />
               </a>
               <a href='https://2017.excelmec.org/'>
                  <img className='prev_excel' src={excel2017logo} alt='excel2017logo' />
               </a>
            </div>
            <br />
         </div>
      </div>
   );
}

export default About;

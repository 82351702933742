import React from "react";

import LandingPage from "./LandingPage";
import About from "./About";
import Sponsers from "./Sponsers";
import Footer from "./Footer";
import "./style.css";

function home() {
   return (
      <div style={{ position: "relative" }}>
         <div className='home-body'>
            <LandingPage />
            <About />
            <Sponsers />
            <Footer />
         </div>
      </div>
   );
}

export default home;

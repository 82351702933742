import React from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./style.css";
import { useEffect } from "react";
import stickermule from "../../../src/assets/sponsers/stickermule.svg";
import nest from "../../../src/assets/sponsers/nest.svg";
import shipyard from "../../../src/assets/sponsers/shipyard.png";
import avt from "../../../src/assets/sponsers/AVT.png";
import pacelabs from "../../../src/assets/sponsers/pacelabs.png";
import ibm from "../../../src/assets/sponsers/IBM.svg";

function Sponsers() {
   useEffect(() => {
      Aos.init({ duration: 1500 });
   }, []);
   return (
      <div className=' home-div '>
         <div className='row text-center sponsors'>
            <h3 className='sponser-heading home-page-title'>Partners </h3>
            <div className='row text-center sponser-logos'>
               {" "}
               <div data-aos='fade-in' data-aos-delay='200' className='col-md-2 col-9 text-center'>
                  <a target='_blank' href='https://www.nestdigital.com/' style={{ textDecoration: "none" }}>
                     <div className='sponser'>
                        <img className='sponserImage' width='100' height='100' src={nest} alt='stickermule' />
                        <p className='sponsorTitle'> Associate Partner</p>
                     </div>
                  </a>
               </div>
               <div data-aos='fade-in' data-aos-delay='400' className='col-md-2 col-9 text-center'>
                  <a target='_blank' href='https://cochinshipyard.in/' style={{ textDecoration: "none" }}>
                     <div className='sponser'>
                        <img className='sponserImage' style={{ width: "55%", height: "50%" }} src={shipyard} alt='cochinshipyard' />
                        <p className='sponsorTitle'> Associate Partner</p>
                     </div>
                  </a>
               </div>
               <div data-aos='fade-in' data-aos-delay='600' className='col-md-2 col-9 text-center'>
                  <a target='_blank' href='https://www.ibm.com/in-en' style={{ textDecoration: "none" }}>
                     <div className='sponser'>
                        <img className='sponserImage' width='100' height='100' src={ibm} alt='ibm' />
                        <br /> <br />
                        <p className='sponsorTitle'> Conference Partner</p>
                     </div>
                  </a>
               </div>
            </div>
            <div className='row text-center sponser-logos'>
               <div data-aos='fade-in' data-aos-delay='200' className='col-md-2 col-9 text-center'>
                  <a target='_blank' href='https://www.stickermule.com/products/die-cut-stickers?utm_source=sponsorship&utm_medium=referral&utm_campaign=NYSCATE' style={{ textDecoration: "none" }}>
                     <div className='sponser'>
                        <br />
                        <img className='sponserImage' width='100' height='100' src={stickermule} alt='stickermule' />
                        <br /> <br />
                        <p className='sponsorTitle'> Stationary Partner</p>
                     </div>
                  </a>
               </div>

               <div data-aos='fade-in' data-aos-delay='400' className='col-md-2 col-9 text-center'>
                  <a target='_blank' href='https://pacelab.tech/' style={{ textDecoration: "none" }}>
                     <div className='sponser'>
                        <br /> <br />
                        <img className='sponserImage' width='100' height='100' src={pacelabs} alt='' />
                        <br /> <br />
                        <p className='sponsorTitle'> Workshops Partner </p>
                     </div>
                  </a>
               </div>
               <div data-aos='fade-in' data-aos-delay='600' className='col-md-2 col-9 text-center'>
                  <a target='_blank' href='https://www.avtnatural.com/' style={{ textDecoration: "none" }}>
                     <div className='sponser'>
                        <img className='sponserImage' width='90' height='90' src={avt} alt='' />
                        <p className='sponsorTitle'>Social Initiatives Partner </p>
                     </div>
                  </a>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Sponsers;

import React from "react";
import "./loader.css";

function Loader({ loaderposition }) {
   return (
      <div className={`pill ${loaderposition}`}>
         <div className='container1'>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
            <div className='block'></div>
         </div>
      </div>
   );
}

export default Loader;

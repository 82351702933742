import "./index.css";
import BigO from "../../assets/svg/BigO.svg";

const DeadEnd = () => {
   return (
      <div>
         <div className='dead-end-container'>
            <img className='deadend-img' src={BigO} alt='' />
            <span className='subtitle1'>*cricket sounds*</span>
            <span className='title'>Oops! Only BigO is here</span>
            <span className='subtitle2'>
               Seems like this filter couldn't find the category you need. <br /> Choose a different category to view more awesome events!
            </span>
         </div>
      </div>
   );
};

export default DeadEnd;

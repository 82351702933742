import React, { useEffect, useRef, useState } from "react";

import lottie from "lottie-web";
import loop from "./animations/loop";
import animation from "./animations/animation";

function Landingpage() {
   const [div_opacity, setdiv_opacity] = useState(1);

   useEffect(() => {
      setTimeout(function () {
         setdiv_opacity(0);
      }, 19000);
   }, []);

   const second_animation = useRef(null);
   const first_animation = useRef(null);

   useEffect(() => {
      window.scrollTo(0, 0);

      const animation1 = lottie.loadAnimation({
         container: first_animation.current,
         renderer: "svg",
         loop: false,
         autoplay: true,
         animationData: animation,
      });

      const animation2 = lottie.loadAnimation({
         container: second_animation.current,
         renderer: "svg",
         loop: true,
         autoplay: true,
         animationData: loop,
      });

      return () => {
         animation1.destroy();
         animation2.destroy();
      };
   }, []);

   const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;
   const lerp = (a, b, n) => (1 - n) * a + n * b;
   const calcWinsize = () => {
      return { width: window.innerWidth, height: window.innerHeight };
   };
   const getMousePos = (e) => {
      return {
         x: e.clientX,
         y: e.clientY,
      };
   };

   var el = useRef();
   let winsize = calcWinsize();
   window.addEventListener("resize", () => (winsize = calcWinsize()));
   // Track the mouse position
   let mousepos = { x: winsize.width / 2, y: winsize.height / 2 };
   window.addEventListener("mousemove", (ev) => (mousepos = getMousePos(ev)));

   useEffect(() => {
      const move = () => {
         let translationVals = { tx: 0, ty: 0 };

         const xstart = winsize.width;
         const ystart = 15;

         // infinite loop
         const render = () => {
            // Calculate the amount to move using linear interpolation to smooth things out.
            translationVals.tx = lerp(translationVals.tx, map(mousepos.x, 0, winsize.width, xstart, -xstart), 0.07) / 1.5;
            translationVals.ty = lerp(translationVals.ty, map(mousepos.y, 0, winsize.height, ystart, -ystart), 0.07);

            var el = document.getElementsByClassName("outer-div")[0];

            if (el) {
               el.style.left = -translationVals.tx + "px";
               el.style.top = -translationVals.ty + "px";
            }
            requestAnimationFrame(render);
         };
         requestAnimationFrame(render);
      };
      move();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div id='landing-page' className='container-fluid text-center'>
         <div id='stars'></div>
         <div className=' outer-div' ref={el}>
            <div style={{ opacity: div_opacity }} className='logo-reveal-div ' ref={first_animation}></div>
            <div style={{ opacity: 1 - div_opacity }} className='logo-reveal-div static-mec-div' ref={second_animation}></div>
         </div>
      </div>
   );
}

export default Landingpage;

import img_0 from "./images/abt/img_0.png";
import img_1 from "./images/abt/img_1.png";

export default {
   v: "5.7.4",
   fr: 30,
   ip: 0,
   op: 480,
   w: 2238,
   h: 1560,
   nm: "Comp 1",
   ddd: 1,
   assets: [
      { id: "image_0", w: 1179, h: 1406, u: "", p: img_0, e: 0 },
      { id: "image_1", w: 1179, h: 1406, u: "", p: img_1, e: 0 },
      {
         id: "comp_0",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 3,
               nm: "Color Control",
               sr: 1,
               ks: { o: { a: 0, k: 0, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [375, 211, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  { ty: 5, nm: "Big Text Color", np: 3, mn: "ADBE Color Control", ix: 1, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.525490224361, 0.160784319043, 0.890196084976, 1], ix: 1 } }] },
                  { ty: 5, nm: "Small Text Color", np: 3, mn: "ADBE Color Control", ix: 2, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.808624267578, 0.808624267578, 0.808624267578, 1], ix: 1 } }] },
                  { ty: 5, nm: "Big Line Color", np: 3, mn: "ADBE Color Control", ix: 3, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.525490224361, 0.160784319043, 0.890196084976, 1], ix: 1 } }] },
                  { ty: 5, nm: "Small Line Color", np: 3, mn: "ADBE Color Control", ix: 4, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.808624267578, 0.808624267578, 0.808624267578, 1], ix: 1 } }] },
                  { ty: 5, nm: "Pacrticles Color", np: 3, mn: "ADBE Color Control", ix: 5, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.527282714844, 0.527282714844, 0.527282714844, 1], ix: 1 } }] },
                  { ty: 5, nm: "Background Color", np: 3, mn: "ADBE Color Control", ix: 6, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.074523925781, 0.074523925781, 0.074523925781, 1], ix: 1 } }] },
               ],
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 0,
               nm: "Line_Right3",
               refId: "comp_1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [436.461, 251.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [-100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 141,
               op: 441,
               st: 141,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 0,
               nm: "Line_Left3",
               refId: "comp_1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [307.461, 251.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 141,
               op: 441,
               st: 141,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 4,
               ty: 0,
               nm: "Line_Bottom3",
               refId: "comp_1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -90, ix: 10 }, p: { a: 0, k: [155.961, 46.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 25,
               op: 325,
               st: 25,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 5,
               ty: 0,
               nm: "Line_Top3",
               refId: "comp_1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [673.55, 372.434, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 25,
               op: 325,
               st: 25,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 6,
               ty: 5,
               nm: "Small Text3",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [374.901, 224.562, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [1, 0, 0, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Small Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        {
                           ty: 0,
                           nm: "Opacity",
                           mn: "ADBE Fill-0005",
                           ix: 7,
                           v: {
                              a: 1,
                              k: [
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 34, s: [0] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 55, s: [1] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 124, s: [1] },
                                 { t: 142, s: [0] },
                              ],
                              ix: 7,
                           },
                        },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "CC Jaws",
                     np: 8,
                     mn: "CC Jaws",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 0, nm: "Completion", mn: "CC Jaws-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 3, nm: "Center", mn: "CC Jaws-0002", ix: 2, v: { a: 0, k: [375, 211], ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "CC Jaws-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                        { ty: 0, nm: "Height", mn: "CC Jaws-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Width", mn: "CC Jaws-0005", ix: 5, v: { a: 0, k: 10, ix: 5 } },
                        { ty: 7, nm: "Shape", mn: "CC Jaws-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "invert alpha",
                     np: 41,
                     mn: "ADBE Pro Levels",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 7, nm: "Channel:", mn: "ADBE Pro Levels-0001", ix: 1, v: { a: 0, k: 1, ix: 1 } },
                        {},
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0003", ix: 3, v: 0 },
                        { ty: 0, nm: "Input Black", mn: "ADBE Pro Levels-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Input White", mn: "ADBE Pro Levels-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 0, nm: "Gamma", mn: "ADBE Pro Levels-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                        { ty: 0, nm: "Output Black", mn: "ADBE Pro Levels-0007", ix: 7, v: { a: 0, k: 0, ix: 7 } },
                        { ty: 0, nm: "Output White", mn: "ADBE Pro Levels-0008", ix: 8, v: { a: 0, k: 1, ix: 8 } },
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0009", ix: 9, v: 0 },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0010", ix: 10, v: 0 },
                        { ty: 0, nm: "Red Input Black", mn: "ADBE Pro Levels-0011", ix: 11, v: { a: 0, k: 0, ix: 11 } },
                        { ty: 0, nm: "Red Input White", mn: "ADBE Pro Levels-0012", ix: 12, v: { a: 0, k: 1, ix: 12 } },
                        { ty: 0, nm: "Red Gamma", mn: "ADBE Pro Levels-0013", ix: 13, v: { a: 0, k: 1, ix: 13 } },
                        { ty: 0, nm: "Red Output Black", mn: "ADBE Pro Levels-0014", ix: 14, v: { a: 0, k: 0, ix: 14 } },
                        { ty: 0, nm: "Red Output White", mn: "ADBE Pro Levels-0015", ix: 15, v: { a: 0, k: 1, ix: 15 } },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0016", ix: 16, v: 0 },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0017", ix: 17, v: 0 },
                        { ty: 0, nm: "Green Input Black", mn: "ADBE Pro Levels-0018", ix: 18, v: { a: 0, k: 0, ix: 18 } },
                        { ty: 0, nm: "Green Input White", mn: "ADBE Pro Levels-0019", ix: 19, v: { a: 0, k: 1, ix: 19 } },
                        { ty: 0, nm: "Green Gamma", mn: "ADBE Pro Levels-0020", ix: 20, v: { a: 0, k: 1, ix: 20 } },
                        { ty: 0, nm: "Green Output Black", mn: "ADBE Pro Levels-0021", ix: 21, v: { a: 0, k: 0, ix: 21 } },
                        { ty: 0, nm: "Green Output White", mn: "ADBE Pro Levels-0022", ix: 22, v: { a: 0, k: 1, ix: 22 } },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0023", ix: 23, v: 0 },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0024", ix: 24, v: 0 },
                        { ty: 0, nm: "Blue Input Black", mn: "ADBE Pro Levels-0025", ix: 25, v: { a: 0, k: 0, ix: 25 } },
                        { ty: 0, nm: "Blue Input White", mn: "ADBE Pro Levels-0026", ix: 26, v: { a: 0, k: 1, ix: 26 } },
                        { ty: 0, nm: "Blue Gamma", mn: "ADBE Pro Levels-0027", ix: 27, v: { a: 0, k: 1, ix: 27 } },
                        { ty: 0, nm: "Blue Output Black", mn: "ADBE Pro Levels-0028", ix: 28, v: { a: 0, k: 0, ix: 28 } },
                        { ty: 0, nm: "Blue Output White", mn: "ADBE Pro Levels-0029", ix: 29, v: { a: 0, k: 1, ix: 29 } },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0030", ix: 30, v: 0 },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0031", ix: 31, v: 0 },
                        { ty: 0, nm: "Alpha Input Black", mn: "ADBE Pro Levels-0032", ix: 32, v: { a: 0, k: 0, ix: 32 } },
                        { ty: 0, nm: "Alpha Input White", mn: "ADBE Pro Levels-0033", ix: 33, v: { a: 0, k: 1, ix: 33 } },
                        { ty: 0, nm: "Alpha Gamma", mn: "ADBE Pro Levels-0034", ix: 34, v: { a: 0, k: 1, ix: 34 } },
                        { ty: 0, nm: "Alpha Output Black", mn: "ADBE Pro Levels-0035", ix: 35, v: { a: 0, k: 1, ix: 35 } },
                        { ty: 0, nm: "Alpha Output White", mn: "ADBE Pro Levels-0036", ix: 36, v: { a: 0, k: 0, ix: 36 } },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0037", ix: 37, v: 0 },
                        { ty: 7, nm: "Clip To Output Black", mn: "ADBE Pro Levels-0038", ix: 38, v: { a: 0, k: 1, ix: 38 } },
                        { ty: 7, nm: "Clip To Output White", mn: "ADBE Pro Levels-0039", ix: 39, v: { a: 0, k: 1, ix: 39 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "clip to object alpha",
                     np: 8,
                     mn: "ADBE Set Matte2",
                     ix: 4,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Take Matte From Layer", mn: "ADBE Set Matte2-0001", ix: 1, v: { a: 0, k: 6, ix: 1 } },
                        { ty: 7, nm: "Use For Matte", mn: "ADBE Set Matte2-0002", ix: 2, v: { a: 0, k: 4, ix: 2 } },
                        { ty: 7, nm: "Invert Matte", mn: "ADBE Set Matte2-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                        { ty: 7, nm: "If Layer Sizes Differ", mn: "ADBE Set Matte2-0004", ix: 4, v: { a: 0, k: 1, ix: 4 } },
                        { ty: 7, nm: "Composite Matte with Original", mn: "ADBE Set Matte2-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 7, nm: "Premultiply Matte Layer", mn: "ADBE Set Matte2-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "clean up edges",
                     np: 41,
                     mn: "ADBE Pro Levels",
                     ix: 5,
                     en: 1,
                     ef: [
                        { ty: 7, nm: "Channel:", mn: "ADBE Pro Levels-0001", ix: 1, v: { a: 0, k: 1, ix: 1 } },
                        {},
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0003", ix: 3, v: 0 },
                        { ty: 0, nm: "Input Black", mn: "ADBE Pro Levels-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Input White", mn: "ADBE Pro Levels-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 0, nm: "Gamma", mn: "ADBE Pro Levels-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                        { ty: 0, nm: "Output Black", mn: "ADBE Pro Levels-0007", ix: 7, v: { a: 0, k: 0, ix: 7 } },
                        { ty: 0, nm: "Output White", mn: "ADBE Pro Levels-0008", ix: 8, v: { a: 0, k: 1, ix: 8 } },
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0009", ix: 9, v: 0 },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0010", ix: 10, v: 0 },
                        { ty: 0, nm: "Red Input Black", mn: "ADBE Pro Levels-0011", ix: 11, v: { a: 0, k: 0, ix: 11 } },
                        { ty: 0, nm: "Red Input White", mn: "ADBE Pro Levels-0012", ix: 12, v: { a: 0, k: 1, ix: 12 } },
                        { ty: 0, nm: "Red Gamma", mn: "ADBE Pro Levels-0013", ix: 13, v: { a: 0, k: 1, ix: 13 } },
                        { ty: 0, nm: "Red Output Black", mn: "ADBE Pro Levels-0014", ix: 14, v: { a: 0, k: 0, ix: 14 } },
                        { ty: 0, nm: "Red Output White", mn: "ADBE Pro Levels-0015", ix: 15, v: { a: 0, k: 1, ix: 15 } },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0016", ix: 16, v: 0 },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0017", ix: 17, v: 0 },
                        { ty: 0, nm: "Green Input Black", mn: "ADBE Pro Levels-0018", ix: 18, v: { a: 0, k: 0, ix: 18 } },
                        { ty: 0, nm: "Green Input White", mn: "ADBE Pro Levels-0019", ix: 19, v: { a: 0, k: 1, ix: 19 } },
                        { ty: 0, nm: "Green Gamma", mn: "ADBE Pro Levels-0020", ix: 20, v: { a: 0, k: 1, ix: 20 } },
                        { ty: 0, nm: "Green Output Black", mn: "ADBE Pro Levels-0021", ix: 21, v: { a: 0, k: 0, ix: 21 } },
                        { ty: 0, nm: "Green Output White", mn: "ADBE Pro Levels-0022", ix: 22, v: { a: 0, k: 1, ix: 22 } },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0023", ix: 23, v: 0 },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0024", ix: 24, v: 0 },
                        { ty: 0, nm: "Blue Input Black", mn: "ADBE Pro Levels-0025", ix: 25, v: { a: 0, k: 0, ix: 25 } },
                        { ty: 0, nm: "Blue Input White", mn: "ADBE Pro Levels-0026", ix: 26, v: { a: 0, k: 1, ix: 26 } },
                        { ty: 0, nm: "Blue Gamma", mn: "ADBE Pro Levels-0027", ix: 27, v: { a: 0, k: 1, ix: 27 } },
                        { ty: 0, nm: "Blue Output Black", mn: "ADBE Pro Levels-0028", ix: 28, v: { a: 0, k: 0, ix: 28 } },
                        { ty: 0, nm: "Blue Output White", mn: "ADBE Pro Levels-0029", ix: 29, v: { a: 0, k: 1, ix: 29 } },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0030", ix: 30, v: 0 },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0031", ix: 31, v: 0 },
                        { ty: 0, nm: "Alpha Input Black", mn: "ADBE Pro Levels-0032", ix: 32, v: { a: 0, k: 0.255, ix: 32 } },
                        { ty: 0, nm: "Alpha Input White", mn: "ADBE Pro Levels-0033", ix: 33, v: { a: 0, k: 1, ix: 33 } },
                        { ty: 0, nm: "Alpha Gamma", mn: "ADBE Pro Levels-0034", ix: 34, v: { a: 0, k: 1, ix: 34 } },
                        { ty: 0, nm: "Alpha Output Black", mn: "ADBE Pro Levels-0035", ix: 35, v: { a: 0, k: 0, ix: 35 } },
                        { ty: 0, nm: "Alpha Output White", mn: "ADBE Pro Levels-0036", ix: 36, v: { a: 0, k: 1, ix: 36 } },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0037", ix: 37, v: 0 },
                        { ty: 7, nm: "Clip To Output Black", mn: "ADBE Pro Levels-0038", ix: 38, v: { a: 0, k: 1, ix: 38 } },
                        { ty: 7, nm: "Clip To Output White", mn: "ADBE Pro Levels-0039", ix: 39, v: { a: 0, k: 1, ix: 39 } },
                     ],
                  },
               ],
               t: { d: { k: [{ s: { s: 36, f: "Intro", t: "ENGINEER", j: 2, tr: 200, lh: 43.2, ls: 0, fc: [0.809, 0.809, 0.809] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 7,
               ty: 0,
               nm: "Big Text3",
               refId: "comp_2",
               sr: 1,
               ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                     a: 1,
                     k: [
                        { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 30, s: [375, 211, 0], to: [0, 5, 0], ti: [0, -5, 0] },
                        { i: { x: 0, y: 0 }, o: { x: 0.333, y: 0.333 }, t: 60, s: [375, 241, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                        { i: { x: 0.758, y: 1 }, o: { x: 1, y: 0 }, t: 120, s: [375, 241, 0], to: [0, -5, 0], ti: [0, 5, 0] },
                        { t: 150, s: [375, 211, 0] },
                     ],
                     ix: 2,
                     l: 2,
                  },
                  a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [1, 0, 0, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Big Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Fill-0005", ix: 7, v: { a: 0, k: 1, ix: 7 } },
                     ],
                  },
               ],
               w: 1920,
               h: 1080,
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 8,
               ty: 0,
               nm: "Text_Top3",
               refId: "comp_3",
               sr: 1,
               ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                     a: 1,
                     k: [
                        { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 30, s: [375, 211, 0], to: [0, -5, 0], ti: [0, 5, 0] },
                        { i: { x: 0, y: 0 }, o: { x: 0.333, y: 0.333 }, t: 60, s: [375, 181, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                        { i: { x: 0.758, y: 1 }, o: { x: 1, y: 0 }, t: 120, s: [375, 181, 0], to: [0, 5, 0], ti: [0, -5, 0] },
                        { t: 150, s: [375, 211, 0] },
                     ],
                     ix: 2,
                     l: 2,
                  },
                  a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [1, 0, 0, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Big Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Fill-0005", ix: 7, v: { a: 0, k: 1, ix: 7 } },
                     ],
                  },
               ],
               w: 1920,
               h: 1080,
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_1",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 4,
               nm: "Line_White",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [870, 565, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [62, 62, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-213.124, 40.314],
                                    [-286.326, 40.314],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        {
                           ty: "st",
                           c: { a: 0, k: [0.783529423732, 0.783529423732, 0.783529423732, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = comp('engineer').layer('Color Control').effect('Small Line Color')('Color');" },
                           o: { a: 0, k: 100, ix: 4 },
                           w: { a: 0, k: 5, ix: 5 },
                           lc: 2,
                           lj: 1,
                           ml: 4,
                           bm: 0,
                           nm: "Stroke 1",
                           mn: "ADBE Vector Graphic - Stroke",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 6.632, s: [0] },
                           { t: 14, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                           { t: 6.6318359375, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 4,
               nm: "Line_Red",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-213.124, 40.314],
                                    [-286.326, 40.314],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        {
                           ty: "st",
                           c: { a: 0, k: [0.993743896484, 0.217437744141, 0.217437744141, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = comp('engineer').layer('Color Control').effect('Big Line Color')('Color');" },
                           o: { a: 0, k: 100, ix: 4 },
                           w: { a: 0, k: 5, ix: 5 },
                           lc: 2,
                           lj: 1,
                           ml: 4,
                           bm: 0,
                           nm: "Stroke 1",
                           mn: "ADBE Vector Graphic - Stroke",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 9.632, s: [0] },
                           { t: 17, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 3, s: [0] },
                           { t: 9.6318359375, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 3,
               op: 303,
               st: 3,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_2",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 1,
               nm: "Mask3",
               td: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 810, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 50, 100], ix: 6, l: 2 } },
               ao: 0,
               sw: 1920,
               sh: 1080,
               sc: "#ffffff",
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 5,
               nm: "engineer",
               tt: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [959.774, 575.097, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { s: 100, f: "Intro", t: "ENGINEER", j: 2, tr: 0, lh: 120, ls: 0, fc: [0.994, 0.217, 0.217] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_3",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 1,
               nm: "Mask3",
               td: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 270, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 50, 100], ix: 6, l: 2 } },
               ao: 0,
               sw: 1920,
               sh: 1080,
               sc: "#ffffff",
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 5,
               nm: "engineer",
               tt: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [959.774, 575.097, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { x: "var $bm_rt;\n$bm_rt = comp('Big Text3').layer('engineer').text.sourceText;", k: [{ s: { s: 100, f: "Intro", t: "ENGINEER", j: 2, tr: 0, lh: 120, ls: 0, fc: [0.994, 0.217, 0.217] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_4",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 3,
               nm: "Color Control",
               sr: 1,
               ks: { o: { a: 0, k: 0, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [375, 211, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  { ty: 5, nm: "Big Text Color", np: 3, mn: "ADBE Color Control", ix: 1, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.525490224361, 0.160784319043, 0.890196084976, 1], ix: 1 } }] },
                  { ty: 5, nm: "Small Text Color", np: 3, mn: "ADBE Color Control", ix: 2, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.808624267578, 0.808624267578, 0.808624267578, 1], ix: 1 } }] },
                  { ty: 5, nm: "Big Line Color", np: 3, mn: "ADBE Color Control", ix: 3, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.525490224361, 0.160784319043, 0.890196084976, 1], ix: 1 } }] },
                  { ty: 5, nm: "Small Line Color", np: 3, mn: "ADBE Color Control", ix: 4, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.808624267578, 0.808624267578, 0.808624267578, 1], ix: 1 } }] },
                  { ty: 5, nm: "Pacrticles Color", np: 3, mn: "ADBE Color Control", ix: 5, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.527282714844, 0.527282714844, 0.527282714844, 1], ix: 1 } }] },
                  { ty: 5, nm: "Background Color", np: 3, mn: "ADBE Color Control", ix: 6, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.074523925781, 0.074523925781, 0.074523925781, 1], ix: 1 } }] },
               ],
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 0,
               nm: "Line_Right2",
               refId: "comp_5",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [432.461, 251.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [-100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 141,
               op: 441,
               st: 141,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 0,
               nm: "Line_Left2",
               refId: "comp_5",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [315.461, 251.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 141,
               op: 441,
               st: 141,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 4,
               ty: 0,
               nm: "Line_Bottom2",
               refId: "comp_5",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -90, ix: 10 }, p: { a: 0, k: [160.961, 46.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 25,
               op: 325,
               st: 25,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 5,
               ty: 0,
               nm: "Line_Top2",
               refId: "comp_5",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [668.55, 372.434, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 25,
               op: 325,
               st: 25,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 6,
               ty: 5,
               nm: "Small Text2",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [374.901, 224.562, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [1, 0, 0, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Small Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        {
                           ty: 0,
                           nm: "Opacity",
                           mn: "ADBE Fill-0005",
                           ix: 7,
                           v: {
                              a: 1,
                              k: [
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 36, s: [0] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 53, s: [1] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 126, s: [1] },
                                 { t: 142, s: [0] },
                              ],
                              ix: 7,
                           },
                        },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "CC Jaws",
                     np: 8,
                     mn: "CC Jaws",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 0, nm: "Completion", mn: "CC Jaws-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 3, nm: "Center", mn: "CC Jaws-0002", ix: 2, v: { a: 0, k: [375, 211], ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "CC Jaws-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                        { ty: 0, nm: "Height", mn: "CC Jaws-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Width", mn: "CC Jaws-0005", ix: 5, v: { a: 0, k: 10, ix: 5 } },
                        { ty: 7, nm: "Shape", mn: "CC Jaws-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "invert alpha",
                     np: 41,
                     mn: "ADBE Pro Levels",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 7, nm: "Channel:", mn: "ADBE Pro Levels-0001", ix: 1, v: { a: 0, k: 1, ix: 1 } },
                        {},
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0003", ix: 3, v: 0 },
                        { ty: 0, nm: "Input Black", mn: "ADBE Pro Levels-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Input White", mn: "ADBE Pro Levels-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 0, nm: "Gamma", mn: "ADBE Pro Levels-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                        { ty: 0, nm: "Output Black", mn: "ADBE Pro Levels-0007", ix: 7, v: { a: 0, k: 0, ix: 7 } },
                        { ty: 0, nm: "Output White", mn: "ADBE Pro Levels-0008", ix: 8, v: { a: 0, k: 1, ix: 8 } },
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0009", ix: 9, v: 0 },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0010", ix: 10, v: 0 },
                        { ty: 0, nm: "Red Input Black", mn: "ADBE Pro Levels-0011", ix: 11, v: { a: 0, k: 0, ix: 11 } },
                        { ty: 0, nm: "Red Input White", mn: "ADBE Pro Levels-0012", ix: 12, v: { a: 0, k: 1, ix: 12 } },
                        { ty: 0, nm: "Red Gamma", mn: "ADBE Pro Levels-0013", ix: 13, v: { a: 0, k: 1, ix: 13 } },
                        { ty: 0, nm: "Red Output Black", mn: "ADBE Pro Levels-0014", ix: 14, v: { a: 0, k: 0, ix: 14 } },
                        { ty: 0, nm: "Red Output White", mn: "ADBE Pro Levels-0015", ix: 15, v: { a: 0, k: 1, ix: 15 } },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0016", ix: 16, v: 0 },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0017", ix: 17, v: 0 },
                        { ty: 0, nm: "Green Input Black", mn: "ADBE Pro Levels-0018", ix: 18, v: { a: 0, k: 0, ix: 18 } },
                        { ty: 0, nm: "Green Input White", mn: "ADBE Pro Levels-0019", ix: 19, v: { a: 0, k: 1, ix: 19 } },
                        { ty: 0, nm: "Green Gamma", mn: "ADBE Pro Levels-0020", ix: 20, v: { a: 0, k: 1, ix: 20 } },
                        { ty: 0, nm: "Green Output Black", mn: "ADBE Pro Levels-0021", ix: 21, v: { a: 0, k: 0, ix: 21 } },
                        { ty: 0, nm: "Green Output White", mn: "ADBE Pro Levels-0022", ix: 22, v: { a: 0, k: 1, ix: 22 } },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0023", ix: 23, v: 0 },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0024", ix: 24, v: 0 },
                        { ty: 0, nm: "Blue Input Black", mn: "ADBE Pro Levels-0025", ix: 25, v: { a: 0, k: 0, ix: 25 } },
                        { ty: 0, nm: "Blue Input White", mn: "ADBE Pro Levels-0026", ix: 26, v: { a: 0, k: 1, ix: 26 } },
                        { ty: 0, nm: "Blue Gamma", mn: "ADBE Pro Levels-0027", ix: 27, v: { a: 0, k: 1, ix: 27 } },
                        { ty: 0, nm: "Blue Output Black", mn: "ADBE Pro Levels-0028", ix: 28, v: { a: 0, k: 0, ix: 28 } },
                        { ty: 0, nm: "Blue Output White", mn: "ADBE Pro Levels-0029", ix: 29, v: { a: 0, k: 1, ix: 29 } },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0030", ix: 30, v: 0 },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0031", ix: 31, v: 0 },
                        { ty: 0, nm: "Alpha Input Black", mn: "ADBE Pro Levels-0032", ix: 32, v: { a: 0, k: 0, ix: 32 } },
                        { ty: 0, nm: "Alpha Input White", mn: "ADBE Pro Levels-0033", ix: 33, v: { a: 0, k: 1, ix: 33 } },
                        { ty: 0, nm: "Alpha Gamma", mn: "ADBE Pro Levels-0034", ix: 34, v: { a: 0, k: 1, ix: 34 } },
                        { ty: 0, nm: "Alpha Output Black", mn: "ADBE Pro Levels-0035", ix: 35, v: { a: 0, k: 1, ix: 35 } },
                        { ty: 0, nm: "Alpha Output White", mn: "ADBE Pro Levels-0036", ix: 36, v: { a: 0, k: 0, ix: 36 } },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0037", ix: 37, v: 0 },
                        { ty: 7, nm: "Clip To Output Black", mn: "ADBE Pro Levels-0038", ix: 38, v: { a: 0, k: 1, ix: 38 } },
                        { ty: 7, nm: "Clip To Output White", mn: "ADBE Pro Levels-0039", ix: 39, v: { a: 0, k: 1, ix: 39 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "clip to object alpha",
                     np: 8,
                     mn: "ADBE Set Matte2",
                     ix: 4,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Take Matte From Layer", mn: "ADBE Set Matte2-0001", ix: 1, v: { a: 0, k: 6, ix: 1 } },
                        { ty: 7, nm: "Use For Matte", mn: "ADBE Set Matte2-0002", ix: 2, v: { a: 0, k: 4, ix: 2 } },
                        { ty: 7, nm: "Invert Matte", mn: "ADBE Set Matte2-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                        { ty: 7, nm: "If Layer Sizes Differ", mn: "ADBE Set Matte2-0004", ix: 4, v: { a: 0, k: 1, ix: 4 } },
                        { ty: 7, nm: "Composite Matte with Original", mn: "ADBE Set Matte2-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 7, nm: "Premultiply Matte Layer", mn: "ADBE Set Matte2-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "clean up edges",
                     np: 41,
                     mn: "ADBE Pro Levels",
                     ix: 5,
                     en: 1,
                     ef: [
                        { ty: 7, nm: "Channel:", mn: "ADBE Pro Levels-0001", ix: 1, v: { a: 0, k: 1, ix: 1 } },
                        {},
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0003", ix: 3, v: 0 },
                        { ty: 0, nm: "Input Black", mn: "ADBE Pro Levels-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Input White", mn: "ADBE Pro Levels-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 0, nm: "Gamma", mn: "ADBE Pro Levels-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                        { ty: 0, nm: "Output Black", mn: "ADBE Pro Levels-0007", ix: 7, v: { a: 0, k: 0, ix: 7 } },
                        { ty: 0, nm: "Output White", mn: "ADBE Pro Levels-0008", ix: 8, v: { a: 0, k: 1, ix: 8 } },
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0009", ix: 9, v: 0 },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0010", ix: 10, v: 0 },
                        { ty: 0, nm: "Red Input Black", mn: "ADBE Pro Levels-0011", ix: 11, v: { a: 0, k: 0, ix: 11 } },
                        { ty: 0, nm: "Red Input White", mn: "ADBE Pro Levels-0012", ix: 12, v: { a: 0, k: 1, ix: 12 } },
                        { ty: 0, nm: "Red Gamma", mn: "ADBE Pro Levels-0013", ix: 13, v: { a: 0, k: 1, ix: 13 } },
                        { ty: 0, nm: "Red Output Black", mn: "ADBE Pro Levels-0014", ix: 14, v: { a: 0, k: 0, ix: 14 } },
                        { ty: 0, nm: "Red Output White", mn: "ADBE Pro Levels-0015", ix: 15, v: { a: 0, k: 1, ix: 15 } },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0016", ix: 16, v: 0 },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0017", ix: 17, v: 0 },
                        { ty: 0, nm: "Green Input Black", mn: "ADBE Pro Levels-0018", ix: 18, v: { a: 0, k: 0, ix: 18 } },
                        { ty: 0, nm: "Green Input White", mn: "ADBE Pro Levels-0019", ix: 19, v: { a: 0, k: 1, ix: 19 } },
                        { ty: 0, nm: "Green Gamma", mn: "ADBE Pro Levels-0020", ix: 20, v: { a: 0, k: 1, ix: 20 } },
                        { ty: 0, nm: "Green Output Black", mn: "ADBE Pro Levels-0021", ix: 21, v: { a: 0, k: 0, ix: 21 } },
                        { ty: 0, nm: "Green Output White", mn: "ADBE Pro Levels-0022", ix: 22, v: { a: 0, k: 1, ix: 22 } },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0023", ix: 23, v: 0 },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0024", ix: 24, v: 0 },
                        { ty: 0, nm: "Blue Input Black", mn: "ADBE Pro Levels-0025", ix: 25, v: { a: 0, k: 0, ix: 25 } },
                        { ty: 0, nm: "Blue Input White", mn: "ADBE Pro Levels-0026", ix: 26, v: { a: 0, k: 1, ix: 26 } },
                        { ty: 0, nm: "Blue Gamma", mn: "ADBE Pro Levels-0027", ix: 27, v: { a: 0, k: 1, ix: 27 } },
                        { ty: 0, nm: "Blue Output Black", mn: "ADBE Pro Levels-0028", ix: 28, v: { a: 0, k: 0, ix: 28 } },
                        { ty: 0, nm: "Blue Output White", mn: "ADBE Pro Levels-0029", ix: 29, v: { a: 0, k: 1, ix: 29 } },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0030", ix: 30, v: 0 },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0031", ix: 31, v: 0 },
                        { ty: 0, nm: "Alpha Input Black", mn: "ADBE Pro Levels-0032", ix: 32, v: { a: 0, k: 0.255, ix: 32 } },
                        { ty: 0, nm: "Alpha Input White", mn: "ADBE Pro Levels-0033", ix: 33, v: { a: 0, k: 1, ix: 33 } },
                        { ty: 0, nm: "Alpha Gamma", mn: "ADBE Pro Levels-0034", ix: 34, v: { a: 0, k: 1, ix: 34 } },
                        { ty: 0, nm: "Alpha Output Black", mn: "ADBE Pro Levels-0035", ix: 35, v: { a: 0, k: 0, ix: 35 } },
                        { ty: 0, nm: "Alpha Output White", mn: "ADBE Pro Levels-0036", ix: 36, v: { a: 0, k: 1, ix: 36 } },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0037", ix: 37, v: 0 },
                        { ty: 7, nm: "Clip To Output Black", mn: "ADBE Pro Levels-0038", ix: 38, v: { a: 0, k: 1, ix: 38 } },
                        { ty: 7, nm: "Clip To Output White", mn: "ADBE Pro Levels-0039", ix: 39, v: { a: 0, k: 1, ix: 39 } },
                     ],
                  },
               ],
               t: { d: { k: [{ s: { s: 36, f: "Intro", t: "INNOVATE", j: 2, tr: 200, lh: 43.2, ls: 0, fc: [0.809, 0.809, 0.809] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 7,
               ty: 0,
               nm: "Big Text2",
               refId: "comp_6",
               sr: 1,
               ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                     a: 1,
                     k: [
                        { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 30, s: [375, 211, 0], to: [0, 5, 0], ti: [0, -5, 0] },
                        { i: { x: 0, y: 0 }, o: { x: 0.333, y: 0.333 }, t: 60, s: [375, 241, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                        { i: { x: 0.758, y: 1 }, o: { x: 1, y: 0 }, t: 120, s: [375, 241, 0], to: [0, -5, 0], ti: [0, 5, 0] },
                        { t: 150, s: [375, 211, 0] },
                     ],
                     ix: 2,
                     l: 2,
                  },
                  a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [1, 0, 0, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Big Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Fill-0005", ix: 7, v: { a: 0, k: 1, ix: 7 } },
                     ],
                  },
               ],
               w: 1920,
               h: 1080,
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 8,
               ty: 0,
               nm: "Text_Top2",
               refId: "comp_7",
               sr: 1,
               ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                     a: 1,
                     k: [
                        { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 30, s: [375, 211, 0], to: [0, -5, 0], ti: [0, 5, 0] },
                        { i: { x: 0, y: 0 }, o: { x: 0.333, y: 0.333 }, t: 60, s: [375, 181, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                        { i: { x: 0.758, y: 1 }, o: { x: 1, y: 0 }, t: 120, s: [375, 181, 0], to: [0, 5, 0], ti: [0, -5, 0] },
                        { t: 150, s: [375, 211, 0] },
                     ],
                     ix: 2,
                     l: 2,
                  },
                  a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [1, 0, 0, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Big Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Fill-0005", ix: 7, v: { a: 0, k: 1, ix: 7 } },
                     ],
                  },
               ],
               w: 1920,
               h: 1080,
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_5",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 4,
               nm: "Line_White",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [870, 565, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [62, 62, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-213.124, 40.314],
                                    [-286.326, 40.314],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        {
                           ty: "st",
                           c: { a: 0, k: [0.783529423732, 0.783529423732, 0.783529423732, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = comp('innovate').layer('Color Control').effect('Small Line Color')('Color');" },
                           o: { a: 0, k: 100, ix: 4 },
                           w: { a: 0, k: 5, ix: 5 },
                           lc: 2,
                           lj: 1,
                           ml: 4,
                           bm: 0,
                           nm: "Stroke 1",
                           mn: "ADBE Vector Graphic - Stroke",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 6.632, s: [0] },
                           { t: 14, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                           { t: 6.6318359375, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 4,
               nm: "Line_Red",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-213.124, 40.314],
                                    [-286.326, 40.314],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        {
                           ty: "st",
                           c: { a: 0, k: [0.993743896484, 0.217437744141, 0.217437744141, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = comp('innovate').layer('Color Control').effect('Big Line Color')('Color');" },
                           o: { a: 0, k: 100, ix: 4 },
                           w: { a: 0, k: 5, ix: 5 },
                           lc: 2,
                           lj: 1,
                           ml: 4,
                           bm: 0,
                           nm: "Stroke 1",
                           mn: "ADBE Vector Graphic - Stroke",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 9.632, s: [0] },
                           { t: 17, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 3, s: [0] },
                           { t: 9.6318359375, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 3,
               op: 303,
               st: 3,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_6",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 1,
               nm: "Mask2",
               td: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 810, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 50, 100], ix: 6, l: 2 } },
               ao: 0,
               sw: 1920,
               sh: 1080,
               sc: "#ffffff",
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 5,
               nm: "innovate",
               tt: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [959.774, 575.097, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { s: 100, f: "Intro", t: "INNOVATE", j: 2, tr: 0, lh: 120, ls: 0, fc: [0.994, 0.217, 0.217] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_7",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 1,
               nm: "Mask2",
               td: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 270, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 50, 100], ix: 6, l: 2 } },
               ao: 0,
               sw: 1920,
               sh: 1080,
               sc: "#ffffff",
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 5,
               nm: "innovate",
               tt: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [959.774, 575.097, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { x: "var $bm_rt;\n$bm_rt = comp('Big Text2').layer('innovate').text.sourceText;", k: [{ s: { s: 100, f: "Intro", t: "INNOVATE", j: 2, tr: 0, lh: 120, ls: 0, fc: [0.994, 0.217, 0.217] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_8",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 3,
               nm: "Color Control",
               sr: 1,
               ks: { o: { a: 0, k: 0, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [323.5, 182, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  { ty: 5, nm: "Big Text Color", np: 3, mn: "ADBE Color Control", ix: 1, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.525490224361, 0.160784319043, 0.890196084976, 1], ix: 1 } }] },
                  { ty: 5, nm: "Small Text Color", np: 3, mn: "ADBE Color Control", ix: 2, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.808624267578, 0.808624267578, 0.808624267578, 1], ix: 1 } }] },
                  { ty: 5, nm: "Big Line Color", np: 3, mn: "ADBE Color Control", ix: 3, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.525490224361, 0.160784319043, 0.890196084976, 1], ix: 1 } }] },
                  { ty: 5, nm: "Small Line Color", np: 3, mn: "ADBE Color Control", ix: 4, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.808624267578, 0.808624267578, 0.808624267578, 1], ix: 1 } }] },
                  { ty: 5, nm: "Pacrticles Color", np: 3, mn: "ADBE Color Control", ix: 5, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0.527282714844, 0.527282714844, 0.527282714844, 1], ix: 1 } }] },
                  { ty: 5, nm: "Background Color", np: 3, mn: "ADBE Color Control", ix: 6, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } }] },
               ],
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 0,
               nm: "Line_Right1",
               refId: "comp_9",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [308.961, 222.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [-100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 141,
               op: 441,
               st: 141,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 0,
               nm: "Line_Left1",
               refId: "comp_9",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [335.961, 222.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 141,
               op: 441,
               st: 141,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 4,
               ty: 0,
               nm: "Line_Bottom1",
               refId: "comp_9",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -90, ix: 10 }, p: { a: 0, k: [184.461, 17.227, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, -100, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  {
                     ty: 5,
                     nm: "CC Jaws",
                     np: 8,
                     mn: "CC Jaws",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 0, nm: "Completion", mn: "CC Jaws-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 3, nm: "Center", mn: "CC Jaws-0002", ix: 2, v: { a: 0, k: [960, 540], ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "CC Jaws-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                        { ty: 0, nm: "Height", mn: "CC Jaws-0004", ix: 4, v: { a: 0, k: 0.1, ix: 4 } },
                        { ty: 0, nm: "Width", mn: "CC Jaws-0005", ix: 5, v: { a: 0, k: 10, ix: 5 } },
                        { ty: 7, nm: "Shape", mn: "CC Jaws-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                     ],
                  },
                  { ty: 5, nm: "Color Control", np: 3, mn: "ADBE Color Control", ix: 2, en: 1, ef: [{ ty: 2, nm: "Color", mn: "ADBE Color Control-0001", ix: 1, v: { a: 0, k: [1, 0, 0, 1], ix: 1 } }] },
               ],
               w: 1920,
               h: 1080,
               ip: 25,
               op: 325,
               st: 25,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 5,
               ty: 0,
               nm: "Line_Top1",
               refId: "comp_9",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [542.05, 343.434, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 25,
               op: 325,
               st: 25,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 6,
               ty: 5,
               nm: "Small Text1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [323.401, 195.562, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [0.917647302151, 0, 1, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Small Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        {
                           ty: 0,
                           nm: "Opacity",
                           mn: "ADBE Fill-0005",
                           ix: 7,
                           v: {
                              a: 1,
                              k: [
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 38, s: [0] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 54, s: [1] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 126, s: [1] },
                                 { t: 142, s: [0] },
                              ],
                              ix: 7,
                           },
                        },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "CC Jaws",
                     np: 8,
                     mn: "CC Jaws",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 0, nm: "Completion", mn: "CC Jaws-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 3, nm: "Center", mn: "CC Jaws-0002", ix: 2, v: { a: 0, k: [323.5, 182], ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "CC Jaws-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                        { ty: 0, nm: "Height", mn: "CC Jaws-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Width", mn: "CC Jaws-0005", ix: 5, v: { a: 0, k: 10, ix: 5 } },
                        { ty: 7, nm: "Shape", mn: "CC Jaws-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "invert alpha",
                     np: 41,
                     mn: "ADBE Pro Levels",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 7, nm: "Channel:", mn: "ADBE Pro Levels-0001", ix: 1, v: { a: 0, k: 1, ix: 1 } },
                        {},
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0003", ix: 3, v: 0 },
                        { ty: 0, nm: "Input Black", mn: "ADBE Pro Levels-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Input White", mn: "ADBE Pro Levels-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 0, nm: "Gamma", mn: "ADBE Pro Levels-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                        { ty: 0, nm: "Output Black", mn: "ADBE Pro Levels-0007", ix: 7, v: { a: 0, k: 0, ix: 7 } },
                        { ty: 0, nm: "Output White", mn: "ADBE Pro Levels-0008", ix: 8, v: { a: 0, k: 1, ix: 8 } },
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0009", ix: 9, v: 0 },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0010", ix: 10, v: 0 },
                        { ty: 0, nm: "Red Input Black", mn: "ADBE Pro Levels-0011", ix: 11, v: { a: 0, k: 0, ix: 11 } },
                        { ty: 0, nm: "Red Input White", mn: "ADBE Pro Levels-0012", ix: 12, v: { a: 0, k: 1, ix: 12 } },
                        { ty: 0, nm: "Red Gamma", mn: "ADBE Pro Levels-0013", ix: 13, v: { a: 0, k: 1, ix: 13 } },
                        { ty: 0, nm: "Red Output Black", mn: "ADBE Pro Levels-0014", ix: 14, v: { a: 0, k: 0, ix: 14 } },
                        { ty: 0, nm: "Red Output White", mn: "ADBE Pro Levels-0015", ix: 15, v: { a: 0, k: 1, ix: 15 } },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0016", ix: 16, v: 0 },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0017", ix: 17, v: 0 },
                        { ty: 0, nm: "Green Input Black", mn: "ADBE Pro Levels-0018", ix: 18, v: { a: 0, k: 0, ix: 18 } },
                        { ty: 0, nm: "Green Input White", mn: "ADBE Pro Levels-0019", ix: 19, v: { a: 0, k: 1, ix: 19 } },
                        { ty: 0, nm: "Green Gamma", mn: "ADBE Pro Levels-0020", ix: 20, v: { a: 0, k: 1, ix: 20 } },
                        { ty: 0, nm: "Green Output Black", mn: "ADBE Pro Levels-0021", ix: 21, v: { a: 0, k: 0, ix: 21 } },
                        { ty: 0, nm: "Green Output White", mn: "ADBE Pro Levels-0022", ix: 22, v: { a: 0, k: 1, ix: 22 } },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0023", ix: 23, v: 0 },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0024", ix: 24, v: 0 },
                        { ty: 0, nm: "Blue Input Black", mn: "ADBE Pro Levels-0025", ix: 25, v: { a: 0, k: 0, ix: 25 } },
                        { ty: 0, nm: "Blue Input White", mn: "ADBE Pro Levels-0026", ix: 26, v: { a: 0, k: 1, ix: 26 } },
                        { ty: 0, nm: "Blue Gamma", mn: "ADBE Pro Levels-0027", ix: 27, v: { a: 0, k: 1, ix: 27 } },
                        { ty: 0, nm: "Blue Output Black", mn: "ADBE Pro Levels-0028", ix: 28, v: { a: 0, k: 0, ix: 28 } },
                        { ty: 0, nm: "Blue Output White", mn: "ADBE Pro Levels-0029", ix: 29, v: { a: 0, k: 1, ix: 29 } },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0030", ix: 30, v: 0 },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0031", ix: 31, v: 0 },
                        { ty: 0, nm: "Alpha Input Black", mn: "ADBE Pro Levels-0032", ix: 32, v: { a: 0, k: 0, ix: 32 } },
                        { ty: 0, nm: "Alpha Input White", mn: "ADBE Pro Levels-0033", ix: 33, v: { a: 0, k: 1, ix: 33 } },
                        { ty: 0, nm: "Alpha Gamma", mn: "ADBE Pro Levels-0034", ix: 34, v: { a: 0, k: 1, ix: 34 } },
                        { ty: 0, nm: "Alpha Output Black", mn: "ADBE Pro Levels-0035", ix: 35, v: { a: 0, k: 1, ix: 35 } },
                        { ty: 0, nm: "Alpha Output White", mn: "ADBE Pro Levels-0036", ix: 36, v: { a: 0, k: 0, ix: 36 } },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0037", ix: 37, v: 0 },
                        { ty: 7, nm: "Clip To Output Black", mn: "ADBE Pro Levels-0038", ix: 38, v: { a: 0, k: 1, ix: 38 } },
                        { ty: 7, nm: "Clip To Output White", mn: "ADBE Pro Levels-0039", ix: 39, v: { a: 0, k: 1, ix: 39 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "clip to object alpha",
                     np: 8,
                     mn: "ADBE Set Matte2",
                     ix: 4,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Take Matte From Layer", mn: "ADBE Set Matte2-0001", ix: 1, v: { a: 0, k: 6, ix: 1 } },
                        { ty: 7, nm: "Use For Matte", mn: "ADBE Set Matte2-0002", ix: 2, v: { a: 0, k: 4, ix: 2 } },
                        { ty: 7, nm: "Invert Matte", mn: "ADBE Set Matte2-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
                        { ty: 7, nm: "If Layer Sizes Differ", mn: "ADBE Set Matte2-0004", ix: 4, v: { a: 0, k: 1, ix: 4 } },
                        { ty: 7, nm: "Composite Matte with Original", mn: "ADBE Set Matte2-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 7, nm: "Premultiply Matte Layer", mn: "ADBE Set Matte2-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                     ],
                  },
                  {
                     ty: 5,
                     nm: "clean up edges",
                     np: 41,
                     mn: "ADBE Pro Levels",
                     ix: 5,
                     en: 1,
                     ef: [
                        { ty: 7, nm: "Channel:", mn: "ADBE Pro Levels-0001", ix: 1, v: { a: 0, k: 1, ix: 1 } },
                        {},
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0003", ix: 3, v: 0 },
                        { ty: 0, nm: "Input Black", mn: "ADBE Pro Levels-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Input White", mn: "ADBE Pro Levels-0005", ix: 5, v: { a: 0, k: 1, ix: 5 } },
                        { ty: 0, nm: "Gamma", mn: "ADBE Pro Levels-0006", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                        { ty: 0, nm: "Output Black", mn: "ADBE Pro Levels-0007", ix: 7, v: { a: 0, k: 0, ix: 7 } },
                        { ty: 0, nm: "Output White", mn: "ADBE Pro Levels-0008", ix: 8, v: { a: 0, k: 1, ix: 8 } },
                        { ty: 6, nm: "RGB", mn: "ADBE Pro Levels-0009", ix: 9, v: 0 },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0010", ix: 10, v: 0 },
                        { ty: 0, nm: "Red Input Black", mn: "ADBE Pro Levels-0011", ix: 11, v: { a: 0, k: 0, ix: 11 } },
                        { ty: 0, nm: "Red Input White", mn: "ADBE Pro Levels-0012", ix: 12, v: { a: 0, k: 1, ix: 12 } },
                        { ty: 0, nm: "Red Gamma", mn: "ADBE Pro Levels-0013", ix: 13, v: { a: 0, k: 1, ix: 13 } },
                        { ty: 0, nm: "Red Output Black", mn: "ADBE Pro Levels-0014", ix: 14, v: { a: 0, k: 0, ix: 14 } },
                        { ty: 0, nm: "Red Output White", mn: "ADBE Pro Levels-0015", ix: 15, v: { a: 0, k: 1, ix: 15 } },
                        { ty: 6, nm: "Red", mn: "ADBE Pro Levels-0016", ix: 16, v: 0 },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0017", ix: 17, v: 0 },
                        { ty: 0, nm: "Green Input Black", mn: "ADBE Pro Levels-0018", ix: 18, v: { a: 0, k: 0, ix: 18 } },
                        { ty: 0, nm: "Green Input White", mn: "ADBE Pro Levels-0019", ix: 19, v: { a: 0, k: 1, ix: 19 } },
                        { ty: 0, nm: "Green Gamma", mn: "ADBE Pro Levels-0020", ix: 20, v: { a: 0, k: 1, ix: 20 } },
                        { ty: 0, nm: "Green Output Black", mn: "ADBE Pro Levels-0021", ix: 21, v: { a: 0, k: 0, ix: 21 } },
                        { ty: 0, nm: "Green Output White", mn: "ADBE Pro Levels-0022", ix: 22, v: { a: 0, k: 1, ix: 22 } },
                        { ty: 6, nm: "Green", mn: "ADBE Pro Levels-0023", ix: 23, v: 0 },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0024", ix: 24, v: 0 },
                        { ty: 0, nm: "Blue Input Black", mn: "ADBE Pro Levels-0025", ix: 25, v: { a: 0, k: 0, ix: 25 } },
                        { ty: 0, nm: "Blue Input White", mn: "ADBE Pro Levels-0026", ix: 26, v: { a: 0, k: 1, ix: 26 } },
                        { ty: 0, nm: "Blue Gamma", mn: "ADBE Pro Levels-0027", ix: 27, v: { a: 0, k: 1, ix: 27 } },
                        { ty: 0, nm: "Blue Output Black", mn: "ADBE Pro Levels-0028", ix: 28, v: { a: 0, k: 0, ix: 28 } },
                        { ty: 0, nm: "Blue Output White", mn: "ADBE Pro Levels-0029", ix: 29, v: { a: 0, k: 1, ix: 29 } },
                        { ty: 6, nm: "Blue", mn: "ADBE Pro Levels-0030", ix: 30, v: 0 },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0031", ix: 31, v: 0 },
                        { ty: 0, nm: "Alpha Input Black", mn: "ADBE Pro Levels-0032", ix: 32, v: { a: 0, k: 0.255, ix: 32 } },
                        { ty: 0, nm: "Alpha Input White", mn: "ADBE Pro Levels-0033", ix: 33, v: { a: 0, k: 1, ix: 33 } },
                        { ty: 0, nm: "Alpha Gamma", mn: "ADBE Pro Levels-0034", ix: 34, v: { a: 0, k: 1, ix: 34 } },
                        { ty: 0, nm: "Alpha Output Black", mn: "ADBE Pro Levels-0035", ix: 35, v: { a: 0, k: 0, ix: 35 } },
                        { ty: 0, nm: "Alpha Output White", mn: "ADBE Pro Levels-0036", ix: 36, v: { a: 0, k: 1, ix: 36 } },
                        { ty: 6, nm: "Alpha", mn: "ADBE Pro Levels-0037", ix: 37, v: 0 },
                        { ty: 7, nm: "Clip To Output Black", mn: "ADBE Pro Levels-0038", ix: 38, v: { a: 0, k: 1, ix: 38 } },
                        { ty: 7, nm: "Clip To Output White", mn: "ADBE Pro Levels-0039", ix: 39, v: { a: 0, k: 1, ix: 39 } },
                     ],
                  },
               ],
               t: { d: { k: [{ s: { s: 36, f: "Intro", t: "INSPIRE", j: 2, tr: 200, lh: 43.2, ls: 0, fc: [1, 0, 0.856] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 7,
               ty: 0,
               nm: "Big Text1",
               refId: "comp_10",
               sr: 1,
               ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                     a: 1,
                     k: [
                        { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 30, s: [323.5, 182, 0], to: [0, 5, 0], ti: [0, -5, 0] },
                        { i: { x: 0, y: 0 }, o: { x: 0.333, y: 0.333 }, t: 60, s: [323.5, 212, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                        { i: { x: 0.758, y: 1 }, o: { x: 1, y: 0 }, t: 120, s: [323.5, 212, 0], to: [0, -5, 0], ti: [0, 5, 0] },
                        { t: 150, s: [323.5, 182, 0] },
                     ],
                     ix: 2,
                     l: 2,
                  },
                  a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [1, 0, 0, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Big Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Fill-0005", ix: 7, v: { a: 0, k: 1, ix: 7 } },
                     ],
                  },
               ],
               w: 1920,
               h: 1080,
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 8,
               ty: 0,
               nm: "Text_Top1",
               refId: "comp_11",
               sr: 1,
               ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                     a: 1,
                     k: [
                        { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 30, s: [323.5, 182, 0], to: [0, -5, 0], ti: [0, 5, 0] },
                        { i: { x: 0, y: 0 }, o: { x: 0.333, y: 0.333 }, t: 60, s: [323.5, 152, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                        { i: { x: 0.758, y: 1 }, o: { x: 1, y: 0 }, t: 120, s: [323.5, 152, 0], to: [0, 5, 0], ti: [0, -5, 0] },
                        { t: 150, s: [323.5, 182, 0] },
                     ],
                     ix: 2,
                     l: 2,
                  },
                  a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 21,
                     nm: "Fill",
                     np: 9,
                     mn: "ADBE Fill",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 10, nm: "Fill Mask", mn: "ADBE Fill-0001", ix: 1, v: { a: 0, k: 0, ix: 1 } },
                        { ty: 7, nm: "All Masks", mn: "ADBE Fill-0007", ix: 2, v: { a: 0, k: 0, ix: 2 } },
                        { ty: 2, nm: "Color", mn: "ADBE Fill-0002", ix: 3, v: { a: 0, k: [1, 0, 0, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = thisComp.layer('Color Control').effect('Big Text Color')('Color');" } },
                        { ty: 7, nm: "Invert", mn: "ADBE Fill-0006", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Horizontal Feather", mn: "ADBE Fill-0003", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 0, nm: "Vertical Feather", mn: "ADBE Fill-0004", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Fill-0005", ix: 7, v: { a: 0, k: 1, ix: 7 } },
                     ],
                  },
               ],
               w: 1920,
               h: 1080,
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_9",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 4,
               nm: "Line_White",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [870, 565, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [62, 62, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-213.124, 40.314],
                                    [-286.326, 40.314],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        {
                           ty: "st",
                           c: { a: 0, k: [0.783529423732, 0.783529423732, 0.783529423732, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = comp('ideate').layer('Color Control').effect('Small Line Color')('Color');" },
                           o: { a: 0, k: 100, ix: 4 },
                           w: { a: 0, k: 5, ix: 5 },
                           lc: 2,
                           lj: 1,
                           ml: 4,
                           bm: 0,
                           nm: "Stroke 1",
                           mn: "ADBE Vector Graphic - Stroke",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 6.632, s: [0] },
                           { t: 14, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                           { t: 6.6318359375, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 4,
               nm: "Line_Red",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-213.124, 40.314],
                                    [-286.326, 40.314],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        {
                           ty: "st",
                           c: { a: 0, k: [0.993743896484, 0.217437744141, 0.217437744141, 1], ix: 3, x: "var $bm_rt;\n$bm_rt = comp('ideate').layer('Color Control').effect('Big Line Color')('Color');" },
                           o: { a: 0, k: 100, ix: 4 },
                           w: { a: 0, k: 5, ix: 5 },
                           lc: 2,
                           lj: 1,
                           ml: 4,
                           bm: 0,
                           nm: "Stroke 1",
                           mn: "ADBE Vector Graphic - Stroke",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 9.632, s: [0] },
                           { t: 17, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 3, s: [0] },
                           { t: 9.6318359375, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 3,
               op: 303,
               st: 3,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_10",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 1,
               nm: "Mask1",
               td: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 810, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 50, 100], ix: 6, l: 2 } },
               ao: 0,
               sw: 1920,
               sh: 1080,
               sc: "#ffffff",
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 5,
               nm: "inspire",
               tt: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [959.774, 575.097, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { s: 100, f: "Intro", t: "INSPIRE", j: 2, tr: 0, lh: 120, ls: 0, fc: [0.994, 0.217, 0.217] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_11",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 1,
               nm: "Mask1",
               td: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 270, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 50, 100], ix: 6, l: 2 } },
               ao: 0,
               sw: 1920,
               sh: 1080,
               sc: "#ffffff",
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 5,
               nm: "ideate",
               tt: 1,
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [959.774, 575.097, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { x: "var $bm_rt;\n$bm_rt = comp('Big Text1').layer('inspire').text.sourceText;", k: [{ s: { s: 100, f: "Intro", t: "INSPIRE", j: 2, tr: 0, lh: 120, ls: 0, fc: [0.994, 0.217, 0.217] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 300,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_12",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 2,
               nm: "Layer 2/BigOp (1).ai",
               cl: "ai",
               refId: "image_0",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [589.5, 703, 0], ix: 2, l: 2 }, a: { a: 0, k: [589.5, 703, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               ip: 0,
               op: 480,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 2,
               nm: "Layer 1/BigOp (1).ai",
               cl: "ai",
               refId: "image_1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [589.5, 703, 0], ix: 2, l: 2 }, a: { a: 0, k: [589.5, 703, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               ip: 0,
               op: 480,
               st: 0,
               bm: 0,
            },
         ],
      },
   ],
   fonts: { list: [{ fName: "Intro", fFamily: "Intro", fStyle: "Regular", ascent: 69.4992065429688 }] },
   layers: [
      {
         ddd: 0,
         ind: 1,
         ty: 0,
         nm: "engineer",
         refId: "comp_0",
         sr: 1,
         ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [1119, 930, 0], ix: 2, l: 2 }, a: { a: 0, k: [375, 211, 0], ix: 1, l: 2 }, s: { a: 0, k: [184, 184, 100], ix: 6, l: 2 } },
         ao: 0,
         w: 750,
         h: 422,
         ip: 318,
         op: 618,
         st: 318,
         bm: 0,
      },
      {
         ddd: 0,
         ind: 2,
         ty: 0,
         nm: "innovate",
         refId: "comp_4",
         sr: 1,
         ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1119, 930, 0], ix: 2, l: 2 },
            a: { a: 0, k: [375, 211, 0], ix: 1, l: 2 },
            s: {
               a: 1,
               k: [
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 0, s: [184, 184, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 316, s: [184, 184, 100] },
                  { t: 317, s: [0, 0, 100] },
               ],
               ix: 6,
               l: 2,
            },
         },
         ao: 0,
         w: 750,
         h: 422,
         ip: 159,
         op: 459,
         st: 159,
         bm: 0,
      },
      {
         ddd: 0,
         ind: 3,
         ty: 0,
         nm: "ideate",
         refId: "comp_8",
         sr: 1,
         ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1119, 930, 0], ix: 2, l: 2 },
            a: { a: 0, k: [323.5, 182, 0], ix: 1, l: 2 },
            s: {
               a: 1,
               k: [
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 0, s: [184, 184, 100] },
                  { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 158, s: [184, 184, 100] },
                  { t: 159, s: [0, 0, 100] },
               ],
               ix: 6,
               l: 2,
            },
         },
         ao: 0,
         w: 647,
         h: 364,
         ip: 0,
         op: 300,
         st: 0,
         bm: 0,
      },
      {
         ddd: 0,
         ind: 4,
         ty: 0,
         nm: "BigOp",
         refId: "comp_12",
         sr: 1,
         ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
               a: 1,
               k: [
                  { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [1119, 519, 0], to: [0, 6.667, 0], ti: [0, 0, 0] },
                  { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 92, s: [1119, 559, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                  { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 180, s: [1119, 519, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                  { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 271, s: [1119, 559, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                  { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 338, s: [1119, 519, 0], to: [0, 0, 0], ti: [0, 0, 0] },
                  { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 421, s: [1119, 559, 0], to: [0, 0, 0], ti: [0, 6.667, 0] },
                  { t: 479, s: [1119, 519, 0] },
               ],
               ix: 2,
               l: 2,
            },
            a: { a: 0, k: [589.5, 703, 0], ix: 1, l: 2 },
            s: { a: 0, k: [98, 98, 100], ix: 6, l: 2 },
         },
         ao: 0,
         w: 1179,
         h: 1406,
         ip: 0,
         op: 480,
         st: 0,
         bm: 0,
      },
   ],
   markers: [],
   chars: [
      {
         ch: "I",
         size: 100,
         style: "Regular",
         w: 33.3,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [1.399, 0.781],
                                 [0.325, 0.814],
                                 [0, 3.353],
                                 [0, 0],
                                 [-0.814, 1.042],
                                 [-2.702, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.433, -0.781],
                                 [-0.326, -0.813],
                                 [0, -3.352],
                                 [0, 0],
                                 [0.781, -1.041],
                                 [2.734, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [-1.562, 0],
                                 [-1.042, -0.586],
                                 [-0.423, -1.074],
                                 [0, 0],
                                 [0, -3.971],
                                 [1.237, -1.595],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.562, 0],
                                 [1.009, 0.586],
                                 [0.423, 1.074],
                                 [0, 0],
                                 [0, 3.972],
                                 [-1.237, 1.595],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [28.467, -1.807],
                                 [24.023, -2.979],
                                 [21.973, -5.078],
                                 [21.338, -11.719],
                                 [21.338, -54.492],
                                 [22.559, -62.012],
                                 [28.467, -64.404],
                                 [30.859, -64.404],
                                 [30.859, -66.211],
                                 [2.49, -66.211],
                                 [2.49, -64.404],
                                 [4.834, -64.404],
                                 [9.326, -63.232],
                                 [11.328, -61.133],
                                 [11.963, -54.492],
                                 [11.963, -11.719],
                                 [10.791, -4.199],
                                 [4.834, -1.807],
                                 [2.49, -1.807],
                                 [2.49, 0],
                                 [30.859, 0],
                                 [30.859, -1.807],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "I",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "I",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "N",
         size: 100,
         style: "Regular",
         w: 72.22,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [-0.945, -0.293],
                                 [-0.993, -0.716],
                                 [-1.921, -2.246],
                                 [0, 0],
                                 [0.716, -1.041],
                                 [2.832, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.237, 1.367],
                                 [0, 4.07],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-0.716, 1.042],
                                 [-2.8, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.205, -1.367],
                                 [0, -4.069],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [1.92, 0],
                                 [1.92, 0.619],
                                 [0.993, 0.716],
                                 [0, 0],
                                 [0, 3.711],
                                 [-1.172, 1.693],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.572, 0],
                                 [-0.879, -1.009],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, -3.711],
                                 [1.172, -1.692],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.604, 0],
                                 [0.911, 1.009],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [-1.318, -64.404],
                                 [2.979, -63.965],
                                 [7.349, -61.963],
                                 [11.719, -57.52],
                                 [11.719, -11.475],
                                 [10.645, -4.346],
                                 [4.639, -1.807],
                                 [2.344, -1.807],
                                 [2.344, 0],
                                 [25.391, 0],
                                 [25.391, -1.807],
                                 [23.047, -1.807],
                                 [17.334, -3.857],
                                 [16.016, -11.475],
                                 [16.016, -52.246],
                                 [59.668, 1.074],
                                 [61.426, 1.074],
                                 [61.426, -54.736],
                                 [62.5, -61.865],
                                 [68.457, -64.404],
                                 [70.801, -64.404],
                                 [70.801, -66.211],
                                 [47.754, -66.211],
                                 [47.754, -64.404],
                                 [50.049, -64.404],
                                 [55.762, -62.354],
                                 [57.129, -54.736],
                                 [57.129, -16.553],
                                 [16.65, -66.211],
                                 [-1.318, -66.211],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "N",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "N",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "S",
         size: 100,
         style: "Regular",
         w: 55.62,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0.537, -0.504],
                                 [0.846, 0],
                                 [1.823, 0.814],
                                 [3.516, 0],
                                 [3.645, -3.434],
                                 [0, -4.752],
                                 [-1.237, -2.278],
                                 [-2.393, -1.888],
                                 [-5.664, -3.174],
                                 [-1.27, -0.977],
                                 [-0.896, -1.66],
                                 [0, -1.627],
                                 [2.36, -2.148],
                                 [4.069, 0],
                                 [2.946, 1.562],
                                 [1.432, 2.361],
                                 [0.846, 4.753],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-0.472, 0.488],
                                 [-0.814, 0],
                                 [-3.174, -1.025],
                                 [-1.009, -0.163],
                                 [-1.921, 0],
                                 [-3.89, 3.63],
                                 [0, 5.013],
                                 [1.221, 2.409],
                                 [2.262, 1.775],
                                 [6.184, 3.32],
                                 [2.018, 2.441],
                                 [0, 2.019],
                                 [-2.214, 2.068],
                                 [-3.386, 0],
                                 [-2.8, -1.53],
                                 [-1.514, -2.604],
                                 [-0.586, -4.395],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [-0.326, 1.53],
                                 [-0.537, 0.505],
                                 [-0.749, 0],
                                 [-3.939, -1.725],
                                 [-5.632, 0],
                                 [-3.646, 3.435],
                                 [0, 2.734],
                                 [1.237, 2.279],
                                 [2.393, 1.888],
                                 [5.664, 3.174],
                                 [1.888, 1.433],
                                 [0.895, 1.66],
                                 [0, 2.93],
                                 [-2.361, 2.148],
                                 [-3.516, 0],
                                 [-2.946, -1.562],
                                 [-1.433, -2.36],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0.227, -1.53],
                                 [0.471, -0.488],
                                 [0.846, 0],
                                 [3.174, 1.025],
                                 [1.692, 0.293],
                                 [6.12, 0],
                                 [3.889, -3.629],
                                 [0, -2.637],
                                 [-1.221, -2.409],
                                 [-2.263, -1.774],
                                 [-7.585, -4.069],
                                 [-1.4, -1.66],
                                 [0, -2.637],
                                 [2.213, -2.067],
                                 [2.995, 0],
                                 [2.799, 1.53],
                                 [1.514, 2.605],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [44.043, -67.725],
                                 [42.749, -64.673],
                                 [40.674, -63.916],
                                 [36.816, -65.137],
                                 [25.635, -67.725],
                                 [11.719, -62.573],
                                 [6.25, -50.293],
                                 [8.105, -42.773],
                                 [13.55, -36.523],
                                 [25.635, -28.931],
                                 [36.035, -22.705],
                                 [40.21, -18.066],
                                 [41.553, -13.135],
                                 [38.013, -5.518],
                                 [28.369, -2.295],
                                 [18.677, -4.639],
                                 [12.109, -10.522],
                                 [8.691, -21.191],
                                 [6.885, -21.191],
                                 [6.885, 1.514],
                                 [8.691, 1.514],
                                 [9.741, -1.514],
                                 [11.67, -2.246],
                                 [17.7, -0.708],
                                 [23.975, 1.074],
                                 [29.395, 1.514],
                                 [44.409, -3.931],
                                 [50.244, -16.895],
                                 [48.413, -24.463],
                                 [43.188, -30.737],
                                 [30.518, -38.379],
                                 [16.113, -48.145],
                                 [14.014, -53.662],
                                 [17.334, -60.718],
                                 [25.732, -63.818],
                                 [34.424, -61.523],
                                 [40.894, -55.322],
                                 [44.043, -44.824],
                                 [45.85, -44.824],
                                 [45.85, -67.725],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "S",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "S",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "P",
         size: 100,
         style: "Regular",
         w: 55.62,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-1.855, -0.227],
                                 [-1.595, 0],
                                 [-3.564, 3.386],
                                 [0, 5.404],
                                 [2.344, 2.898],
                                 [3.418, 1.221],
                                 [5.924, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.27, -1.432],
                                 [0, -4.166],
                                 [0, 0],
                                 [0.651, -1.009],
                                 [2.799, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.237, 1.433],
                                 [0, 4.167],
                                 [0, 0],
                              ],
                              o: [
                                 [1.855, 0.228],
                                 [6.51, 0],
                                 [3.564, -3.385],
                                 [0, -3.971],
                                 [-2.344, -2.897],
                                 [-3.418, -1.221],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.506, 0],
                                 [0.879, 1.009],
                                 [0, 0],
                                 [0, 3.777],
                                 [-1.205, 1.823],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.507, 0],
                                 [-0.912, -1.009],
                                 [0, 0],
                                 [2.116, 0.456],
                              ],
                              v: [
                                 [26.465, -29.98],
                                 [31.641, -29.639],
                                 [46.753, -34.717],
                                 [52.1, -47.9],
                                 [48.584, -58.203],
                                 [39.941, -64.38],
                                 [25.928, -66.211],
                                 [1.66, -66.211],
                                 [1.66, -64.404],
                                 [4.15, -64.404],
                                 [9.814, -62.256],
                                 [11.133, -54.492],
                                 [11.133, -11.719],
                                 [10.156, -4.541],
                                 [4.15, -1.807],
                                 [1.66, -1.807],
                                 [1.66, 0],
                                 [30.029, 0],
                                 [30.029, -1.807],
                                 [27.49, -1.807],
                                 [21.875, -3.955],
                                 [20.508, -11.719],
                                 [20.508, -31.006],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "P",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [-1.66, 0],
                                 [-2.148, -1.221],
                                 [-1.172, -2.457],
                                 [0, -2.864],
                                 [2.457, -2.62],
                                 [3.385, 0],
                                 [1.334, 0.163],
                                 [1.725, 0.326],
                              ],
                              o: [
                                 [2.604, -0.618],
                                 [2.734, 0],
                                 [2.148, 1.221],
                                 [1.172, 2.458],
                                 [0, 4.167],
                                 [-2.458, 2.621],
                                 [-0.945, 0],
                                 [-1.335, -0.163],
                                 [0, 0],
                              ],
                              v: [
                                 [20.508, -61.328],
                                 [26.904, -62.256],
                                 [34.229, -60.425],
                                 [39.209, -54.907],
                                 [40.967, -46.924],
                                 [37.28, -36.743],
                                 [28.516, -32.812],
                                 [25.098, -33.057],
                                 [20.508, -33.789],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "P",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "P",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "R",
         size: 100,
         style: "Regular",
         w: 66.7,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [2.246, 1.433],
                                 [3.125, 4.362],
                                 [0, 0],
                                 [-2.654, 2.995],
                                 [0, 4.07],
                                 [2.327, 2.751],
                                 [3.336, 1.025],
                                 [7.031, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.27, -1.432],
                                 [0, -4.166],
                                 [0, 0],
                                 [0.684, -1.009],
                                 [2.799, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.237, 1.433],
                                 [0, 4.167],
                                 [0, 0],
                                 [-0.488, -0.016],
                                 [-0.423, 0],
                                 [-1.66, 0.066],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [-3.613, -0.391],
                                 [-2.246, -1.432],
                                 [0, 0],
                                 [5.566, -1.237],
                                 [2.653, -2.995],
                                 [0, -3.809],
                                 [-2.328, -2.75],
                                 [-3.337, -1.025],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.506, 0],
                                 [0.911, 1.009],
                                 [0, 0],
                                 [0, 3.777],
                                 [-1.205, 1.823],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.474, 0],
                                 [-0.912, -1.009],
                                 [0, 0],
                                 [0.52, 0.033],
                                 [0.488, 0.017],
                                 [1.041, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [67.578, -1.807],
                                 [58.789, -4.541],
                                 [50.732, -13.232],
                                 [37.061, -32.227],
                                 [49.39, -38.574],
                                 [53.369, -49.17],
                                 [49.878, -59.009],
                                 [41.382, -64.673],
                                 [25.83, -66.211],
                                 [1.709, -66.211],
                                 [1.709, -64.404],
                                 [4.199, -64.404],
                                 [9.863, -62.256],
                                 [11.23, -54.492],
                                 [11.23, -11.719],
                                 [10.205, -4.541],
                                 [4.199, -1.807],
                                 [1.709, -1.807],
                                 [1.709, 0],
                                 [30.078, 0],
                                 [30.078, -1.807],
                                 [27.49, -1.807],
                                 [21.924, -3.955],
                                 [20.557, -11.719],
                                 [20.557, -30.957],
                                 [22.07, -30.884],
                                 [23.438, -30.859],
                                 [27.49, -30.957],
                                 [49.902, 0],
                                 [67.578, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "R",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [-1.888, 0],
                                 [-2.588, -2.588],
                                 [0, -4.134],
                                 [3.206, -2.734],
                                 [6.315, 0],
                                 [0.455, 0.017],
                                 [0.618, 0],
                              ],
                              o: [
                                 [3.223, -0.618],
                                 [4.264, 0],
                                 [2.588, 2.588],
                                 [0, 4.232],
                                 [-3.207, 2.734],
                                 [-0.293, 0],
                                 [-0.456, -0.016],
                                 [0, 0],
                              ],
                              v: [
                                 [20.557, -61.572],
                                 [28.223, -62.5],
                                 [38.501, -58.618],
                                 [42.383, -48.535],
                                 [37.573, -38.086],
                                 [23.291, -33.984],
                                 [22.168, -34.009],
                                 [20.557, -34.033],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "R",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "R",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "E",
         size: 100,
         style: "Regular",
         w: 61.08,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [-1.302, -0.488],
                                 [-0.83, -1.302],
                                 [-0.684, -3.483],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.172, -1.302],
                                 [0, -4.199],
                                 [0, 0],
                                 [0.374, -1.041],
                                 [1.041, -0.52],
                                 [1.562, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.018, -2.018],
                                 [1.692, -0.52],
                                 [3.743, 0],
                                 [0, 0],
                                 [0.651, 0.375],
                                 [0.26, 0.635],
                                 [0, 2.93],
                                 [0, 0],
                                 [0, 0],
                                 [-1.335, -0.651],
                                 [-0.586, -1.139],
                                 [-0.456, -3.223],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.692, -1.497],
                                 [3.776, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [3.288, 0],
                                 [1.627, 0.684],
                                 [0.83, 1.302],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.734, 0],
                                 [0.846, 0.977],
                                 [0, 0],
                                 [0, 3.223],
                                 [-0.375, 1.042],
                                 [-1.4, 0.749],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.116, 4.07],
                                 [-1.562, 1.53],
                                 [-1.693, 0.521],
                                 [0, 0],
                                 [-1.823, 0],
                                 [-0.652, -0.374],
                                 [-0.261, -0.635],
                                 [0, 0],
                                 [0, 0],
                                 [2.766, 0],
                                 [1.334, 0.652],
                                 [0.455, 0.912],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-0.195, 3.777],
                                 [-1.27, 1.14],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [39.062, -62.598],
                                 [45.947, -61.865],
                                 [49.634, -58.887],
                                 [51.904, -51.709],
                                 [53.809, -51.709],
                                 [53.076, -66.211],
                                 [2.051, -66.211],
                                 [2.051, -64.404],
                                 [4.395, -64.404],
                                 [10.254, -62.451],
                                 [11.523, -54.688],
                                 [11.523, -11.67],
                                 [10.962, -5.273],
                                 [8.838, -2.93],
                                 [4.395, -1.807],
                                 [2.051, -1.807],
                                 [2.051, 0],
                                 [53.076, 0],
                                 [58.789, -16.602],
                                 [56.836, -16.602],
                                 [50.635, -7.471],
                                 [45.752, -4.395],
                                 [37.598, -3.613],
                                 [26.367, -3.613],
                                 [22.656, -4.175],
                                 [21.289, -5.688],
                                 [20.898, -11.035],
                                 [20.898, -32.861],
                                 [35.449, -32.861],
                                 [41.602, -31.885],
                                 [44.482, -29.199],
                                 [45.85, -22.998],
                                 [47.656, -22.998],
                                 [47.656, -46.045],
                                 [45.85, -46.045],
                                 [43.018, -38.135],
                                 [35.449, -36.426],
                                 [20.898, -36.426],
                                 [20.898, -62.598],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "E",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "E",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "O",
         size: 100,
         style: "Regular",
         w: 72.22,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [6.217, -5.762],
                                 [0, -10.514],
                                 [-6.201, -6.477],
                                 [-9.115, 0],
                                 [-6.348, 6.641],
                                 [0, 10.059],
                                 [6.299, 6.527],
                                 [8.594, 0],
                              ],
                              o: [
                                 [-7.162, 6.641],
                                 [0, 10.287],
                                 [6.201, 6.478],
                                 [9.016, 0],
                                 [6.348, -6.641],
                                 [0, -9.766],
                                 [-6.299, -6.526],
                                 [-8.659, 0],
                              ],
                              v: [
                                 [14.258, -59.082],
                                 [3.516, -33.35],
                                 [12.817, -8.203],
                                 [35.791, 1.514],
                                 [58.838, -8.447],
                                 [68.359, -33.496],
                                 [58.911, -57.935],
                                 [36.572, -67.725],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "O",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-3.581, -4.427],
                                 [0, -11.328],
                                 [4.053, -4.85],
                                 [6.217, 0],
                                 [3.548, 4.427],
                                 [0, 10.808],
                                 [-4.46, 5.469],
                                 [-5.925, 0],
                              ],
                              o: [
                                 [4.459, 5.566],
                                 [0, 10.449],
                                 [-4.053, 4.851],
                                 [-5.827, 0],
                                 [-4.623, -5.827],
                                 [0, -10.547],
                                 [3.58, -4.395],
                                 [6.315, 0],
                              ],
                              v: [
                                 [50.488, -57.52],
                                 [57.178, -32.178],
                                 [51.099, -9.229],
                                 [35.693, -1.953],
                                 [21.631, -8.594],
                                 [14.697, -33.545],
                                 [21.387, -57.568],
                                 [35.645, -64.16],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "O",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "O",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "V",
         size: 100,
         style: "Regular",
         w: 72.22,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [-0.163, -0.032],
                                 [-0.945, -0.862],
                                 [0, -0.944],
                                 [1.53, -3.776],
                                 [0, 0],
                                 [0, 0],
                                 [0, 1.628],
                                 [-0.912, 0.749],
                                 [-3.06, 0.293],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.189, -0.569],
                                 [-0.814, -1.009],
                                 [-1.27, -2.93],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.726, 1.595],
                                 [-2.344, 0.423],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0.391, 0.066],
                                 [2.246, 0.359],
                                 [0.944, 0.863],
                                 [0, 1.465],
                                 [0, 0],
                                 [0, 0],
                                 [-1.498, -3.45],
                                 [0, -1.172],
                                 [0.911, -0.748],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.018, 0.163],
                                 [1.188, 0.57],
                                 [0.52, 0.652],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.334, -3.288],
                                 [1.204, -1.074],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [50.586, -66.211],
                                 [50.586, -64.404],
                                 [51.416, -64.258],
                                 [56.201, -62.427],
                                 [57.617, -59.717],
                                 [55.322, -51.855],
                                 [39.697, -13.525],
                                 [22.852, -52.344],
                                 [20.605, -59.961],
                                 [21.973, -62.842],
                                 [27.93, -64.404],
                                 [27.93, -66.211],
                                 [0.879, -66.211],
                                 [0.879, -64.404],
                                 [5.688, -63.306],
                                 [8.691, -60.938],
                                 [11.377, -55.566],
                                 [36.182, 1.514],
                                 [37.988, 1.514],
                                 [61.084, -54.834],
                                 [65.674, -62.158],
                                 [70.996, -64.404],
                                 [70.996, -66.211],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "V",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "V",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "A",
         size: 100,
         style: "Regular",
         w: 72.22,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, -1.432],
                                 [0.928, -0.748],
                                 [2.637, -0.13],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.514, 1.318],
                                 [1.855, 4.427],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.66, -1.562],
                                 [2.766, -0.488],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0.96, 0.765],
                                 [0, 1.009],
                                 [-1.107, 2.572],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [1.302, 3.093],
                                 [0, 1.074],
                                 [-0.928, 0.749],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.702, -0.163],
                                 [-1.514, -1.318],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.019, 4.785],
                                 [-0.814, 0.781],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-3.191, -0.227],
                                 [-0.961, -0.764],
                                 [0, -1.27],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [49.756, -12.646],
                                 [51.709, -5.859],
                                 [50.317, -3.125],
                                 [44.971, -1.807],
                                 [44.971, 0],
                                 [71.094, 0],
                                 [71.094, -1.807],
                                 [64.771, -4.028],
                                 [59.717, -12.646],
                                 [36.67, -67.725],
                                 [34.961, -67.725],
                                 [11.67, -13.232],
                                 [6.152, -3.711],
                                 [0.781, -1.807],
                                 [0.781, 0],
                                 [21.631, 0],
                                 [21.631, -1.807],
                                 [15.405, -3.296],
                                 [13.965, -5.957],
                                 [15.625, -11.719],
                                 [20.117, -22.168],
                                 [45.752, -22.168],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "A",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [21.631, -25.781],
                                 [33.154, -52.539],
                                 [44.385, -25.781],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "A",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "A",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "T",
         size: 100,
         style: "Regular",
         w: 61.08,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.205, 1.758],
                                 [-1.693, 0.619],
                                 [-3.093, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0.716, -1.041],
                                 [2.799, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.237, 1.367],
                                 [0, 4.07],
                                 [0, 0],
                                 [0, 0],
                                 [-1.677, -0.895],
                                 [-1.009, -1.888],
                                 [-0.359, -2.734],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0.227, -2.995],
                                 [1.204, -1.758],
                                 [1.302, -0.455],
                                 [0, 0],
                                 [0, 0],
                                 [0, 3.711],
                                 [-1.172, 1.693],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.572, 0],
                                 [-0.879, -1.009],
                                 [0, 0],
                                 [0, 0],
                                 [2.734, 0],
                                 [1.676, 0.896],
                                 [0.618, 1.172],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [3.809, -66.211],
                                 [3.027, -50.684],
                                 [4.883, -50.684],
                                 [7.031, -57.812],
                                 [11.377, -61.377],
                                 [17.969, -62.061],
                                 [25.928, -62.061],
                                 [25.928, -11.475],
                                 [24.854, -4.346],
                                 [18.896, -1.807],
                                 [16.553, -1.807],
                                 [16.553, 0],
                                 [44.629, 0],
                                 [44.629, -1.807],
                                 [42.334, -1.807],
                                 [36.621, -3.857],
                                 [35.303, -11.475],
                                 [35.303, -62.061],
                                 [44.629, -62.061],
                                 [51.245, -60.718],
                                 [55.273, -56.543],
                                 [56.738, -50.684],
                                 [58.594, -50.684],
                                 [57.861, -66.211],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "T",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "T",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
      {
         ch: "G",
         size: 100,
         style: "Regular",
         w: 72.22,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0.439, -0.569],
                                 [0.618, 0],
                                 [1.823, 0.684],
                                 [1.969, 0.391],
                                 [2.409, 0],
                                 [4.85, -2.832],
                                 [3.32, -6.348],
                                 [0, -5.827],
                                 [-4.98, -6.152],
                                 [-12.793, 0],
                                 [-3.646, 0.944],
                                 [-3.744, 2.019],
                                 [0, 0],
                                 [-0.652, 1.205],
                                 [-2.539, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.058, -0.537],
                                 [-0.505, -1.025],
                                 [0, -3.32],
                                 [0, 0],
                                 [2.278, -0.618],
                                 [2.441, 0],
                                 [3.955, 2.425],
                                 [2.441, 5.209],
                                 [0, 6.316],
                                 [-3.679, 5.632],
                                 [-8.431, 0],
                                 [-3.939, -3.809],
                                 [-1.726, -5.208],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [-0.098, 1.66],
                                 [-0.439, 0.57],
                                 [-0.619, 0],
                                 [-3.613, -1.334],
                                 [-1.97, -0.391],
                                 [-6.609, 0],
                                 [-5.795, 3.418],
                                 [-2.8, 5.339],
                                 [0, 8.041],
                                 [6.641, 8.203],
                                 [4.459, 0],
                                 [3.645, -0.944],
                                 [0, 0],
                                 [0, -3.548],
                                 [0.944, -1.692],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.669, 0],
                                 [1.057, 0.537],
                                 [0.504, 1.025],
                                 [0, 0],
                                 [-2.116, 1.205],
                                 [-2.279, 0.619],
                                 [-4.167, 0],
                                 [-3.955, -2.425],
                                 [-2.441, -5.208],
                                 [0, -7.78],
                                 [4.395, -6.673],
                                 [6.184, 0],
                                 [2.734, 2.637],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [59.57, -67.725],
                                 [58.765, -64.38],
                                 [57.178, -63.525],
                                 [53.516, -64.551],
                                 [45.142, -67.139],
                                 [38.574, -67.725],
                                 [21.387, -63.477],
                                 [7.715, -48.828],
                                 [3.516, -32.08],
                                 [10.986, -10.791],
                                 [40.137, 1.514],
                                 [52.295, 0.098],
                                 [63.379, -4.346],
                                 [63.379, -24.609],
                                 [64.355, -31.738],
                                 [69.58, -34.277],
                                 [70.801, -34.277],
                                 [70.801, -36.133],
                                 [45.166, -36.133],
                                 [45.166, -34.277],
                                 [50.757, -33.472],
                                 [53.101, -31.128],
                                 [53.857, -24.609],
                                 [53.857, -5.469],
                                 [47.266, -2.734],
                                 [40.186, -1.807],
                                 [28.003, -5.444],
                                 [18.408, -16.895],
                                 [14.746, -34.18],
                                 [20.264, -54.297],
                                 [39.502, -64.307],
                                 [54.688, -58.594],
                                 [61.377, -46.826],
                                 [63.086, -46.826],
                                 [61.377, -67.725],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "G",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "G",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Intro",
      },
   ],
};

import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";
import img_4 from "./images/img_4.png";
import img_5 from "./images/img_5.png";
import img_6 from "./images/img_6.png";
import img_7 from "./images/img_7.png";
import img_8 from "./images/img_8.png";
import img_9 from "./images/img_9.png";
import img_10 from "./images/img_10.png";

export default {
   v: "5.7.4",
   fr: 30,
   ip: 365,
   op: 600,
   w: 1920,
   h: 1080,
   nm: "Video 1 Main Comp",
   ddd: 0,
   assets: [
      { id: "image_0", w: 2804, h: 1441, u: "", p: img_0, e: 0 },
      { id: "image_1", w: 2015, h: 3000, u: "", p: img_1, e: 0 },
      { id: "image_2", w: 3023, h: 2034, u: "", p: img_2, e: 0 },
      { id: "image_3", w: 110, h: 1935, u: "", p: img_3, e: 0 },
      { id: "image_4", w: 6654, h: 4053, u: "", p: img_4, e: 0 },
      { id: "image_5", w: 1017, h: 636, u: "", p: img_5, e: 0 },
      { id: "image_6", w: 2165, h: 1071, u: "", p: img_6, e: 0 },
      { id: "image_7", w: 3840, h: 1281, u: "", p: img_7, e: 0 },
      { id: "image_8", w: 3840, h: 1281, u: "", p: img_8, e: 0 },
      { id: "image_9", w: 3843, h: 1982, u: "", p: img_9, e: 0 },
      { id: "image_10", w: 3840, h: 2444, u: "", p: img_10, e: 0 },

      {
         id: "comp_0",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 5,
               nm: "Innovation Spike Detected",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.667], y: [0.852] }, o: { x: [0.333], y: [0] }, t: 330, s: [0] },
                        { i: { x: [0.667], y: [0.963] }, o: { x: [0.333], y: [-0.148] }, t: 335, s: [100] },
                        { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [-0.037] }, t: 340, s: [0] },
                        { t: 345, s: [100] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1049, 779, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               t: { d: { k: [{ s: { sz: [1170, 350], ps: [-585, -175], s: 42, f: "LucidaSans-Typewriter", t: "Innovation Spike Detected", j: 2, tr: 0, lh: 50.4, ls: 0, fc: [0.957, 0.361, 0.957] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 360,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 5,
               nm: "... 2",
               sr: 0.5,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 165, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 180, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 195, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 210, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 225, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 240, s: [0] },
                        { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 255, s: [100] },
                        { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 270, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 285, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 300, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 315, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 330, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 345, s: [100] },
                        { t: 360, s: [0] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1026, 640, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               t: { d: { k: [{ s: { s: 42, f: "LucidaSans-Typewriter", t: "...", j: 2, tr: 0, lh: 50.4, ls: 0, fc: [0.957, 0.361, 0.957] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 165,
               op: 361,
               st: 165,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 5,
               nm: "...",
               sr: 0.5,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 15, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 30, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 45, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 60, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 75, s: [0] },
                        { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 90, s: [100] },
                        { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 105, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 120, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 135, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 150, s: [100] },
                        { t: 165, s: [0] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1026, 640, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               t: { d: { k: [{ s: { s: 42, f: "LucidaSans-Typewriter", t: "...", j: 2, tr: 0, lh: 50.4, ls: 0, fc: [0.957, 0.361, 0.957] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 166,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 4,
               ty: 5,
               nm: "Detecting",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [847, 462, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { sz: [1358, 95.9999694824219], ps: [-679, 142.000015258789], s: 42, f: "LucidaSans-Typewriter", t: "Detecting", j: 2, tr: 0, lh: 50.4, ls: 0, fc: [0.957, 0.361, 0.957] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 360,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_1",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 2,
               nm: "mecvector.png",
               cl: "png",
               parent: 3,
               refId: "image_0",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 16, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 75, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 111, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 195, s: [0] },
                        { t: 255, s: [100] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1402, 720.5, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [1402, 720.5, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 25,
                     nm: "Drop Shadow - white glow",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.882352948189, 0.785467147827, 0.785467147827, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 189.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 1",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 127.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 2",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 127.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
               ],
               ip: 0,
               op: 615,
               st: 15,
               bm: 16,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 2,
               nm: "mecvector.png",
               cl: "png",
               parent: 3,
               refId: "image_0",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 16, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 75, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 111, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 195, s: [0] },
                        { t: 255, s: [100] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1402, 720.5, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [1402, 720.5, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 25,
                     nm: "Drop Shadow - white glow",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.882352948189, 0.785467147827, 0.785467147827, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 189.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 1",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 127.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 2",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 127.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
               ],
               ip: 0,
               op: 615,
               st: 15,
               bm: 16,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 2,
               nm: "mecvector.png",
               cl: "png",
               refId: "image_0",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 16, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 75, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 111, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 195, s: [0] },
                        { t: 255, s: [100] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [3324.5, 1886.5, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [1402, 720.5, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [130, 130, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ef: [
                  {
                     ty: 25,
                     nm: "Drop Shadow - white glow",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.882352948189, 0.785467147827, 0.785467147827, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 189.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 1",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 127.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 2",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 127.5, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 135, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 5, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 0, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
               ],
               ip: 0,
               op: 615,
               st: 15,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 5,
               ty: 2,
               nm: "logo (1).png",
               cl: "png",
               refId: "image_1",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0] }, t: 15, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 75, s: [0] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 135, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 195, s: [100] },
                        { t: 230, s: [0] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [3416, 1932, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [1007.5, 1500, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [82, 82, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ip: 0,
               op: 615,
               st: 15,
               bm: 2,
            },
            {
               ddd: 0,
               ind: 6,
               ty: 2,
               nm: "Asset 5 (2)@4x.png",
               cl: "png",
               refId: "image_2",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [3428.5, 2014.5, 0], ix: 2, l: 2 }, a: { a: 0, k: [1511.5, 1017, 0], ix: 1, l: 2 }, s: { a: 0, k: [186, 189.505, 100], ix: 6, l: 2 } },
               ao: 0,
               ip: 0,
               op: 615,
               st: 15,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_2",
         layers: [
            {
               ddd: 0,
               ind: 2,
               ty: 4,
               nm: "Stroke.ai 3",
               cl: "ai",
               sr: 0.69364161849711,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -6, ix: 10 }, p: { a: 0, k: [361.177, -654.895, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [45.434, 139.244, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  {
                     ty: 25,
                     nm: "Drop Shadow - white glow",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.572549045086, 0.466666698456, 0.807843208313, 0.784313797951], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 153, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: -242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 50, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 1",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.007843137719, 0.001445597853, 0.001445597853, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: -242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 20, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 2",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: -242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 90, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 85, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
               ],
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [467.397, -498.094],
                                 ],
                                 o: [
                                    [0, 0],
                                    [-1260.616, 1343.409],
                                 ],
                                 v: [
                                    [2397.381, 1386.682],
                                    [1862.116, 1480.91],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        {
                           ty: "gr",
                           it: [
                              { ind: 0, ty: "sh", ix: 1, ks: { a: 0, k: { i: [[0, 0]], o: [[0, 0]], v: [[972.235, 1409.758]], c: false }, ix: 2 }, nm: "Path 1", mn: "ADBE Vector Shape - Group", hd: false },
                              { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                              { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                           ],
                           nm: "Shape 1",
                           np: 3,
                           cix: 2,
                           bm: 0,
                           ix: 4,
                           mn: "ADBE Vector Group",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 20], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: -0.367, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 4,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 539.999, s: [0] },
                           { t: 570.999819364162, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 360, s: [0] },
                           { t: 502.999909682081, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 3,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 360,
               op: 600,
               st: 360,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 4,
               nm: "Stroke.ai 4",
               cl: "ai",
               sr: 0.69364161849711,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -6, ix: 10 }, p: { a: 0, k: [35.177, 81.105, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [37.593, 92.149, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  {
                     ty: 25,
                     nm: "Drop Shadow - white glow",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.572549045086, 0.466666698456, 0.807843208313, 0.784313797951], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 153, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 50, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 1",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.007843137719, 0.001445597853, 0.001445597853, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 58, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 20, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 2",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 90, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 85, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
               ],
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [-2004.506, -719.115],
                                 ],
                                 o: [
                                    [0, 0],
                                    [1847.894, 662.93],
                                 ],
                                 v: [
                                    [349.73, 2067.605],
                                    [3510.106, 1928.272],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        {
                           ty: "gr",
                           it: [
                              { ind: 0, ty: "sh", ix: 1, ks: { a: 0, k: { i: [[0, 0]], o: [[0, 0]], v: [[375.536, 1446.922]], c: false }, ix: 2 }, nm: "Path 1", mn: "ADBE Vector Shape - Group", hd: false },
                              { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                              { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                           ],
                           nm: "Shape 1",
                           np: 3,
                           cix: 2,
                           bm: 0,
                           ix: 4,
                           mn: "ADBE Vector Group",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [81.229, 95.085], ix: 3 }, r: { a: 0, k: -11.679, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 4,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 542, s: [0] },
                           { t: 573.000316112717, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 407, s: [0] },
                           { t: 536.000225794798, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 3,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 376,
               op: 616,
               st: 376,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 4,
               ty: 4,
               nm: "Stroke.ai 5",
               cl: "ai",
               sr: 0.69364161849711,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -29, ix: 10 }, p: { a: 0, k: [-8.663, 375.105, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [16.422, 50.254, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  {
                     ty: 25,
                     nm: "Drop Shadow - white glow",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.572549045086, 0.466666698456, 0.807843208313, 0.784313797951], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 153, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 50, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 1",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.007843137719, 0.001445597853, 0.001445597853, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 58, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 20, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 2",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 90, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 85, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
               ],
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [-3729.958, -908.077],
                                 ],
                                 o: [
                                    [0, 0],
                                    [2094.39, 509.89],
                                 ],
                                 v: [
                                    [972.235, 1409.758],
                                    [4474.96, 1634.878],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        {
                           ty: "gr",
                           it: [
                              { ind: 0, ty: "sh", ix: 1, ks: { a: 0, k: { i: [[0, 0]], o: [[0, 0]], v: [[972.235, 1409.758]], c: false }, ix: 2 }, nm: "Path 1", mn: "ADBE Vector Shape - Group", hd: false },
                              { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                              { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                           ],
                           nm: "Shape 1",
                           np: 3,
                           cix: 2,
                           bm: 0,
                           ix: 4,
                           mn: "ADBE Vector Group",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 4,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 561, s: [0] },
                           { t: 592.000316112717, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 426, s: [0] },
                           { t: 555.000225794798, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 3,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 395,
               op: 635,
               st: 395,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 5,
               ty: 4,
               nm: "Stroke.ai",
               cl: "ai",
               parent: 16,
               sr: 0.69364161849711,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -20, ix: 10 }, p: { a: 0, k: [7448.988, 3062.869, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [68.426, 209.392, 100], ix: 6, l: 2 } },
               ao: 0,
               ef: [
                  {
                     ty: 25,
                     nm: "Drop Shadow - white glow",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 1,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.572549045086, 0.466666698456, 0.807843208313, 0.784313797951], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 153, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 0, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 50, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 1",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 2,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0.007843137719, 0.001445597853, 0.001445597853, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 58, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 20, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
                  {
                     ty: 25,
                     nm: "Drop Shadow - dark halo 2",
                     np: 8,
                     mn: "ADBE Drop Shadow",
                     ix: 3,
                     en: 1,
                     ef: [
                        { ty: 2, nm: "Shadow Color", mn: "ADBE Drop Shadow-0001", ix: 1, v: { a: 0, k: [0, 0, 0, 1], ix: 1 } },
                        { ty: 0, nm: "Opacity", mn: "ADBE Drop Shadow-0002", ix: 2, v: { a: 0, k: 255, ix: 2 } },
                        { ty: 0, nm: "Direction", mn: "ADBE Drop Shadow-0003", ix: 3, v: { a: 0, k: 242, ix: 3 } },
                        { ty: 0, nm: "Distance", mn: "ADBE Drop Shadow-0004", ix: 4, v: { a: 0, k: 90, ix: 4 } },
                        { ty: 0, nm: "Softness", mn: "ADBE Drop Shadow-0005", ix: 5, v: { a: 0, k: 85, ix: 5 } },
                        { ty: 7, nm: "Shadow Only", mn: "ADBE Drop Shadow-0006", ix: 6, v: { a: 0, k: 0, ix: 6 } },
                     ],
                  },
               ],
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [-3729.958, -908.077],
                                 ],
                                 o: [
                                    [0, 0],
                                    [2094.39, 509.89],
                                 ],
                                 v: [
                                    [972.235, 1409.758],
                                    [4474.96, 1634.878],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        {
                           ty: "gr",
                           it: [
                              { ind: 0, ty: "sh", ix: 1, ks: { a: 0, k: { i: [[0, 0]], o: [[0, 0]], v: [[972.235, 1409.758]], c: false }, ix: 2 }, nm: "Path 1", mn: "ADBE Vector Shape - Group", hd: false },
                              { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                              { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                           ],
                           nm: "Shape 1",
                           np: 3,
                           cix: 2,
                           bm: 0,
                           ix: 4,
                           mn: "ADBE Vector Group",
                           hd: false,
                        },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 4,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 526, s: [0] },
                           { t: 557.000316112717, s: [100] },
                        ],
                        ix: 1,
                     },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 391, s: [0] },
                           { t: 520.000225794798, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 3,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 360,
               op: 600,
               st: 360,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 8,
               ty: 2,
               nm: "Right Extreme.png",
               cl: "png",
               refId: "image_3",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 0, s: [0] },
                        { t: 8, s: [100] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1860, 578.5, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [55, 967.5, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [37, 37, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ip: 0,
               op: 600,
               st: 0,
               bm: 2,
            },
            {
               ddd: 0,
               ind: 9,
               ty: 2,
               nm: "Frame@2x.png",
               cl: "png",
               refId: "image_4",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [933, 600.5, 0], ix: 2, l: 2 }, a: { a: 0, k: [3327, 2026.5, 0], ix: 1, l: 2 }, s: { a: 0, k: [21, 19.091, 100], ix: 6, l: 2 } },
               ao: 0,
               ip: 0,
               op: 600,
               st: 0,
               bm: 2,
            },
            {
               ddd: 0,
               ind: 10,
               ty: 2,
               nm: "Mid@2x.png",
               cl: "png",
               refId: "image_5",
               sr: 1,
               ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [933, 612.5, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [508.5, 318, 0], ix: 1, l: 2 },
                  s: {
                     a: 1,
                     k: [
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 0, s: [17, 17.455, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 60, s: [13, 13.348, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 120, s: [17, 17.455, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 180, s: [13, 13.348, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 240, s: [17, 17.455, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 300, s: [13, 13.348, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 360, s: [17, 17.455, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 420, s: [13, 13.348, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 480, s: [17, 17.455, 100] },
                        { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 540, s: [13, 13.348, 100] },
                        { t: 600, s: [17, 17.455, 100] },
                     ],
                     ix: 6,
                     l: 2,
                  },
               },
               ao: 0,
               ip: 0,
               op: 600,
               st: 0,
               bm: 2,
            },
            {
               ddd: 0,
               ind: 12,
               ty: 2,
               nm: "OGlow .png",
               cl: "png",
               parent: 14,
               refId: "image_6",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 28, s: [0] },
                        { i: { x: [0.633], y: [1] }, o: { x: [0.158], y: [0] }, t: 58, s: [100] },
                        { i: { x: [0.812], y: [1] }, o: { x: [0.438], y: [0] }, t: 259, s: [100] },
                        { t: 342, s: [80] },
                     ],
                     ix: 11,
                     x: "var $bm_rt;\n$bm_rt = loopOut('cycle');",
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1902.004, 1191.701, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [1082.5, 535.5, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ip: 0,
               op: 600,
               st: 0,
               bm: 2,
            },
            {
               ddd: 0,
               ind: 13,
               ty: 2,
               nm: "Patches.png",
               cl: "png",
               parent: 14,
               refId: "image_7",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 5, s: [80] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 9, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 55, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 59, s: [80] },
                        { i: { x: [0.631], y: [1] }, o: { x: [0.158], y: [0] }, t: 63, s: [100] },
                        { i: { x: [0.813], y: [1] }, o: { x: [0.437], y: [0] }, t: 259, s: [100] },
                        { t: 342, s: [70] },
                     ],
                     ix: 11,
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1919.392, 643.976, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [1920, 640.5, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ip: 0,
               op: 600,
               st: 0,
               bm: 10,
            },
            {
               ddd: 0,
               ind: 14,
               ty: 2,
               nm: "Earth.png",
               cl: "png",
               parent: 16,
               refId: "image_8",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [10725.854, 4316.096, 0], ix: 2, l: 2 }, a: { a: 0, k: [1920, 640.5, 0], ix: 1, l: 2 }, s: { a: 0, k: [349.545, 349.545, 100], ix: 6, l: 2 } },
               ao: 0,
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 15,
               ty: 2,
               nm: "OMGlow2.png",
               cl: "png",
               parent: 14,
               refId: "image_9",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [100] },
                        { i: { x: [0.833], y: [1] }, o: { x: [0.167], y: [0] }, t: 39, s: [50] },
                        { t: 75, s: [100] },
                     ],
                     ix: 11,
                     x: "var $bm_rt;\n$bm_rt = loopOut('cycle');",
                  },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [1955.264, 508.488, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [1921.5, 991, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [197, 152, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 16,
               ty: 3,
               nm: "H_Earth Asset 1080p_2-01.png",
               cl: "png",
               sr: 2,
               ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                     a: 1,
                     k: [
                        { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [2276.62, 1827.777, 0], to: [-115.577, 0, 0], ti: [150.256, 0, 0] },
                        { t: 285, s: [487.62, 1827.777, 0] },
                     ],
                     ix: 2,
                     l: 2,
                  },
                  a: { a: 0, k: [8971, 9549, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [24, 24, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ip: 0,
               op: 599,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 17,
               ty: 0,
               nm: "NOBLinkStars Pre-comp ",
               refId: "comp_3",
               sr: 1,
               ks: { o: { a: 0, k: 30, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 620.5, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               w: 1920,
               h: 1080,
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_3",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 2,
               nm: "Stars.png",
               cl: "png",
               refId: "image_10",
               sr: 1,
               ks: {
                  o: {
                     a: 1,
                     k: [
                        { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [75] },
                        { t: 7, s: [100] },
                     ],
                     ix: 11,
                     x: "var $bm_rt;\n$bm_rt = loopOut();",
                  },
                  r: { a: 0, k: 0, ix: 10, x: "var $bm_rt;\n$bm_rt = transform.rotation;" },
                  p: { a: 0, k: [960, 636, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [1920, 1222, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [50, 50, 100], ix: 6, l: 2 },
               },
               ao: 0,
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_4",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 5,
               nm: "Vibe-o-meter 2",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [505.091, 280, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { sz: [1170, 350], ps: [-585, -175], s: 115, f: "LucidaSans-Typewriter", t: "Vibe-o-meter", j: 2, tr: 0, lh: 138, ls: 0, fc: [0.8, 0.282, 0.914] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 4,
               nm: "circle 4",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [763.705, 802.568, 0], ix: 2, l: 2 }, a: { a: 0, k: [-150, 300, 0], ix: 1, l: 2 }, s: { a: 0, k: [71.33, 71.33, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        { d: 2, ty: "el", s: { a: 0, k: [462, 462], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: "Ellipse Path 1", mn: "ADBE Vector Shape - Ellipse", hd: false },
                        {
                           ty: "tm",
                           s: { a: 0, k: 0, ix: 1 },
                           e: {
                              a: 1,
                              k: [
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [40] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [35] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [15] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 274, s: [35] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [67] },
                                 { t: 365, s: [87] },
                              ],
                              ix: 2,
                           },
                           o: { a: 0, k: 0, ix: 3 },
                           m: 1,
                           ix: 2,
                           nm: "Trim Paths 1",
                           mn: "ADBE Vector Filter - Trim",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.960784373564, 0.360784313725, 0.952941236309, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 45, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "st", c: { a: 0, k: [0.960784373564, 0.360784313725, 0.952941236309, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 45, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 2", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "tr", p: { a: 0, k: [-159, 297], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Ellipse 1",
                     np: 5,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 4,
               nm: "circle 3",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [763.705, 802.568, 0], ix: 2, l: 2 }, a: { a: 0, k: [-150, 300, 0], ix: 1, l: 2 }, s: { a: 0, k: [105.564, 105.564, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        { d: 2, ty: "el", s: { a: 0, k: [462, 462], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: "Ellipse Path 1", mn: "ADBE Vector Shape - Ellipse", hd: false },
                        {
                           ty: "tm",
                           s: { a: 0, k: 0, ix: 1 },
                           e: {
                              a: 1,
                              k: [
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [12] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [32] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [31] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [39] },
                                 { t: 365, s: [89] },
                              ],
                              ix: 2,
                           },
                           o: { a: 0, k: 0, ix: 3 },
                           m: 1,
                           ix: 2,
                           nm: "Trim Paths 1",
                           mn: "ADBE Vector Filter - Trim",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.470588265213, 0.109803929048, 0.819607902976, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 45, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "st", c: { a: 0, k: [0.470588265213, 0.109803929048, 0.819607902976, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 45, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 2", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "tr", p: { a: 0, k: [-159, 297], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Ellipse 1",
                     np: 5,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 4,
               ty: 4,
               nm: "circle 2",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [763.705, 802.568, 0], ix: 2, l: 2 }, a: { a: 0, k: [-150, 300, 0], ix: 1, l: 2 }, s: { a: 0, k: [143.633, 143.633, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        { d: 2, ty: "el", s: { a: 0, k: [462, 462], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: "Ellipse Path 1", mn: "ADBE Vector Shape - Ellipse", hd: false },
                        {
                           ty: "tm",
                           s: { a: 0, k: 0, ix: 1 },
                           e: {
                              a: 1,
                              k: [
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [25] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 95, s: [23] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 196, s: [20] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 274, s: [23] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [46] },
                                 { t: 365, s: [87] },
                              ],
                              ix: 2,
                           },
                           o: { a: 0, k: 0, ix: 3 },
                           m: 1,
                           ix: 2,
                           nm: "Trim Paths 1",
                           mn: "ADBE Vector Filter - Trim",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.800000059838, 0.282352941176, 0.913725550034, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 45, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "st", c: { a: 0, k: [0.800000059838, 0.282352941176, 0.913725550034, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 45, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 2", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "tr", p: { a: 0, k: [-159, 297], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Ellipse 1",
                     np: 5,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 5,
               ty: 4,
               nm: "circle 1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [763.705, 802.568, 0], ix: 2, l: 2 }, a: { a: 0, k: [-150, 300, 0], ix: 1, l: 2 }, s: { a: 0, k: [186.461, 186.461, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        { d: 2, ty: "el", s: { a: 0, k: [462, 462], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: "Ellipse Path 1", mn: "ADBE Vector Shape - Ellipse", hd: false },
                        {
                           ty: "tm",
                           s: { a: 0, k: 0, ix: 1 },
                           e: {
                              a: 1,
                              k: [
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [18] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 95, s: [7] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 196, s: [17] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 274, s: [30] },
                                 { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [63] },
                                 { t: 365, s: [92] },
                              ],
                              ix: 2,
                           },
                           o: { a: 0, k: 0, ix: 3 },
                           m: 1,
                           ix: 2,
                           nm: "Trim Paths 1",
                           mn: "ADBE Vector Filter - Trim",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.800000071526, 0.282352954149, 0.913725554943, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 43, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "tr", p: { a: 0, k: [-159, 297], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Ellipse 1",
                     np: 4,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_5",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 5,
               nm: "Excitement Density 2",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [717.091, 766, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { sz: [1342, 350], ps: [-585, -175], s: 115, f: "LucidaSans-Typewriter", t: "Excitement Density", j: 0, tr: 0, lh: 138, ls: 0, fc: [0.8, 0.282, 0.914] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 4,
               nm: "Shape Layer 1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [774, 1102, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [86.297, 95.215, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-730, 316],
                                    [686, 316],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 7, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 5,
               nm: "50%",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [80.594, 1189, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { sz: [102, 62], ps: [-51, -31], s: 30, f: "Poppins-Bold", t: "50%", j: 2, tr: -35, lh: 36, ls: 0, fc: [1, 1, 1], sc: [0, 0, 0], sw: 0.00999999977648, of: false }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 4,
               ty: 5,
               nm: "100%",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [81.406, 973, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { sz: [102, 62], ps: [-51, -31], s: 30, f: "Poppins-Bold", t: "100%", j: 2, tr: -35, lh: 36, ls: 0, fc: [1, 1, 1], sc: [0, 0, 0], sw: 0.00999999977648, of: false }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 5,
               ty: 4,
               nm: "guides",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [825, 1062, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [99.714, 93.215, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-704, -322],
                                    [-704, 364],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 7, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [24, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 8,
               ty: 4,
               nm: "bar 5",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [1299, 1154, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.960784373564, 0.360784313725, 0.952941236309, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [12] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [42] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [40] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [77] },
                           { t: 365, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
                  {
                     ty: "gr",
                     it: [
                        { ty: "tm", s: { a: 0, k: 0, ix: 1 }, e: { a: 0, k: 100, ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 1, nm: "Trim Paths 1", mn: "ADBE Vector Filter - Trim", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Group 1",
                     np: 1,
                     cix: 2,
                     bm: 0,
                     ix: 3,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 9,
               ty: 4,
               nm: "bar 4",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [1187.75, 1154, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.470588265213, 0.109803929048, 0.819607902976, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [16] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [60] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [25] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [47] },
                           { t: 365, s: [89] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 10,
               ty: 4,
               nm: "bar 3",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [1076.5, 1154, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.960784373564, 0.360784313725, 0.952941236309, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [18] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [55] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [58] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [74] },
                           { t: 365, s: [96] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 11,
               ty: 4,
               nm: "bar 2",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [965.25, 1154, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.470588265213, 0.109803929048, 0.819607902976, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [12] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [25] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [20] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [20] },
                           { t: 365, s: [89] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 12,
               ty: 4,
               nm: "bar 1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [854, 1154, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.960784373564, 0.360784313725, 0.952941236309, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [22] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [18] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [46] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [70] },
                           { t: 365, s: [92] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
         ],
      },
      {
         id: "comp_6",
         layers: [
            {
               ddd: 0,
               ind: 1,
               ty: 5,
               nm: "Happiness Index",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [697.091, 466, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { sz: [1342, 350], ps: [-585, -175], s: 115, f: "LucidaSans-Typewriter", t: "Happiness Index", j: 0, tr: 0, lh: 138, ls: 0, fc: [0.8, 0.282, 0.914] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 2,
               ty: 5,
               nm: "50%",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [407, 1155, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { sz: [102, 62], ps: [-51, -31], s: 30, f: "Poppins-Bold", t: "50%", j: 2, tr: -35, lh: 36, ls: 0, fc: [1, 1, 1], sc: [0, 0, 0], sw: 0.00999999977648, of: false }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 3,
               ty: 5,
               nm: "100%",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [649, 1155, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               t: { d: { k: [{ s: { sz: [102, 62], ps: [-51, -31], s: 30, f: "Poppins-Bold", t: "100%", j: 2, tr: -35, lh: 36, ls: 0, fc: [1, 1, 1], sc: [0, 0, 0], sw: 0.00999999977648, of: false }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 4,
               ty: 4,
               nm: "Shape Layer 1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [752, 790, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [86.297, 95.215, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-730, 316],
                                    [686, 316],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 7, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 5,
               ty: 4,
               nm: "guides",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [803, 750, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [99.714, 93.215, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-704, -322],
                                    [-704, 364],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 7, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [24, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 8,
               ty: 4,
               nm: "bar 5",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [372, 1532, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.800000059838, 0.282352941176, 0.913725550034, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [12] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [25] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [42] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 262, s: [58.688] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [65] },
                           { t: 365, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 1",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 9,
               ty: 4,
               nm: "bar 4",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [372, 1421.5, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.47058826685, 0.109803929925, 0.819607913494, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [6] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [31] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [29] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 258, s: [73.112] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [75] },
                           { t: 365, s: [94] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 2",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 10,
               ty: 4,
               nm: "bar 3",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [372, 1311, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.800000059838, 0.282352941176, 0.913725550034, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [10] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [9] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [41] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 258, s: [57.985] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [41] },
                           { t: 365, s: [89] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 2",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 11,
               ty: 4,
               nm: "bar 2",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [372, 1200.5, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.47058826685, 0.109803929925, 0.819607913494, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [22] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [20] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [27] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 254, s: [42.62] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [66] },
                           { t: 365, s: [100] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 2",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
            {
               ddd: 0,
               ind: 12,
               ty: 4,
               nm: "bar 1",
               sr: 1,
               ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [369.618, 1090, 0], ix: 2, l: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
               ao: 0,
               shapes: [
                  {
                     ty: "gr",
                     it: [
                        {
                           ind: 0,
                           ty: "sh",
                           ix: 1,
                           ks: {
                              a: 0,
                              k: {
                                 i: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 o: [
                                    [0, 0],
                                    [0, 0],
                                 ],
                                 v: [
                                    [-526, 244],
                                    [-526, -318],
                                 ],
                                 c: false,
                              },
                              ix: 2,
                           },
                           nm: "Path 1",
                           mn: "ADBE Vector Shape - Group",
                           hd: false,
                        },
                        { ty: "st", c: { a: 0, k: [0.800000059838, 0.282352941176, 0.913725550034, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 69, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: "Stroke 1", mn: "ADBE Vector Graphic - Stroke", hd: false },
                        { ty: "fl", c: { a: 0, k: [0.054901998183, 0.403921987496, 0.937255021638, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: "Fill 1", mn: "ADBE Vector Graphic - Fill", hd: false },
                        { ty: "tr", p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: "Transform" },
                     ],
                     nm: "Shape 1",
                     np: 3,
                     cix: 2,
                     bm: 0,
                     ix: 1,
                     mn: "ADBE Vector Group",
                     hd: false,
                  },
                  {
                     ty: "tm",
                     s: { a: 0, k: 0, ix: 1 },
                     e: {
                        a: 1,
                        k: [
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [17] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 72, s: [16] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 155, s: [58] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 258, s: [78.385] },
                           { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 360, s: [74] },
                           { t: 365, s: [97] },
                        ],
                        ix: 2,
                     },
                     o: { a: 0, k: 0, ix: 3 },
                     m: 1,
                     ix: 2,
                     nm: "Trim Paths 2",
                     mn: "ADBE Vector Filter - Trim",
                     hd: false,
                  },
               ],
               ip: 0,
               op: 600,
               st: 0,
               bm: 0,
            },
         ],
      },
   ],
   fonts: {
      list: [
         { fName: "Poppins-Bold", fFamily: "Poppins", fStyle: "Bold", ascent: 73.9990234375 },
         { fName: "LucidaSans-Typewriter", fFamily: "Lucida Sans Typewriter", fStyle: "Regular", ascent: 77.099609375 },
      ],
   },
   layers: [
      {
         ddd: 0,
         ind: 2,
         ty: 5,
         nm: "Join in on the thrill of Excel February 19th onwards.",
         sr: 1,
         ks: {
            o: {
               a: 1,
               k: [
                  { i: { x: [0.724], y: [1] }, o: { x: [0.387], y: [0] }, t: 345, s: [0] },
                  { t: 360, s: [100] },
               ],
               ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [961.74, 81.176, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
         },
         ao: 0,
         t: { d: { k: [{ s: { s: 28, f: "LucidaSans-Typewriter", t: "Join in on the thrill of Excel\rFebruary 19th onwards.", j: 2, tr: 0, lh: 38, ls: 0, fc: [1, 1, 1] }, t: 0 }] }, p: {}, m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } }, a: [] },
         ip: 345,
         op: 600,
         st: 0,
         bm: 0,
      },
      {
         ddd: 0,
         ind: 3,
         ty: 0,
         nm: "Text Pre Comp",
         refId: "comp_0",
         sr: 1,
         ks: {
            o: {
               a: 1,
               k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  { t: 7, s: [100] },
               ],
               ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [674, 252, 0], ix: 2, l: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [62, 62, 100], ix: 6, l: 2 },
         },
         ao: 0,
         w: 1920,
         h: 1080,
         ip: 0,
         op: 600,
         st: 0,
         bm: 0,
      },
      {
         ddd: 0,
         ind: 4,
         ty: 0,
         nm: "HUDP",
         refId: "comp_1",
         sr: 1,
         ks: {
            o: {
               a: 1,
               k: [
                  { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  { t: 15, s: [100] },
               ],
               ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
               a: 1,
               k: [
                  { i: { x: 0.833, y: 1 }, o: { x: 0.167, y: 0 }, t: 345, s: [1239.233, 856.715, 0], to: [-51.705, -45.308, 0], ti: [51.705, 45.308, 0] },
                  { t: 360, s: [929, 584.865, 0] },
               ],
               ix: 2,
               l: 2,
            },
            a: { a: 0, k: [3428.5, 1937.5, 0], ix: 1, l: 2 },
            s: {
               a: 1,
               k: [
                  { i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 345, s: [0, 0, 100] },
                  { t: 360, s: [10, 10, 100] },
               ],
               ix: 6,
               l: 2,
            },
         },
         ao: 0,
         w: 6857,
         h: 3875,
         ip: 345,
         op: 600,
         st: 345,
         bm: 0,
      },
      {
         ddd: 0,
         ind: 5,
         ty: 0,
         nm: "Primary Comp",
         refId: "comp_2",
         sr: 1,
         ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 712, 0], ix: 2, l: 2 }, a: { a: 0, k: [960, 740.5, 0], ix: 1, l: 2 }, s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 } },
         ao: 0,
         w: 1920,
         h: 1481,
         ip: 0,
         op: 600,
         st: 0,
         bm: 2,
      },
      {
         ddd: 0,
         ind: 9,
         ty: 0,
         nm: "Graph circle 3",
         refId: "comp_4",
         sr: 1,
         ks: {
            o: {
               a: 1,
               k: [
                  { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  { t: 15, s: [100] },
               ],
               ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1709, 830, 0], ix: 2, l: 2 },
            a: { a: 0, k: [750, 750, 0], ix: 1, l: 2 },
            s: { a: 0, k: [17, 17, 100], ix: 6, l: 2 },
         },
         ao: 0,
         w: 1500,
         h: 1500,
         ip: 0,
         op: 600,
         st: 0,
         bm: 0,
      },
      {
         ddd: 0,
         ind: 10,
         ty: 0,
         nm: "Graph bar 2",
         refId: "comp_5",
         sr: 1,
         ks: {
            o: {
               a: 1,
               k: [
                  { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  { t: 15, s: [100] },
               ],
               ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1709, 222, 0], ix: 2, l: 2 },
            a: { a: 0, k: [750, 750, 0], ix: 1, l: 2 },
            s: { a: 0, k: [17, 17, 100], ix: 6, l: 2 },
         },
         ao: 0,
         w: 1500,
         h: 1500,
         ip: 0,
         op: 600,
         st: 0,
         bm: 0,
      },
      {
         ddd: 0,
         ind: 11,
         ty: 0,
         nm: "Graph bar 1",
         refId: "comp_6",
         sr: 1,
         ks: {
            o: {
               a: 1,
               k: [
                  { i: { x: [0.833], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  { t: 15, s: [100] },
               ],
               ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1709, 546, 0], ix: 2, l: 2 },
            a: { a: 0, k: [750, 750, 0], ix: 1, l: 2 },
            s: { a: 0, k: [17, 17, 100], ix: 6, l: 2 },
         },
         ao: 0,
         w: 1500,
         h: 1500,
         ip: 0,
         op: 600,
         st: 0,
         bm: 0,
      },
   ],
   markers: [],
   chars: [
      {
         ch: "1",
         size: 30,
         style: "Bold",
         w: 37.6,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [12.689, 0],
                                 [30.615, 0],
                                 [30.615, -73.517],
                                 [2.719, -73.517],
                                 [2.719, -57.504],
                                 [12.689, -57.504],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Poppins",
      },
      {
         ch: "0",
         size: 30,
         style: "Bold",
         w: 65.2,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, -21.552],
                                 [-20.947, 0],
                                 [0, 21.753],
                                 [20.947, 0],
                              ],
                              o: [
                                 [0, 21.753],
                                 [20.947, 0],
                                 [0, -21.552],
                                 [-20.947, 0],
                              ],
                              v: [
                                 [4.532, -37.766],
                                 [32.831, -0.302],
                                 [61.13, -37.766],
                                 [32.831, -75.027],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "0",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, -11.279],
                                 [10.172, 0],
                                 [0, 11.682],
                                 [-10.172, 0],
                              ],
                              o: [
                                 [0, 11.682],
                                 [-10.172, 0],
                                 [0, -11.279],
                                 [10.172, 0],
                              ],
                              v: [
                                 [44.211, -37.766],
                                 [32.831, -16.415],
                                 [21.451, -37.766],
                                 [32.831, -58.914],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "0",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "0",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Poppins",
      },
      {
         ch: "%",
         size: 30,
         style: "Bold",
         w: 87,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, -11.179],
                                 [-9.567, 0],
                                 [0, 11.179],
                                 [9.567, 0],
                              ],
                              o: [
                                 [0, 11.179],
                                 [9.567, 0],
                                 [0, -11.179],
                                 [-9.567, 0],
                              ],
                              v: [
                                 [3.122, -54.987],
                                 [19.94, -37.967],
                                 [36.658, -54.987],
                                 [19.94, -72.006],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "%",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-3.021, 0],
                                 [0, -4.834],
                                 [3.021, 0],
                                 [0, 4.733],
                              ],
                              o: [
                                 [3.021, 0],
                                 [0, 4.733],
                                 [-3.021, 0],
                                 [0, -4.834],
                              ],
                              v: [
                                 [19.839, -61.935],
                                 [24.774, -54.987],
                                 [19.839, -48.138],
                                 [15.005, -54.987],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "%",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 2,
                        ty: "sh",
                        ix: 3,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [16.113, 0],
                                 [32.73, 0],
                                 [71.503, -70.898],
                                 [54.886, -70.898],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "%",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 3,
                        ty: "sh",
                        ix: 4,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, -11.279],
                                 [-9.567, 0],
                                 [0, 11.179],
                                 [9.567, 0],
                              ],
                              o: [
                                 [0, 11.179],
                                 [9.567, 0],
                                 [0, -11.279],
                                 [-9.567, 0],
                              ],
                              v: [
                                 [51.059, -15.912],
                                 [67.776, 1.108],
                                 [84.494, -15.912],
                                 [67.776, -32.932],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "%",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 4,
                        ty: "sh",
                        ix: 5,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-3.021, 0],
                                 [0, -4.733],
                                 [3.021, 0],
                                 [0, 4.733],
                              ],
                              o: [
                                 [3.021, 0],
                                 [0, 4.733],
                                 [-3.021, 0],
                                 [0, -4.733],
                              ],
                              v: [
                                 [67.676, -22.861],
                                 [72.61, -15.912],
                                 [67.676, -9.064],
                                 [62.842, -15.912],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "%",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "%",
                  np: 8,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Poppins",
      },
      {
         ch: "5",
         size: 30,
         style: "Bold",
         w: 65,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-4.834, 0],
                                 [0, -6.647],
                                 [6.546, 0],
                                 [0.705, 3.827],
                                 [0, 0],
                                 [-16.516, 0],
                                 [0, 15.509],
                                 [16.013, 0],
                                 [2.618, -2.921],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0.906, -3.424],
                                 [6.143, 0],
                                 [0, 6.345],
                                 [-5.64, 0],
                                 [0, 0],
                                 [0.604, 12.689],
                                 [16.315, 0],
                                 [0, -11.884],
                                 [-5.841, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [9.265, -73.618],
                                 [9.265, -29.81],
                                 [25.882, -29.81],
                                 [34.442, -35.852],
                                 [43.909, -25.681],
                                 [34.543, -14.603],
                                 [25.278, -21.854],
                                 [8.459, -21.854],
                                 [35.147, 0],
                                 [60.526, -25.58],
                                 [38.37, -49.649],
                                 [24.875, -44.614],
                                 [24.875, -58.511],
                                 [56.296, -58.511],
                                 [56.296, -73.618],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "5",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "5",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Poppins",
      },
      {
         ch: "H",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [16.895, 0],
                                 [16.895, -34.326],
                                 [43.359, -34.326],
                                 [43.359, 0],
                                 [53.027, 0],
                                 [53.027, -72.266],
                                 [43.359, -72.266],
                                 [43.359, -41.553],
                                 [16.895, -41.553],
                                 [16.895, -72.266],
                                 [7.227, -72.266],
                                 [7.227, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "H",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "H",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "a",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-1.693, -0.862],
                                 [-1.953, 0],
                                 [-3.191, 1.041],
                                 [0, 0],
                                 [0.618, 0],
                                 [0.651, 0.31],
                                 [0.391, 0.554],
                                 [0.098, 0.912],
                                 [0, 1.825],
                                 [0, 0],
                                 [0.471, 2.069],
                                 [1.562, 1.694],
                                 [6.998, 0],
                                 [2.783, -0.472],
                                 [4.199, -1.499],
                                 [0, 0],
                                 [-2.767, 0.587],
                                 [-2.312, 0],
                                 [-1.384, -0.327],
                                 [-1.107, -0.947],
                                 [-0.375, -1.208],
                                 [0, -2.122],
                                 [0, 0],
                                 [0, 0],
                                 [4.801, -3.434],
                                 [0, -5.338],
                                 [-3.223, -2.718],
                                 [-4.688, 0],
                                 [-2.8, 1.074],
                                 [-3.255, 2.734],
                              ],
                              o: [
                                 [1.692, 0.862],
                                 [1.692, 0],
                                 [0, 0],
                                 [-1.4, 0.326],
                                 [-0.847, 0],
                                 [-0.652, -0.31],
                                 [-0.521, -0.684],
                                 [-0.098, -0.912],
                                 [0, 0],
                                 [0, -3.03],
                                 [-0.472, -2.069],
                                 [-2.8, -3.03],
                                 [-3.06, 0],
                                 [-2.783, 0.473],
                                 [0, 0],
                                 [3.678, -1.959],
                                 [2.766, -0.587],
                                 [1.595, 0],
                                 [1.383, 0.327],
                                 [0.944, 0.816],
                                 [0.374, 1.208],
                                 [0, 0],
                                 [0, 0],
                                 [-8.171, 0],
                                 [-4.802, 3.435],
                                 [0, 4.33],
                                 [3.223, 2.718],
                                 [2.766, 0],
                                 [2.799, -1.074],
                                 [1.074, 3.158],
                              ],
                              v: [
                                 [44.922, -0.366],
                                 [50.391, 0.928],
                                 [57.715, -0.635],
                                 [56.836, -6.396],
                                 [53.809, -5.908],
                                 [51.562, -6.372],
                                 [50, -7.668],
                                 [49.072, -10.062],
                                 [48.926, -14.169],
                                 [48.926, -36.407],
                                 [48.218, -44.057],
                                 [45.166, -49.702],
                                 [30.469, -54.248],
                                 [21.704, -53.539],
                                 [11.23, -50.581],
                                 [11.23, -42.319],
                                 [20.898, -46.14],
                                 [28.516, -47.021],
                                 [32.983, -46.532],
                                 [36.719, -44.621],
                                 [38.696, -41.584],
                                 [39.258, -36.587],
                                 [39.258, -32.031],
                                 [33.301, -32.031],
                                 [13.843, -26.88],
                                 [6.641, -13.721],
                                 [11.475, -3.149],
                                 [23.34, 0.928],
                                 [31.689, -0.684],
                                 [40.771, -6.396],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "a",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [2.229, -0.977],
                                 [2.116, 0],
                                 [1.546, 1.693],
                                 [0, 2.279],
                                 [-3.174, 1.937],
                                 [-6.836, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [-2.23, 0.977],
                                 [-3.093, 0],
                                 [-1.546, -1.692],
                                 [0, -3.288],
                                 [3.174, -1.936],
                                 [0, 0],
                                 [0, 0],
                                 [-2.344, 1.953],
                              ],
                              v: [
                                 [32.397, -8.252],
                                 [25.879, -6.787],
                                 [18.921, -9.326],
                                 [16.602, -15.283],
                                 [21.362, -23.12],
                                 [36.377, -26.025],
                                 [39.258, -26.025],
                                 [39.258, -12.646],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "a",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "a",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "p",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.374, -0.96],
                                 [-2.503, 0],
                                 [-2.828, 1.433],
                                 [-2.048, 2.737],
                                 [0, 8.765],
                                 [3.445, 4.724],
                                 [6.047, 0],
                                 [2.877, -1.855],
                                 [2.341, -3.711],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.406, 2.898],
                                 [2.373, 0.96],
                                 [3.153, 0],
                                 [2.828, -1.434],
                                 [3.966, -5.31],
                                 [0, -7.852],
                                 [-3.446, -4.724],
                                 [-3.446, 0],
                                 [-2.877, 1.855],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [18.359, 19.287],
                                 [18.359, 1.221],
                                 [17.773, -6.006],
                                 [18.359, -6.006],
                                 [25.527, -0.22],
                                 [32.841, 1.221],
                                 [41.814, -0.929],
                                 [49.129, -7.185],
                                 [55.078, -28.297],
                                 [49.909, -47.162],
                                 [35.67, -54.248],
                                 [26.185, -51.465],
                                 [18.359, -43.115],
                                 [17.773, -43.115],
                                 [18.359, -53.027],
                                 [8.691, -53.027],
                                 [8.691, 19.287],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "p",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [-2.73, 1.598],
                                 [-2.209, 0],
                                 [-2.112, -2.835],
                                 [0, -6.844],
                                 [2.664, -3.226],
                                 [3.542, 0],
                                 [2.193, 1.206],
                                 [2.242, 2.412],
                              ],
                              o: [
                                 [2.534, -3.584],
                                 [2.729, -1.597],
                                 [3.217, 0],
                                 [2.112, 2.836],
                                 [0, 7.725],
                                 [-2.664, 3.226],
                                 [-2.145, 0],
                                 [-2.193, -1.205],
                                 [0, 0],
                              ],
                              v: [
                                 [18.359, -35.876],
                                 [26.255, -43.649],
                                 [33.663, -46.045],
                                 [41.656, -41.792],
                                 [44.824, -27.272],
                                 [40.827, -10.845],
                                 [31.519, -6.006],
                                 [25.012, -7.815],
                                 [18.359, -13.242],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "p",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "p",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "i",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [37.354, 0],
                                 [37.354, -53.027],
                                 [8.447, -53.027],
                                 [8.447, -45.801],
                                 [27.686, -45.801],
                                 [27.686, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "i",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [38.525, -65.039],
                                 [38.525, -77.1],
                                 [26.465, -77.1],
                                 [26.465, -65.039],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "i",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "i",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "n",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [-2.404, 1.711],
                                 [-2.599, 0],
                                 [-1.202, -0.505],
                                 [-0.748, -1.108],
                                 [-0.179, -1.238],
                                 [0, -2.02],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0.439, 1.971],
                                 [1.365, 1.662],
                                 [5.038, 0],
                                 [3.169, -1.92],
                                 [2.145, -3.58],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [2.663, -3.519],
                                 [2.403, -1.711],
                                 [1.396, 0],
                                 [1.202, 0.505],
                                 [0.682, 1.01],
                                 [0.179, 1.238],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, -2.965],
                                 [-0.439, -1.971],
                                 [-2.763, -3.388],
                                 [-3.543, 0],
                                 [-3.169, 1.921],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [18.555, 0],
                                 [18.555, -34.705],
                                 [26.156, -42.551],
                                 [33.66, -45.117],
                                 [37.558, -44.36],
                                 [40.482, -41.94],
                                 [41.772, -38.567],
                                 [42.041, -33.679],
                                 [42.041, 0],
                                 [51.66, 0],
                                 [51.66, -36.312],
                                 [51.002, -43.716],
                                 [48.296, -49.165],
                                 [36.594, -54.248],
                                 [26.526, -51.367],
                                 [18.554, -43.115],
                                 [17.969, -43.115],
                                 [18.555, -53.027],
                                 [8.887, -53.027],
                                 [8.887, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "n",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "n",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "e",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [5.176, 0],
                                 [3.711, 2.605],
                                 [0.391, 6.804],
                                 [0, 0],
                                 [0, 0],
                                 [3.906, 3.896],
                                 [6.413, 0],
                                 [4.573, -5.539],
                                 [0, -7.787],
                                 [-4.476, -5.132],
                                 [-9.961, 0],
                                 [-2.393, 0.342],
                                 [-2.474, 0.716],
                                 [-1.693, 0.554],
                              ],
                              o: [
                                 [-6.185, 2.474],
                                 [-5.144, 0],
                                 [-3.711, -2.604],
                                 [0, 0],
                                 [0, 0],
                                 [0, -10.303],
                                 [-3.906, -3.896],
                                 [-7.552, 0],
                                 [-4.574, 5.539],
                                 [0, 7.852],
                                 [4.475, 5.132],
                                 [2.441, 0],
                                 [2.393, -0.342],
                                 [0.846, -0.26],
                                 [0, 0],
                              ],
                              v: [
                                 [53.613, -9.717],
                                 [36.572, -6.006],
                                 [23.291, -9.912],
                                 [17.139, -24.023],
                                 [53.271, -24.023],
                                 [53.271, -27.105],
                                 [47.412, -48.403],
                                 [31.934, -54.248],
                                 [13.745, -45.94],
                                 [6.885, -25.951],
                                 [13.599, -6.477],
                                 [35.254, 1.221],
                                 [42.505, 0.708],
                                 [49.805, -0.879],
                                 [53.613, -2.1],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "e",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-2.474, 2.727],
                                 [-3.679, 0],
                                 [-2.263, -2.22],
                                 [0, -5.159],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [2.473, -2.727],
                                 [3.45, 0],
                                 [2.262, 2.22],
                                 [0, 0],
                                 [0, 0],
                                 [0.455, -5.061],
                              ],
                              v: [
                                 [21.826, -42.931],
                                 [31.055, -47.021],
                                 [39.624, -43.69],
                                 [43.018, -32.622],
                                 [43.018, -31.25],
                                 [17.432, -31.25],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "e",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "e",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "s",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-6.087, 0],
                                 [-3.857, 3.226],
                                 [0, 4.529],
                                 [1.692, 2.281],
                                 [4.883, 1.694],
                                 [0, 0],
                                 [0.944, 1.011],
                                 [0, 1.337],
                                 [-2.019, 1.337],
                                 [-3.679, 0],
                                 [-6.348, -1.923],
                                 [0, 0],
                                 [2.311, 0.195],
                                 [2.083, 0],
                                 [3.434, -2.817],
                                 [0, -4.462],
                                 [-2.182, -2.28],
                                 [-3.68, -1.172],
                                 [0, 0],
                                 [-1.138, -1.143],
                                 [0, -1.502],
                                 [2.311, -1.764],
                                 [4.395, 0],
                                 [7.454, 3.233],
                                 [0, 0],
                              ],
                              o: [
                                 [7.52, 0],
                                 [3.857, -3.226],
                                 [0, -3.128],
                                 [-1.726, -2.248],
                                 [0, 0],
                                 [-2.865, -0.977],
                                 [-0.977, -0.977],
                                 [0, -2.02],
                                 [2.018, -1.336],
                                 [5.241, 0],
                                 [0, 0],
                                 [-4.851, -1.14],
                                 [-2.312, -0.195],
                                 [-7.031, 0],
                                 [-3.435, 2.818],
                                 [0, 3.42],
                                 [2.182, 2.313],
                                 [0, 0],
                                 [3.836, 1.241],
                                 [1.105, 1.176],
                                 [0, 2.058],
                                 [-2.312, 1.764],
                                 [-5.437, 0],
                                 [0, 0],
                                 [6.934, 2.249],
                              ],
                              v: [
                                 [28.662, 1.221],
                                 [45.728, -3.617],
                                 [51.514, -15.249],
                                 [48.975, -23.362],
                                 [39.062, -29.276],
                                 [26.904, -33.529],
                                 [21.191, -36.511],
                                 [19.727, -39.982],
                                 [22.754, -45.017],
                                 [31.299, -47.021],
                                 [48.682, -44.138],
                                 [48.682, -51.952],
                                 [37.939, -53.955],
                                 [31.348, -54.248],
                                 [15.649, -50.022],
                                 [10.498, -39.103],
                                 [13.77, -30.553],
                                 [22.562, -25.326],
                                 [32.874, -21.977],
                                 [40.334, -18.401],
                                 [41.992, -14.384],
                                 [38.525, -8.651],
                                 [28.467, -6.006],
                                 [9.131, -10.856],
                                 [9.131, -2.153],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "s",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "s",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      { ch: " ", size: 115, style: "Regular", w: 60.25, data: {}, fFamily: "Lucida Sans Typewriter" },
      {
         ch: "I",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [51.807, 0],
                                 [51.807, -7.227],
                                 [34.961, -7.227],
                                 [34.961, -65.039],
                                 [51.807, -65.039],
                                 [51.807, -72.266],
                                 [8.447, -72.266],
                                 [8.447, -65.039],
                                 [25.293, -65.039],
                                 [25.293, -7.227],
                                 [8.447, -7.227],
                                 [8.447, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "I",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "I",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "d",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.357, 0.928],
                                 [2.502, 0],
                                 [2.828, -1.418],
                                 [2.048, -2.737],
                                 [0, -8.731],
                                 [-3.446, -4.724],
                                 [-6.015, 0],
                                 [-2.893, 1.863],
                                 [-2.341, 3.695],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.438, -2.962],
                                 [-2.357, -0.928],
                                 [-3.154, 0],
                                 [-2.828, 1.418],
                                 [-3.967, 5.344],
                                 [0, 7.885],
                                 [3.445, 4.724],
                                 [3.445, 0],
                                 [2.893, -1.863],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [52.588, 0],
                                 [52.588, -77.1],
                                 [42.92, -77.1],
                                 [42.92, -54.237],
                                 [43.506, -47.021],
                                 [42.921, -47.021],
                                 [35.728, -52.856],
                                 [28.438, -54.248],
                                 [19.465, -52.122],
                                 [12.151, -45.891],
                                 [6.201, -24.779],
                                 [11.37, -5.865],
                                 [25.561, 1.221],
                                 [35.069, -1.575],
                                 [42.921, -9.912],
                                 [43.506, -9.912],
                                 [42.92, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "d",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [2.729, -1.613],
                                 [2.209, 0],
                                 [2.112, 2.836],
                                 [0, 6.812],
                                 [-2.681, 3.194],
                                 [-3.51, 0],
                                 [-2.193, -1.205],
                                 [-2.242, -2.412],
                              ],
                              o: [
                                 [-2.534, 3.552],
                                 [-2.73, 1.614],
                                 [-3.217, 0],
                                 [-2.112, -2.835],
                                 [0, -7.79],
                                 [2.681, -3.194],
                                 [2.144, 0],
                                 [2.193, 1.206],
                                 [0, 0],
                              ],
                              v: [
                                 [42.92, -17.2],
                                 [35.024, -9.451],
                                 [27.616, -7.031],
                                 [19.623, -11.285],
                                 [16.455, -25.755],
                                 [20.476, -42.23],
                                 [29.761, -47.021],
                                 [36.267, -45.213],
                                 [42.92, -39.786],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "d",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "d",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "x",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [14.289, 0],
                                 [29.183, -20.24],
                                 [44.336, 0],
                                 [56.348, 0],
                                 [36.303, -26.855],
                                 [54.297, -53.027],
                                 [44.768, -53.027],
                                 [31.332, -33.483],
                                 [16.699, -53.027],
                                 [4.639, -53.027],
                                 [24.268, -26.807],
                                 [4.541, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "x",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "x",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "E",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [55.127, 0],
                                 [55.127, -7.227],
                                 [20.215, -7.227],
                                 [20.215, -34.619],
                                 [47.9, -34.619],
                                 [47.9, -41.846],
                                 [20.215, -41.846],
                                 [20.215, -65.039],
                                 [52.881, -65.039],
                                 [52.881, -72.266],
                                 [10.547, -72.266],
                                 [10.547, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "E",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "E",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "c",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [4.649, 0],
                                 [3.543, 3.634],
                                 [0, 6.421],
                                 [-3.267, 3.683],
                                 [-5.852, 0],
                                 [-6.274, -2.248],
                                 [0, 0],
                                 [5.592, 0],
                                 [4.796, -5.066],
                                 [0, -8.34],
                                 [-4.781, -5.034],
                                 [-9.658, 0],
                                 [-5.691, 1.953],
                              ],
                              o: [
                                 [-5.819, 2.184],
                                 [-6.177, 0],
                                 [-3.543, -3.634],
                                 [0, -6.29],
                                 [3.267, -3.683],
                                 [4.42, 0],
                                 [0, 0],
                                 [-5.691, -1.692],
                                 [-9.04, 0],
                                 [-4.796, 5.067],
                                 [0, 8.439],
                                 [4.78, 5.034],
                                 [5.658, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [53.809, -9.281],
                                 [38.107, -6.006],
                                 [23.528, -11.456],
                                 [18.213, -26.538],
                                 [23.113, -41.497],
                                 [36.79, -47.021],
                                 [52.832, -43.649],
                                 [52.832, -51.709],
                                 [35.907, -54.248],
                                 [15.153, -46.648],
                                 [7.959, -26.538],
                                 [15.129, -6.329],
                                 [36.786, 1.221],
                                 [53.809, -1.709],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "c",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "c",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "t",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [4.004, 0],
                                 [1.855, 0.407],
                                 [1.302, 1.042],
                                 [0.325, 0.912],
                                 [0, 3.288],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-0.554, -2.555],
                                 [-1.726, -1.627],
                                 [-7.748, 0],
                                 [-4.818, 0.813],
                              ],
                              o: [
                                 [-3.939, 0.879],
                                 [-1.953, 0],
                                 [-1.855, -0.407],
                                 [-0.814, -0.651],
                                 [-0.326, -0.911],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 3.906],
                                 [0.553, 2.556],
                                 [2.93, 2.832],
                                 [4.492, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [53.809, -7.324],
                                 [41.895, -6.006],
                                 [36.182, -6.616],
                                 [31.445, -8.789],
                                 [29.736, -11.133],
                                 [29.248, -17.432],
                                 [29.248, -44.58],
                                 [52.734, -44.58],
                                 [52.734, -51.807],
                                 [29.248, -51.807],
                                 [29.248, -63.232],
                                 [19.58, -63.232],
                                 [19.58, -51.807],
                                 [6.348, -51.807],
                                 [6.348, -44.58],
                                 [19.58, -44.58],
                                 [19.58, -18.994],
                                 [20.41, -9.302],
                                 [23.828, -3.027],
                                 [39.844, 1.221],
                                 [53.809, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "t",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "t",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "m",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [-1.888, 2.303],
                                 [-1.4, 0],
                                 [-0.554, -0.473],
                                 [-0.228, -0.865],
                                 [0, -3.429],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-1.872, 2.379],
                                 [-1.498, 0],
                                 [-0.554, -0.439],
                                 [-0.195, -0.782],
                                 [0, -2.541],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0.731, 1.857],
                                 [1.51, 1.027],
                                 [1.819, 0],
                                 [2.078, -2.246],
                                 [1.331, -2.93],
                                 [0, 0],
                                 [1.709, 1.937],
                                 [2.278, 0],
                                 [1.839, -1.871],
                                 [2.051, -3.678],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [1.334, -3.2],
                                 [1.888, -2.303],
                                 [0.553, 0],
                                 [0.553, 0.474],
                                 [0.227, 0.865],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.367, -3.03],
                                 [1.871, -2.379],
                                 [0.52, 0],
                                 [0.553, 0.44],
                                 [0.195, 0.782],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, -4.496],
                                 [-0.731, -1.857],
                                 [-1.511, -1.026],
                                 [-2.437, 0],
                                 [-2.079, 2.246],
                                 [0, 0],
                                 [-0.261, -3.548],
                                 [-1.709, -1.936],
                                 [-2.148, 0],
                                 [-1.839, 1.872],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [12.988, 0],
                                 [12.988, -34.24],
                                 [17.822, -42.493],
                                 [22.754, -45.947],
                                 [24.414, -45.237],
                                 [25.586, -43.228],
                                 [25.928, -36.787],
                                 [25.928, 0],
                                 [34.375, 0],
                                 [34.375, -34.265],
                                 [39.233, -42.379],
                                 [44.287, -45.947],
                                 [45.898, -45.287],
                                 [47.021, -43.454],
                                 [47.314, -38.469],
                                 [47.314, 0],
                                 [55.762, 0],
                                 [55.762, -38.853],
                                 [54.665, -48.383],
                                 [51.304, -52.708],
                                 [46.31, -54.248],
                                 [39.538, -50.879],
                                 [34.422, -43.115],
                                 [33.789, -43.115],
                                 [30.835, -51.343],
                                 [24.854, -54.248],
                                 [18.872, -51.44],
                                 [13.037, -43.115],
                                 [12.402, -43.115],
                                 [12.988, -53.027],
                                 [4.541, -53.027],
                                 [4.541, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "m",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "m",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "D",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [-4.037, 1.335],
                                 [-3.158, 3.484],
                                 [-1.286, 4.769],
                                 [0, 5.73],
                                 [4.801, 6.136],
                                 [11.23, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [5.469, 0],
                                 [4.036, -1.334],
                                 [3.288, -3.613],
                                 [1.286, -4.768],
                                 [0, -10.872],
                                 [-4.802, -6.136],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [22.656, 0],
                                 [36.914, -2.002],
                                 [47.705, -9.229],
                                 [54.565, -21.802],
                                 [56.494, -37.549],
                                 [49.292, -63.062],
                                 [25.244, -72.266],
                                 [6.348, -72.266],
                                 [6.348, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "D",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [-3.109, -1.969],
                                 [-1.644, -4.215],
                                 [0, -6.608],
                                 [1.741, -4.362],
                                 [3.369, -2.067],
                                 [6.738, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [7.682, 0],
                                 [3.108, 1.97],
                                 [1.643, 4.216],
                                 [0, 6.738],
                                 [-1.742, 4.362],
                                 [-3.369, 2.068],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [16.016, -65.039],
                                 [20.508, -65.088],
                                 [36.694, -62.134],
                                 [43.823, -52.856],
                                 [46.289, -36.621],
                                 [43.677, -19.971],
                                 [36.011, -10.327],
                                 [20.85, -7.227],
                                 [16.016, -7.227],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "D",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "D",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "y",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [-1.852, 0.325],
                                 [-1.561, 1.106],
                                 [-1.95, 4.395],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0.635, -1.057],
                                 [1.041, -0.845],
                                 [3.841, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [2.827, 0],
                                 [1.852, -0.326],
                                 [3.152, -2.181],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-0.847, 1.82],
                                 [-0.635, 1.056],
                                 [-1.791, 1.43],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [11.064, 19.287],
                                 [18.085, 18.799],
                                 [23.205, 16.65],
                                 [30.859, 6.787],
                                 [57.544, -53.027],
                                 [48.94, -53.027],
                                 [31.471, -13.269],
                                 [14.133, -53.027],
                                 [4.175, -53.027],
                                 [26.424, -2.316],
                                 [24.059, 2.747],
                                 [21.837, 7.063],
                                 [19.322, 9.915],
                                 [10.875, 12.061],
                                 [6.969, 12.061],
                                 [6.969, 19.287],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "y",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "y",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "V",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [34.668, 0],
                                 [59.521, -72.266],
                                 [51.263, -72.266],
                                 [30.827, -12.84],
                                 [10.83, -72.266],
                                 [0.684, -72.266],
                                 [25, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "V",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "V",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "b",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-2.377, -0.96],
                                 [-2.507, 0],
                                 [-2.832, 1.433],
                                 [-2.051, 2.737],
                                 [0, 8.765],
                                 [3.45, 4.724],
                                 [6.055, 0],
                                 [2.881, -1.855],
                                 [2.344, -3.711],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [2.409, 2.898],
                                 [2.376, 0.96],
                                 [3.157, 0],
                                 [2.832, -1.434],
                                 [3.971, -5.31],
                                 [0, -7.852],
                                 [-3.451, -4.724],
                                 [-3.451, 0],
                                 [-2.881, 1.855],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [15.674, 0.586],
                                 [17.48, -6.006],
                                 [18.066, -6.006],
                                 [25.244, -0.22],
                                 [32.568, 1.221],
                                 [41.553, -0.929],
                                 [48.877, -7.185],
                                 [54.834, -28.297],
                                 [49.658, -47.162],
                                 [35.4, -54.248],
                                 [25.903, -51.465],
                                 [18.066, -43.115],
                                 [17.48, -43.115],
                                 [18.066, -53.062],
                                 [18.066, -77.1],
                                 [8.398, -77.1],
                                 [8.398, 0.586],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "b",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [-2.734, 1.598],
                                 [-2.214, 0],
                                 [-2.116, -2.835],
                                 [0, -6.844],
                                 [2.669, -3.226],
                                 [3.548, 0],
                                 [2.197, 1.206],
                                 [2.246, 2.412],
                              ],
                              o: [
                                 [2.539, -3.584],
                                 [2.734, -1.597],
                                 [3.223, 0],
                                 [2.116, 2.836],
                                 [0, 7.725],
                                 [-2.67, 3.226],
                                 [-2.148, 0],
                                 [-2.197, -1.205],
                                 [0, 0],
                              ],
                              v: [
                                 [18.066, -35.876],
                                 [25.977, -43.649],
                                 [33.398, -46.045],
                                 [41.406, -41.792],
                                 [44.58, -27.272],
                                 [40.576, -10.845],
                                 [31.25, -6.006],
                                 [24.731, -7.815],
                                 [18.066, -13.242],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "b",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "b",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "-",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [50, -26.514],
                                 [50, -33.74],
                                 [10.254, -33.74],
                                 [10.254, -26.514],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "-",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "-",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "o",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-4.325, 5.132],
                                 [0, 8.243],
                                 [4.325, 5.116],
                                 [8, 0],
                                 [4.325, -5.115],
                                 [0, -8.243],
                                 [-4.325, -5.132],
                                 [-8, 0],
                              ],
                              o: [
                                 [4.325, -5.132],
                                 [0, -8.243],
                                 [-4.325, -5.115],
                                 [-8, 0],
                                 [-4.325, 5.116],
                                 [0, 8.243],
                                 [4.325, 5.132],
                                 [8, 0],
                              ],
                              v: [
                                 [48.59, -6.477],
                                 [55.078, -26.538],
                                 [48.59, -46.575],
                                 [30.103, -54.248],
                                 [11.614, -46.575],
                                 [5.127, -26.538],
                                 [11.614, -6.477],
                                 [30.103, 1.221],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "o",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [2.583, 3.634],
                                 [0, 6.421],
                                 [-2.6, 3.634],
                                 [-4.615, 0],
                                 [-2.6, -3.634],
                                 [0, -6.387],
                                 [2.583, -3.634],
                                 [4.647, 0],
                              ],
                              o: [
                                 [-2.583, -3.634],
                                 [0, -6.387],
                                 [2.6, -3.634],
                                 [4.614, 0],
                                 [2.6, 3.634],
                                 [0, 6.421],
                                 [-2.583, 3.634],
                                 [-4.648, 0],
                              ],
                              v: [
                                 [19.256, -11.456],
                                 [15.381, -26.538],
                                 [19.28, -41.57],
                                 [30.103, -47.021],
                                 [40.924, -41.57],
                                 [44.824, -26.538],
                                 [40.948, -11.456],
                                 [30.103, -6.006],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "o",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "o",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "r",
         size: 115,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [-3.037, 1.907],
                                 [-3.281, 0],
                                 [-1.495, -0.325],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.859, 0],
                                 [3.104, -2.034],
                                 [2.209, -3.352],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [2.793, -3.845],
                                 [3.036, -1.906],
                                 [1.201, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-3.673, -0.813],
                                 [-4.713, 0],
                                 [-3.104, 2.035],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [24.707, 0],
                                 [24.707, -34.558],
                                 [33.453, -43.185],
                                 [42.929, -46.045],
                                 [46.973, -45.557],
                                 [46.973, -37.988],
                                 [54.199, -37.988],
                                 [54.199, -53.027],
                                 [44.401, -54.248],
                                 [32.677, -51.196],
                                 [24.706, -43.115],
                                 [24.121, -43.115],
                                 [24.707, -53.027],
                                 [15.039, -53.027],
                                 [15.039, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "r",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "r",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "g",
         size: 42,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-5.013, 0],
                                 [-3.239, 1.139],
                                 [-2.377, 2.995],
                                 [-0.603, 3.467],
                                 [0, 5.632],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [2.213, 0.89],
                                 [2.604, 0],
                                 [3.889, -5.696],
                                 [0, -7.747],
                                 [-0.928, -3.336],
                                 [-1.855, -2.409],
                                 [-2.474, -1.188],
                                 [-3.027, 0],
                                 [-1.775, 0.586],
                                 [-1.726, 1.237],
                                 [-0.961, 0.928],
                                 [-1.27, 2.279],
                                 [0, 0],
                                 [0, 0],
                                 [0.504, -1.693],
                                 [1.334, -1.335],
                                 [5.305, 0],
                                 [2.246, 0.358],
                                 [4.134, 1.725],
                                 [0, 0],
                              ],
                              o: [
                                 [4.557, 0],
                                 [3.239, -1.14],
                                 [2.246, -2.898],
                                 [0.602, -3.467],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-3.158, -3.04],
                                 [-2.214, -0.889],
                                 [-7.097, 0],
                                 [-3.89, 5.697],
                                 [0, 3.581],
                                 [0.928, 3.337],
                                 [1.497, 1.986],
                                 [2.473, 1.189],
                                 [2.116, 0],
                                 [1.774, -0.586],
                                 [1.106, -0.781],
                                 [0.96, -0.928],
                                 [0, 0],
                                 [0, 0],
                                 [-0.293, 2.669],
                                 [-0.505, 1.692],
                                 [-2.344, 2.409],
                                 [-2.084, 0],
                                 [-2.246, -0.359],
                                 [0, 0],
                                 [6.315, 1.855],
                              ],
                              v: [
                                 [27.344, 20.508],
                                 [39.038, 18.799],
                                 [47.461, 12.598],
                                 [51.733, 3.052],
                                 [52.637, -10.596],
                                 [52.637, -53.027],
                                 [44.189, -53.027],
                                 [44.189, -47.021],
                                 [43.604, -47.021],
                                 [35.547, -52.914],
                                 [28.32, -54.248],
                                 [11.841, -45.703],
                                 [6.006, -25.537],
                                 [7.397, -15.161],
                                 [11.572, -6.543],
                                 [17.529, -1.782],
                                 [25.781, 0],
                                 [31.616, -0.879],
                                 [36.865, -3.613],
                                 [39.966, -6.177],
                                 [43.311, -10.986],
                                 [43.896, -10.986],
                                 [42.871, -1.416],
                                 [41.675, 5.127],
                                 [38.916, 9.668],
                                 [27.441, 13.281],
                                 [20.947, 12.744],
                                 [11.377, 9.619],
                                 [10.352, 17.725],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "g",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0.813, -0.831],
                                 [1.041, -0.847],
                                 [2.995, 0],
                                 [2.051, 3.178],
                                 [0, 5.508],
                                 [-2.181, 3.895],
                                 [-4.525, 0],
                                 [-2.132, -1.043],
                                 [-2.474, -2.803],
                                 [0, 0],
                              ],
                              o: [
                                 [-0.814, 0.832],
                                 [-3.158, 2.444],
                                 [-3.679, 0],
                                 [-2.051, -3.178],
                                 [0, -6.095],
                                 [2.18, -3.895],
                                 [2.213, 0],
                                 [2.132, 1.043],
                                 [0, 0],
                                 [-1.237, 1.988],
                              ],
                              v: [
                                 [39.941, -14.583],
                                 [37.158, -12.065],
                                 [27.93, -8.398],
                                 [19.336, -13.165],
                                 [16.26, -26.194],
                                 [19.531, -41.179],
                                 [29.59, -47.021],
                                 [36.108, -45.457],
                                 [43.018, -39.688],
                                 [43.018, -18.812],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "g",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "g",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: ".",
         size: 42,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [37.354, 0],
                                 [37.354, -14.453],
                                 [22.9, -14.453],
                                 [22.9, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: ".",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: ".",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "v",
         size: 42,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [34.668, 0],
                                 [57.227, -53.027],
                                 [48.689, -53.027],
                                 [30.589, -10.486],
                                 [12.96, -53.027],
                                 [3.027, -53.027],
                                 [25, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "v",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "v",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "S",
         size: 42,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-2.67, -0.31],
                                 [-2.148, 0],
                                 [-4.427, 4.086],
                                 [0, 6.055],
                                 [1.985, 2.93],
                                 [5.566, 2.865],
                                 [0, 0],
                                 [1.367, 1.855],
                                 [0, 2.181],
                                 [-1.009, 1.644],
                                 [-2.035, 0.765],
                                 [-2.572, 0],
                                 [-7.031, -2.702],
                                 [0, 0],
                                 [5.534, 0],
                                 [3.516, -3.76],
                                 [0, -5.013],
                                 [-2.019, -2.897],
                                 [-5.144, -2.702],
                                 [0, 0],
                                 [0, -4.623],
                                 [1.253, -2.019],
                                 [2.393, -1.025],
                                 [2.734, 0],
                                 [8.463, 3.841],
                                 [0, 0],
                              ],
                              o: [
                                 [2.669, 0.309],
                                 [8.366, 0],
                                 [4.427, -4.085],
                                 [0, -3.841],
                                 [-1.986, -2.93],
                                 [0, 0],
                                 [-3.679, -1.888],
                                 [-1.4, -1.855],
                                 [0, -1.855],
                                 [1.009, -1.643],
                                 [2.034, -0.764],
                                 [5.534, 0],
                                 [0, 0],
                                 [-7.031, -1.953],
                                 [-7.617, 0],
                                 [-3.516, 3.76],
                                 [0, 3.874],
                                 [2.018, 2.898],
                                 [0, 0],
                                 [5.728, 2.995],
                                 [0, 2.279],
                                 [-1.254, 2.019],
                                 [-2.393, 1.025],
                                 [-6.087, 0],
                                 [0, 0],
                                 [6.315, 1.692],
                              ],
                              v: [
                                 [21.484, 1.343],
                                 [28.711, 1.807],
                                 [47.9, -4.321],
                                 [54.541, -19.531],
                                 [51.562, -29.688],
                                 [40.234, -38.379],
                                 [27.148, -45.166],
                                 [19.58, -50.781],
                                 [17.48, -56.836],
                                 [18.994, -62.085],
                                 [23.56, -65.698],
                                 [30.469, -66.846],
                                 [49.316, -62.793],
                                 [49.316, -71.143],
                                 [30.469, -74.072],
                                 [13.77, -68.433],
                                 [8.496, -55.273],
                                 [11.523, -45.117],
                                 [22.266, -36.719],
                                 [36.28, -29.396],
                                 [44.873, -17.97],
                                 [42.993, -11.523],
                                 [37.524, -6.958],
                                 [29.834, -5.42],
                                 [8.008, -11.182],
                                 [8.008, -1.66],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "S",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "S",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "k",
         size: 42,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [20.068, 0],
                                 [20.068, -27.773],
                                 [45.077, 0],
                                 [58.06, 0],
                                 [31.789, -29.175],
                                 [52.74, -53.027],
                                 [42.294, -53.027],
                                 [20.068, -27.797],
                                 [20.068, -77.1],
                                 [10.4, -77.1],
                                 [10.4, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "k",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "k",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "J",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-3.711, 0],
                                 [-3.223, 1.53],
                                 [-1.937, 3.402],
                                 [0, 9.148],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [1.056, -2.034],
                                 [1.884, -0.813],
                                 [2.405, 0],
                                 [4.451, 1.465],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [3.906, 0],
                                 [3.223, -1.53],
                                 [1.936, -3.401],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 5.078],
                                 [-1.056, 2.035],
                                 [-1.884, 0.814],
                                 [-2.567, 0],
                                 [0, 0],
                                 [0, 0],
                                 [5.957, 1.562],
                              ],
                              v: [
                                 [24.121, 1.807],
                                 [34.814, -0.488],
                                 [42.554, -7.886],
                                 [45.459, -26.709],
                                 [45.459, -72.266],
                                 [14.16, -72.266],
                                 [14.16, -65.039],
                                 [35.791, -65.039],
                                 [35.791, -21.582],
                                 [34.207, -10.913],
                                 [29.797, -6.641],
                                 [23.363, -5.42],
                                 [12.836, -7.617],
                                 [9.619, -8.691],
                                 [9.619, -0.537],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "J",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "J",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "h",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [-2.404, 1.711],
                                 [-2.599, 0],
                                 [-1.202, -0.505],
                                 [-0.748, -1.108],
                                 [-0.179, -1.238],
                                 [0, -2.02],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0.439, 1.971],
                                 [1.365, 1.662],
                                 [5.038, 0],
                                 [3.169, -1.92],
                                 [2.145, -3.58],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [2.663, -3.519],
                                 [2.403, -1.711],
                                 [1.396, 0],
                                 [1.202, 0.505],
                                 [0.682, 1.01],
                                 [0.179, 1.238],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, -2.965],
                                 [-0.439, -1.971],
                                 [-2.763, -3.388],
                                 [-3.543, 0],
                                 [-3.169, 1.921],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [18.555, 0],
                                 [18.555, -34.705],
                                 [26.156, -42.551],
                                 [33.66, -45.117],
                                 [37.558, -44.36],
                                 [40.482, -41.94],
                                 [41.772, -38.567],
                                 [42.041, -33.679],
                                 [42.041, 0],
                                 [51.66, 0],
                                 [51.66, -36.312],
                                 [51.002, -43.716],
                                 [48.296, -49.165],
                                 [36.594, -54.248],
                                 [26.526, -51.367],
                                 [18.554, -43.115],
                                 [17.969, -43.115],
                                 [18.555, -53.062],
                                 [18.555, -77.1],
                                 [8.887, -77.1],
                                 [8.887, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "h",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "h",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "l",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [38.574, 0],
                                 [38.574, -77.1],
                                 [8.447, -77.1],
                                 [8.447, -69.873],
                                 [28.906, -69.873],
                                 [28.906, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "l",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "l",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "f",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-0.471, 1.318],
                                 [-1.073, 0.912],
                                 [-4.549, 0],
                                 [-4.744, -1.66],
                                 [0, 0],
                                 [5.208, 0],
                                 [3.45, -2.1],
                                 [1.041, -3.45],
                                 [0, -3.938],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, -2.962],
                                 [0.471, -1.318],
                                 [2.274, -1.92],
                                 [4.614, 0],
                                 [0, 0],
                                 [-4.655, -1.399],
                                 [-6.12, 0],
                                 [-3.451, 2.1],
                                 [-0.716, 2.344],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [31.348, 0],
                                 [31.348, -44.58],
                                 [56.641, -44.58],
                                 [56.641, -51.807],
                                 [31.348, -51.807],
                                 [31.348, -58.447],
                                 [32.054, -64.868],
                                 [34.37, -68.213],
                                 [44.605, -71.094],
                                 [58.643, -68.604],
                                 [58.643, -76.221],
                                 [43.848, -78.32],
                                 [29.492, -75.171],
                                 [22.754, -66.846],
                                 [21.68, -57.422],
                                 [21.68, -51.807],
                                 [7.227, -51.807],
                                 [7.227, -44.58],
                                 [21.68, -44.58],
                                 [21.68, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "f",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "f",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      { ch: "\r", size: 28, style: "Regular", w: 0, fFamily: "Lucida Sans Typewriter" },
      {
         ch: "F",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [21.729, 0],
                                 [21.729, -31.738],
                                 [51.514, -31.738],
                                 [51.514, -38.965],
                                 [21.729, -38.965],
                                 [21.729, -65.039],
                                 [56.641, -65.039],
                                 [56.641, -72.266],
                                 [12.061, -72.266],
                                 [12.061, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "F",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "F",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "u",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [2.403, -1.725],
                                 [2.599, 0],
                                 [1.202, 0.521],
                                 [0.747, 1.107],
                                 [0.179, 1.237],
                                 [0, 2.051],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [-0.439, -1.953],
                                 [-1.366, -1.692],
                                 [-5.006, 0],
                                 [-3.169, 1.895],
                                 [-2.178, 3.63],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [-2.664, 3.516],
                                 [-2.404, 1.726],
                                 [-1.397, 0],
                                 [-1.202, -0.52],
                                 [-0.682, -0.977],
                                 [-0.179, -1.237],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 2.995],
                                 [0.439, 1.953],
                                 [2.763, 3.386],
                                 [3.543, 0],
                                 [3.169, -1.896],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [41.699, -53.027],
                                 [41.699, -18.359],
                                 [34.098, -10.498],
                                 [26.594, -7.91],
                                 [22.696, -8.691],
                                 [19.772, -11.133],
                                 [18.481, -14.453],
                                 [18.213, -19.385],
                                 [18.213, -53.027],
                                 [8.594, -53.027],
                                 [8.594, -16.748],
                                 [9.251, -9.326],
                                 [11.958, -3.857],
                                 [23.611, 1.221],
                                 [33.679, -1.623],
                                 [41.7, -9.912],
                                 [42.285, -9.912],
                                 [41.699, 0],
                                 [51.367, 0],
                                 [51.367, -53.027],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "u",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "u",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "1",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [57.227, 0],
                                 [57.227, -7.227],
                                 [37.939, -7.227],
                                 [37.939, -74.072],
                                 [9.033, -65.039],
                                 [9.033, -59.082],
                                 [28.32, -63.428],
                                 [28.32, -7.227],
                                 [9.033, -7.227],
                                 [9.033, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "9",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-3.809, 0],
                                 [-4.281, 3.011],
                                 [-2.523, 5.73],
                                 [0, 8.789],
                                 [4.004, 6.462],
                                 [8.105, 0],
                                 [3.336, -2.067],
                                 [2.018, -4.053],
                                 [0, -4.72],
                                 [-1.839, -3.385],
                                 [-3.125, -1.969],
                                 [-3.581, 0],
                                 [-2.002, 0.684],
                                 [-1.628, 1.189],
                                 [-1.693, 2.441],
                                 [3.288, -5.354],
                                 [6.087, 0],
                                 [1.709, 0.342],
                                 [4.329, 1.693],
                                 [0, 0],
                              ],
                              o: [
                                 [5.143, 0],
                                 [4.28, -3.011],
                                 [2.522, -5.729],
                                 [0, -11.393],
                                 [-4.004, -6.461],
                                 [-4.427, 0],
                                 [-3.337, 2.068],
                                 [-2.019, 4.053],
                                 [0, 4.33],
                                 [1.839, 3.386],
                                 [3.125, 1.97],
                                 [2.083, 0],
                                 [2.002, -0.684],
                                 [1.627, -1.188],
                                 [0, 9.245],
                                 [-3.288, 5.355],
                                 [-1.498, 0],
                                 [-1.709, -0.342],
                                 [0, 0],
                                 [6.055, 1.692],
                              ],
                              v: [
                                 [26.025, 1.807],
                                 [40.161, -2.71],
                                 [50.366, -15.82],
                                 [54.15, -37.598],
                                 [48.145, -64.38],
                                 [29.98, -74.072],
                                 [18.335, -70.972],
                                 [10.303, -61.792],
                                 [7.275, -48.633],
                                 [10.034, -37.061],
                                 [17.48, -29.028],
                                 [27.539, -26.074],
                                 [33.667, -27.1],
                                 [39.111, -29.907],
                                 [44.092, -35.352],
                                 [39.16, -13.452],
                                 [25.098, -5.42],
                                 [20.288, -5.933],
                                 [11.23, -8.984],
                                 [11.23, -0.732],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "9",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                     {
                        ind: 1,
                        ty: "sh",
                        ix: 2,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [-2.49, -3.385],
                                 [0, -5.403],
                                 [2.734, -2.409],
                                 [3.223, 0],
                                 [2.132, 1.384],
                                 [1.09, 2.865],
                                 [0, 3.223],
                                 [-2.1, 3.304],
                                 [-3.939, 0],
                              ],
                              o: [
                                 [2.49, 3.386],
                                 [0, 5.566],
                                 [-2.734, 2.409],
                                 [-2.377, 0],
                                 [-2.132, -1.383],
                                 [-1.091, -2.864],
                                 [0, -4.231],
                                 [2.1, -3.304],
                                 [4.395, 0],
                              ],
                              v: [
                                 [39.136, -61.768],
                                 [42.871, -48.584],
                                 [38.77, -36.621],
                                 [29.834, -33.008],
                                 [23.071, -35.083],
                                 [18.237, -41.455],
                                 [16.602, -50.586],
                                 [19.751, -61.89],
                                 [28.809, -66.846],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "9",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "9",
                  np: 5,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
      {
         ch: "w",
         size: 28,
         style: "Regular",
         w: 60.25,
         data: {
            shapes: [
               {
                  ty: "gr",
                  it: [
                     {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                           a: 0,
                           k: {
                              i: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              o: [
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                                 [0, 0],
                              ],
                              v: [
                                 [19.873, 0],
                                 [30.151, -39.648],
                                 [30.249, -39.648],
                                 [39.551, 0],
                                 [49.023, 0],
                                 [59.619, -53.027],
                                 [52.249, -53.027],
                                 [44.421, -10.107],
                                 [44.287, -10.107],
                                 [35.371, -48.193],
                                 [26.047, -48.193],
                                 [16.634, -11.523],
                                 [16.504, -11.523],
                                 [9.473, -53.027],
                                 [0.586, -53.027],
                                 [10.254, 0],
                              ],
                              c: true,
                           },
                           ix: 2,
                        },
                        nm: "w",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                     },
                  ],
                  nm: "w",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
               },
            ],
         },
         fFamily: "Lucida Sans Typewriter",
      },
   ],
};

import { useEffect } from "react";
import ScheduleMain from "./ScheduleMain";

const Schedule = () => {
   useEffect(() => {
      window.scrollTo(0, -1);
   }, []);
   return (
      <div className='' style={{ position: "relative" }}>
         <div id='stars'></div>
         <div id='stars3'></div>

         <ScheduleMain />
      </div>
   );
};

export default Schedule;

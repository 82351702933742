import { Component } from "react";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import DeadEnd from "../../../components/DeadEnd";
import RippleButton from "../../../components/Button/RippleButton";

import EventCard from "../event-card";
import eventsjson from "../../../data/events.json";
import { eventsBaseURL } from "../../../utils/urls";
import "../events-container/index.css";

export default class EventsContainer extends Component {
   state = {
      // Sort type: name, datetime
      sortType: "datetime",
      filters: {
         category: "All",
         needRegistration: "All",
         eventType: "All",
      },

      categories: [],

      events: [eventsjson],

      loadingDone: false,
   };

   constructor(props) {
      super(props);
      this.categoryOptions = ["All"];
   }

   getEvents = async () => {
      let eventData = [];

      const res = await await axios.get(`${eventsBaseURL}/events`);
      let result = res.data.filter((event) => event.eventType !== "competition");
      let promises = [];

      if (res.status === 200) {
         result.forEach((event) => {
            promises.push(axios.get(`${eventsBaseURL}/events/` + event.id));
         });

         let responses = await Promise.all(promises);
         responses.forEach((response) => eventData.push(response.data));
      }

      return eventData;
   };

   async componentDidMount() {
      window.scrollTo(0, 0);
      const events_api_response = await this.getEvents();
      const events = events_api_response.concat(this.state.events[0]);
      this.setState({
         events: events,
         categories: ["non_tech", "computer_science", "general_tech"],
         loadingDone: true,
      });
   }

   sortEvents = (a, b) => {
      if (a[this.state.sortType] > b[this.state.sortType]) {
         return 1;
      } else {
         return -1;
      }
   };

   filterEvents = () => {
      let events = this.state.events.slice();

      Object.keys(this.state.filters).forEach((key) => {
         if (this.state.filters[key] === "All") return true;

         events = events.filter((event) => event[key] === this.state.filters[key]);
      });

      return events;
   };

   EventCardsContainer = () => {
      let events = this.filterEvents();

      if (!this.state.loadingDone) return <Loader loaderposition='loader-top' />;

      if (events.length === 0) return <DeadEnd />;

      return (
         <div className='events-body eventsContainer'>
            {events.sort(this.sortCompetitions).map((event) => (
               <EventCard key={event.id} event={event} />
            ))}
         </div>
      );
   };

   sortCompetitions = (a, b) => {
      if (a[this.state.sortType] > b[this.state.sortType]) {
         return -1;
      } else {
         return 1;
      }
   };

   onSortOptionChanged = (option) => {
      // Sort => Name: name, Time: datetime
      let key = "name";

      switch (option) {
         default:
         case "Name":
            key = "name";
            break;
         case "Time":
            key = "datetime";
            break;
      }
      this.setState({ sortType: key });
   };

   onCategoryOptionChanged = (label) => {
      let value = "All";

      if (label !== "All") value = label.toLowerCase().replace(" ", "_");

      this.setState((prevState) => {
         let state = Object.assign({}, prevState);
         state.filters.eventType = value;
         return state;
      });
   };

   onSortOptionChanged = (option) => {
      // Sort => Name: name, Time: datetime
      let key = "name";

      switch (option) {
         default:
         case "Name":
            key = "name";
            break;
         case "Time":
            key = "datetime";
            break;
      }

      this.setState({ sortType: key });
   };

   render() {
      return (
         <div className='events-body eventsContainer-2'>
            <h1 className='page-title event-page-title'>Events</h1>
            <div className='sort-filter-box-2 row'>
               <div className='events-categories'>
                  <div className='events-group-1'>
                     <div className='input-container'>
                        <div
                           style={{
                              opacity: "1",
                           }}
                           className='radio-button'>
                           <RippleButton children='All' onClick={() => this.onCategoryOptionChanged("All")}>
                              All
                           </RippleButton>
                        </div>
                     </div>
                     <div className='input-container'>
                        <div
                           style={{
                              opacity: "1",
                           }}
                           className='radio-button'>
                           <RippleButton children='All' onClick={() => this.onCategoryOptionChanged("general")}>
                              General
                           </RippleButton>
                        </div>
                     </div>

                     <div className='input-container'>
                        <div
                           style={{
                              opacity: "1",
                           }}
                           className='radio-button'>
                           <RippleButton children='All' onClick={() => this.onCategoryOptionChanged("Talk")}>
                              Talks
                           </RippleButton>
                        </div>
                     </div>
                  </div>
                  <div className='events-group-2'>
                     <div className='input-container'>
                        <div
                           style={{
                              opacity: "1",
                           }}
                           className='radio-button'>
                           <RippleButton children='All' onClick={() => this.onCategoryOptionChanged("conference")}>
                              Conference
                           </RippleButton>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='row category-row'>
                  <div className='col-6 category-col'>
                     {" "}
                     <div className='drop-down-div category-div'>
                        <select style={{ color: "#cfcdcf" }} onChange={(e) => this.onCategoryOptionChanged(e.target.value)} className=' sort-down'>
                           <option selected disabled hidden>
                              Category &nbsp;▼
                           </option>

                           <option value='All'>All</option>
                           <option value='general'>General</option>
                           <option value='Talk'>Talks</option>
                           <option value='Workshop'>Workshop</option>
                           <option value='conference'>Conference</option>
                        </select>
                     </div>
                  </div>
                  <div className='col-6'>
                     {" "}
                     <div className='drop-down-div'>
                        <select style={{ color: "#cfcdcf" }} onChange={(e) => this.onSortOptionChanged(e.target.value)} className=' sort-down'>
                           <option selected disabled hidden>
                              Sort by ▼
                           </option>
                           <option value='Name'>Name</option>
                           <option value='Time'>Time</option>
                        </select>
                     </div>
                  </div>
               </div>
            </div>
            <this.EventCardsContainer />
         </div>
      );
   }
}

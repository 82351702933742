import { Component } from "react";
import axios from "axios";

import Loader from "../../../components/Loader/Loader";
import DeadEnd from "../../../components/DeadEnd";
import RippleButton from "../../../components/Button/RippleButton";

import CompetitionCard from "../competition-card";
import { eventsBaseURL } from "../../../utils/urls";

import "../competitions-grid/index.css";
import "../competitions-grid/button.css";

export default class CompetitionsGrid extends Component {
   state = {
      // Sort type: name, datetime
      sortType: "datetime",
      filters: {
         category: "All",
         needRegistration: "All",
      },

      categories: [],

      competitions: [],

      loadingDone: false,
   };

   constructor(props) {
      super(props);
      this.categoryOptions = ["All"];
   }

   getCompetitions = async () => {
      let competitionsData = [];

      const res = await axios.get(`${eventsBaseURL}/events`);
      let result = res.data.filter((event) => event.eventType === "competition");
      let promises = [];

      if (res.status === 200) {
         result.forEach((event) => {
            promises.push(axios.get(`${eventsBaseURL}/events/` + event.id));
         });

         let responses = await Promise.all(promises);
         responses.forEach((response) => competitionsData.push(response.data));
      }

      return competitionsData;
   };

   async componentDidMount() {
      const competitions = await this.getCompetitions();

      this.setState({
         competitions: competitions,
         categories: ["non_tech", "cs_tech", "general_tech"],
         loadingDone: true,
      });
   }

   sortCompetitions = (a, b) => {
      if (this.state.sortType === "prizeMoney") {
         if (a[this.state.sortType] < b[this.state.sortType]) {
            return 1;
         } else {
            return -1;
         }
      } else {
         if (a[this.state.sortType] > b[this.state.sortType]) {
            return 1;
         } else {
            return -1;
         }
      }
   };

   filterCompetitions = () => {
      let competitions = this.state.competitions.slice();

      Object.keys(this.state.filters).forEach((key) => {
         if (this.state.filters[key] === "All") return true;

         competitions = competitions.filter((competition) => competition[key] === this.state.filters[key]);
      });

      return competitions;
   };

   CompetitionCardsContainer = () => {
      let competitions = this.filterCompetitions();

      if (!this.state.loadingDone) return <Loader loaderposition='loader-top' />;

      if (competitions.length === 0) return <DeadEnd />;

      return (
         <div className='competition-cards-container '>
            {competitions.sort(this.sortCompetitions).map((competition) => (
               <CompetitionCard key={competition.id} competition={competition} />
            ))}
         </div>
      );
   };

   onSortOptionChanged = (option) => {
      // Sort => Name: name, Time: datetime
      let key = "name";

      switch (option) {
         default:
         case "Name":
            key = "name";
            break;
         case "Time":
            key = "datetime";
            break;
         case "Prize":
            key = "prizeMoney";
            break;
      }

      this.setState({ sortType: key });
   };

   onCategoryOptionChanged = (label) => {
      let value = "All";

      if (label !== "All") value = label.toLowerCase().replace(" ", "_");

      this.setState((prevState) => {
         let state = Object.assign({}, prevState);
         state.filters.category = value;
         return state;
      });
   };

   render() {
      return (
         <div className=' competition-grid  competitions-body'>
            <h2 className='page-title competition-title'>Competitions</h2>
            <div className=' sort-filter-box row'>
               <div className='competitionfilterbtn radio-tile-group col-md-10'>
                  <div className='competition-sort-1'>
                     <div className='input-container'>
                        <div
                           style={{
                              opacity: "1",
                           }}
                           className='radio-button'>
                           <RippleButton children='All' onClick={() => this.onCategoryOptionChanged("All")}>
                              All
                           </RippleButton>
                        </div>
                     </div>
                     <div className='input-container'>
                        <div
                           style={{
                              opacity: "1",
                           }}
                           className='radio-button'>
                           <RippleButton children='All' onClick={() => this.onCategoryOptionChanged("CS Tech")}>
                              CS Tech
                           </RippleButton>
                        </div>
                     </div>
                  </div>
                  <div className='competition-sort-2'>
                     <div className='input-container'>
                        <div
                           style={{
                              opacity: "1",
                           }}
                           className='radio-button'>
                           <RippleButton children='All' onClick={() => this.onCategoryOptionChanged("General Tech")}>
                              Gen Tech
                           </RippleButton>
                        </div>
                     </div>

                     <div className='input-container'>
                        <div
                           style={{
                              opacity: "1",
                           }}
                           className='radio-button'>
                           <RippleButton children='All' onClick={() => this.onCategoryOptionChanged("Non Tech")}>
                              Non Tech
                           </RippleButton>
                        </div>
                     </div>
                  </div>
               </div>

               {/* ----------- */}
               <div className='row  compDropDown'>
                  <div className='col-6 category-col'>
                     {" "}
                     <div className='drop-down-div category-div'>
                        <select style={{ color: "#cfcdcf" }} onChange={(e) => this.onCategoryOptionChanged(e.target.value)} className=' sort-down'>
                           <option selected disabled hidden>
                              Category ▼
                           </option>

                           <option value='All'>All</option>
                           <option value='CS Tech'>CS Tech</option>
                           <option value='General Tech'>Gen Tech</option>
                           <option value='Non Tech'>Non Tech</option>
                        </select>
                     </div>
                  </div>
                  <div className='col-6'>
                     {" "}
                     <div className='drop-down-div '>
                        <select style={{ color: "#cfcdcf" }} onChange={(e) => this.onSortOptionChanged(e.target.value)} className=' drop-down'>
                           <option selected disabled hidden>
                              Sort by ▼
                           </option>
                           <option value='Name'>Name</option>
                           <option value='Time'>Time</option>
                           <option value='Prize'>Prize</option>
                        </select>
                     </div>
                  </div>
               </div>
               {/* ------------ */}
            </div>
            <this.CompetitionCardsContainer />
         </div>
      );
   }
}

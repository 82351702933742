import { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import AuthHelper from "./utils/authHelper";
import Navbar from "./components/Navbar";
import NavProfileIcon from "./components/NavProfileIcon";

import Home from "./pages/home";
import Events from "./pages/events";
import Competitions from "./pages/competitions";
import Schedule from "./pages/schedule";
import Contacts from "./pages/contacts";
import Loader from "./components/Loader/Loader";
import "./animation.css";
import "./App.css";

const App = () => {
   const [isLoading, setLoading] = useState(true);
   const setIsLoggedIn = useState(false)[1];

   const location = useLocation();
   useEffect(
      () =>
         setTimeout(() => {
            setLoading(false);
         }, 1000),
      []
   );
   useEffect(() => {
      let index = window.location.href.indexOf("?");
      const searchString = window.location.href.slice(index);
      const urlParams = new URLSearchParams(searchString);
      const refreshToken = urlParams.get("refreshToken");
      if (refreshToken) localStorage.setItem("refreshToken", refreshToken);
      // When the user logins in for the first time, the url would be of the form
      // www.excelmec.org?refreshToken=<token>
      // using the below code, we are redirecting it to site without the refreshToken parameter
      if (index >= 0) {
         window.open(window.location.href.slice(0, index), "_self");
      }
   }, []);

   function receiveMessage(event) {
      if (typeof event.data == "string" && event.data.length !== 0)
         AuthHelper.asyncSetRefreshToken(event.data).then((res) => {
            if (res != null) setIsLoggedIn(true);
         });
      else if (event.data == null) AuthHelper.clearAllTokens();
   }

   window.addEventListener("message", receiveMessage);
   if (isLoading) {
      return <Loader loaderposition='loader-center' />;
   }
   return (
      <div className='app-container'>
         <Navbar />

         <div className='nav-shift'>
            <TransitionGroup>
               <div className='mobile-profile-icon'>
                  <NavProfileIcon isMobile={true} />
               </div>
               <CSSTransition timeout={1000} classNames='fade' key={location.key}>
                  <Switch>
                     <Route exact path='/' component={Home} />
                     <Route exact path='/competitions' component={Competitions} />
                     <Route exact path='/events' component={Events} />
                     <Route exact path='/schedule' component={Schedule} />
                     <Route exact path='/contacts' component={Contacts} />
                     <Redirect to='/' />
                  </Switch>
               </CSSTransition>
            </TransitionGroup>
         </div>
      </div>
   );
};

export default App;

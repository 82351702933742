import { NavLink } from "react-router-dom";

import "./Bottombar.css";
import home from "../../assets/svg/home.svg";
import schedule from "../../assets/svg/schedule.svg";
import competitions from "../../assets/svg/competitions.svg";
import contacts from "../../assets/svg/contacts.svg";
import events from "../../assets/svg/events.svg";



const Bottombar = () => {
   return (
      <div className='bottombarContainer'>
         <NavLink exact to='/' activeClassName='activeContainer'>
            <div className='bottomBarLink'>
               <img alt='Home' src={home} height='25px' width='25px' />
            </div>
         </NavLink>
         <NavLink exact to='/competitions' activeClassName='activeContainer'>
            <div className='bottomBarLink'>
               <img alt='Competitions' src={competitions} height='25px' width='25px' />
            </div>
         </NavLink>
         <NavLink exact to='/events' activeClassName='activeContainer'>
            <div className='bottomBarLink'>
               <img alt='Events' src={events} height='25px' width='25px' />
            </div>
         </NavLink>
         <NavLink exact to='/schedule' activeClassName='activeContainer'>
            <div className='bottomBarLink'>
               <img alt='Schedule' src={schedule} height='25px' width='25px' />
            </div>
         </NavLink>
         <NavLink exact to='/contacts' activeClassName='activeContainer'>
            <div className='bottomBarLink'>
               <img alt='Contacts' src={contacts} height='25px' width='25px' />
            </div>
         </NavLink>
         {/* <NavProfileIcon isMobile={true} /> */}
      </div>
   );
};

export default Bottombar;

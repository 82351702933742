import React, { useEffect, useState, useRef } from "react";

import AccountHelper from "../../utils/accountHelper";
import AuthHelper from "../../utils/authHelper";
import { accountsBaseURL } from "../../utils/urls";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import "./ProfilePopup.css";

const ProfilePopup = ({ userprofile, token, close }) => {
   const [profile, setProfile] = useState(userprofile);
   const ProfleAnimation = useRef(null);
   const NoProfleAnimation = useRef(null);

   let onEditProfileClicked = () => {
      AuthHelper.aysncGetAccessToken().then(() => window.open(`${accountsBaseURL}?refreshToken=${token}`, "_blank"));
   };
   let refvalue = useWindowDimensions().width < 600 ? NoProfleAnimation : ProfleAnimation;
   return (
      <div className='profilePopupContainer '>
         <div className='profile-popup-content'>
            <div className='close-container-mobile ' onClick={() => close()}>
               <svg className='close-button' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 252 252'>
                  <path d='M126 0C56.523 0 0 56.523 0 126s56.523 126 126 126 126-56.523 126-126S195.477 0 126 0zm0 234c-59.551 0-108-48.449-108-108S66.449 18 126 18s108 48.449 108 108-48.449 108-108 108z' />
                  <path d='M164.612 87.388a9 9 0 00-12.728 0L126 113.272l-25.885-25.885a9 9 0 00-12.728 0 9 9 0 000 12.728L113.272 126l-25.885 25.885a9 9 0 006.364 15.364 8.975 8.975 0 006.364-2.636L126 138.728l25.885 25.885c1.757 1.757 4.061 2.636 6.364 2.636s4.606-.879 6.364-2.636a9 9 0 000-12.728L138.728 126l25.885-25.885a9 9 0 00-.001-12.727z' />
               </svg>
            </div>
            <div className='profile-popup'>
               <span className='signed-in-text row'>
                  {profile.picture && <img src={profile.picture} alt='profile pic' />}
                  <br />
                  <div>Signed in as {profile.name.split(" ")[0]}</div>
                  <i>
                     {" "}
                     <small style={{ color: "#b061ff" }}>{profile.email}</small>
                  </i>
               </span>
               <div className='profile-row-2'>
                  <div className='row-content'>
                     <h2>Experience the magic of Excel</h2>
                     <p className='profilePopupdescr'>
                        Now that you've got your ticket, <br /> it's time to choose from the array of cool things we have in store for you!
                     </p>
                  </div>
                  <div className='buttons-box'>
                     <button className='profile-btn' onClick={onEditProfileClicked}>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 472.615 472.615'>
                           <g fill='#FFF'>
                              <circle cx='236.308' cy='117.504' r='111.537' />
                              <path d='M369 246.306c-1.759-1.195-5.297-3.493-5.297-3.493-28.511 39.583-74.993 65.402-127.395 65.402-52.407 0-98.894-25.825-127.404-65.416 0 0-2.974 1.947-4.451 2.942C41.444 288.182 0 360.187 0 441.87v24.779h472.615V441.87c0-81.321-41.077-153.048-103.615-195.564z' />
                           </g>
                        </svg>
                        <span>Account</span>
                     </button>
                     <button
                        className='profile-btn'
                        onClick={() => {
                           setProfile(null);
                           AccountHelper.logUserOut();
                        }}>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                           <path
                              fill='#E7E7EA'
                              d='M12.59,13l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H3a1,1,0,0,0,0,2ZM12,2A10,10,0,0,0,3,7.55a1,1,0,0,0,1.8.9A8,8,0,1,1,12,20a7.93,7.93,0,0,1-7.16-4.45,1,1,0,0,0-1.8.9A10,10,0,1,0,12,2Z'
                           />
                        </svg>
                        <span>Logout</span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ProfilePopup;

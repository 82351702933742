import ContactMain from "./ContactMain";

const Contacts = () => {
  return (
    <div style={{ position: "relative" }}>
      <div id="stars"></div>
      <div id="stars3"></div>

      <ContactMain />
    </div>
  );
};

export default Contacts;

import React, { useEffect } from "react";
import "./ContactCard.css";
import phone from "../../assets/svg/phone.svg";
import envelope from "../../assets/svg/envelope.svg";
import instagram from "../../assets/svg/instagram.svg";
import linkedin from "../../assets/svg/linkedin.svg";

function ContactCard({ details }) {
   // useEffect(() => {
   //    Aos.init({ duration: 2000 });
   // }, []);

   const [isVisible, setVisible] = React.useState(true);
   const domRef = React.useRef();
   useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
         entries.forEach((entry) => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
   }, []);
   return (
      <div className={`purecss-fade-in-section ${isVisible ? "is-visible" : ""}`} ref={domRef}>
         <div className='contact-card'>
            <div className='contact-card-img'>
               <img className='contact-img' src={details.img} alt='' />
            </div>
            <div className='contact-card-body text-center'>
               <h3 className='contact-name'>{details.name}</h3>
               <span className='contact-design'>{details.desig}</span>
               <ul className='contact-card-links'>
                  <li>
                     <a href={"tel:" + details.phno} target='_blank' rel='noopener noreferrer' aria-label='facebook social link' className='icon icon-xs icon-facebook mr-3'>
                        <img className='phone' src={phone} alt='phone' width='25' height='25' />
                     </a>
                  </li>
                  <li>
                     <a href={"mailto:" + details.email} target='_blank' rel='noopener noreferrer' aria-label='twitter social link' className='icon icon-xs icon-twitter mr-3'>
                        <img src={envelope} alt='envelope' width='25' height='25' />
                     </a>
                  </li>
                  <li>
                     <a href={details.insta} target='_blank' rel='noopener noreferrer' aria-label='slack social link' className='icon icon-xs icon-slack mr-3'>
                        <img src={instagram} alt='instagram' width='25' height='25' />
                     </a>
                  </li>
                  <li>
                     <a href={details.linkln} target='_blank' rel='noopener noreferrer' aria-label='dribbble social link' className='icon icon-xs icon-dribbble mr-3'>
                        <img src={linkedin} alt='linkedin' width='25' height='25' />
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   );
}

export default ContactCard;

import axios from "axios";

import AuthHelper from "./authHelper";
import { apiBaseURL, authBaseURL } from "./urls";

export default class AccountHelper {
   static logUserIn = () => {
      if (!this.isUserLoggedIn()) window.location.href = `${authBaseURL}/auth/login?redirect_to=` + window.location;
   };

   static logUserOut = () => {
      if (this.isUserLoggedIn()) {
         AuthHelper.clearAllTokens();
         window.location.href = `${authBaseURL}/auth/logout?redirect_to=` + window.location;
      }
   };

   static isUserLoggedIn = () => {
      return AuthHelper.getRefreshToken() != null;
   };

   static asyncGetUserProfile = async () => {
      let profile = null;
      if (this.isUserLoggedIn()) {
         let access_token = await AuthHelper.aysncGetAccessToken();
         if (access_token != null) {
            try {
               let res = await axios.get(`${apiBaseURL}/api/Profile/view`, {
                  headers: { Authorization: `Bearer ${access_token}` },
               });

               if (res.status === 200) profile = res.data;
            } catch (err) {
               console.log("Failed to fetch user profile");
            }
         }
      }

      return profile;
   };
}

import { useState } from "react";

import RadioButton from "../../components/RadioButton";
import "./SchedulePopup.css";

const SchedulePopup = ({ filters, selectedFilters, setSelectedFilters, close }) => {
   const [selectedCategory, setSelectedCategory] = useState(selectedFilters.category);
   const [selectedEventType, setSelectedEventType] = useState(selectedFilters.eventType);

   return (
      <div className=''>
         {" "}
         <div className='schedulePopupContainer '>
            <div className='schedule-popup-content'>
               <div className='close-container-mobile ' onClick={() => close()}>
                  <svg className='close-button' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 252 252'>
                     <path d='M126 0C56.523 0 0 56.523 0 126s56.523 126 126 126 126-56.523 126-126S195.477 0 126 0zm0 234c-59.551 0-108-48.449-108-108S66.449 18 126 18s108 48.449 108 108-48.449 108-108 108z' />
                     <path d='M164.612 87.388a9 9 0 00-12.728 0L126 113.272l-25.885-25.885a9 9 0 00-12.728 0 9 9 0 000 12.728L113.272 126l-25.885 25.885a9 9 0 006.364 15.364 8.975 8.975 0 006.364-2.636L126 138.728l25.885 25.885c1.757 1.757 4.061 2.636 6.364 2.636s4.606-.879 6.364-2.636a9 9 0 000-12.728L138.728 126l25.885-25.885a9 9 0 00-.001-12.727z' />
                  </svg>
               </div>
               <div className='filter-popup'>
                  <div className='schedulePopupTitle'>Categories</div>
                  <div className='schedulePopupOptionsContainer'>
                     <RadioButton value='All' selected={selectedCategory} text='All' color='#fff' onChange={setSelectedCategory} />
                     {filters.category.map((item, id) => (
                        <RadioButton key={id} value={item} selected={selectedCategory} text={item.split("_").join(" ")} color='#fff' onChange={setSelectedCategory} />
                     ))}
                  </div>
                  <div className='schedulePopupTitle'>Event type</div>
                  <div className='schedulePopupOptionsContainer'>
                     <RadioButton value='All' selected={selectedEventType} text='All' color='#fff' onChange={setSelectedEventType} />
                     {filters.eventType.map((item, id) => (
                        <RadioButton key={id} value={item} selected={selectedEventType} text={item.split("_").join(" ")} color='#fff' onChange={setSelectedEventType} />
                     ))}
                  </div>
               </div>
            </div>
            <button
               onClick={() => {
                  setSelectedFilters({
                     category: selectedCategory,
                     eventType: selectedEventType,
                     eventStatus: /*selectedEventStatus*/ "All",
                  });
                  close();
               }}
               className='schedulePopupButton'>
               Filter
            </button>
         </div>
      </div>
   );
};

export default SchedulePopup;

import { useEffect, useState } from "react";
// import axios from 'axios';
import Popup from "reactjs-popup";

import ScheduleCard from "./ScheduleCard";
import SchedulePopup from "./SchedulePopup";
import DeadEnd from "../../components/DeadEnd";
import Loader from "../../components/Loader/Loader";
import schedulejson from "../../data/schedule.json";
import constantsjson from "../../data/scheduleconstants.json";
import filter from "../../assets/svg/filter.svg";

import "./ScheduleMain.css";

const ScheduleMain = () => {
   const [scheduleData, setScheduleData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [selectedDay, setSelectedDay] = useState(1);
   const [filteredData, setFilteredData] = useState([]);
   const [filters, setFilters] = useState([]);
   const [selectedFilters, setSelectedFilters] = useState({
      category: "All",
      eventType: "All",
      eventStatus: "All",
   });

   useEffect(() => {
      setScheduleData(schedulejson);
      setFilters(constantsjson);
      setIsLoading(false);
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      if (scheduleData.length !== 0) {
         let x = scheduleData.find((e) => e.day === selectedDay);
         console.log("e.category", x, "selectedFilters.eventType", selectedFilters.category);
         if (x) {
            x = x.events;
            if (selectedFilters.category !== "All") {
               x = x.filter((e) => e.category === selectedFilters.category);
            }
            if (selectedFilters.eventType !== "All") {
               x = x.filter((e) => e.eventType === selectedFilters.eventType);
            }
            if (selectedFilters.eventStatus !== "All") {
               x = x.filter((e) => e.eventStatus === selectedFilters.eventStatus);
            }
         } else {
            // **** backend guys for not sending data for day 1
            setSelectedDay(scheduleData[0].day);
         }
         setFilteredData({ day: selectedDay, events: x });
      } else {
         // **** backend guys for sending empty datas
         setFilteredData({ day: selectedDay, events: [] });
      }
   }, [scheduleData, selectedDay, selectedFilters]);

   return isLoading ? (
      <Loader />
   ) : (
      <div className='schedule schedule-body'>
         <h1 className='page-title schedule-page-title'>Schedule</h1>
         <div className='scheduleContainer'>
            <Popup
               trigger={
                  <div className='scheduleFilter'>
                     <span className='scheduleFilterInsensitive' />
                     <div className='scheduleFilterSensitive'>
                        <img src={filter} width='20' height='20' alt='' /> Filter
                     </div>
                  </div>
               }>
               {(close) => <SchedulePopup filters={filters} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} close={close} />}
            </Popup>
            <div className='scheduleDayContainer'>
               {scheduleData.map((item, id) => (
                  <div className={item.day === selectedDay ? "scheduleSelectedDay" : "scheduleDay"} key={id} onClick={() => setSelectedDay(item.day)}>
                     Week {item.day}
                  </div>
               ))}
            </div>
            <div className='sheduledata__container'>
               <div className='sheduledata__container2'>{filteredData.length === 0 || filteredData.events.length === 0 ? <DeadEnd /> : filteredData.events.map((item) => <ScheduleCard event={item} />)}</div>
            </div>
         </div>
      </div>
   );
};

export default ScheduleMain;

import { Component } from "react";
import ContactCard from "./ContactCard";
import "./ContactMain.css";
import anjali from "../../assets/coreteam_img/anjali.png";
import al_ameen from "../../assets/coreteam_img/al_ameen.png";
import adil from "../../assets/coreteam_img/adil.png";
import ameen from "../../assets/coreteam_img/ameen.png";
import ravi from "../../assets/coreteam_img/ravi.png";
import antony from "../../assets/coreteam_img/antony.png";
import varadha from "../../assets/coreteam_img/varadha.png";
import pranav from "../../assets/coreteam_img/pranav.png";
import kurup from "../../assets/coreteam_img/kurup.png";
import kim from "../../assets/coreteam_img/kim.png";
import annu from "../../assets/coreteam_img/annu.png";
import afnan from "../../assets/coreteam_img/afnan.png";
import sandra from "../../assets/coreteam_img/sandra.png";
import prithvi from "../../assets/coreteam_img/prithvi.png";
import denil from "../../assets/coreteam_img/denil.png";
import aiswarya from "../../assets/coreteam_img/aiswarya.png";
import geetika from "../../assets/coreteam_img/geetika.png";
import hari from "../../assets/coreteam_img/hari.png";
import jishnudev from "../../assets/coreteam_img/jishnudev.png";
import malavika from "../../assets/coreteam_img/malavika.png";
import shreyas from "../../assets/coreteam_img/shreyas.png";
import sreerenjima from "../../assets/coreteam_img/sreerenjima.png";
export default class Contacts extends Component {
   constructor(props) {
      super(props);
      this.state = {
         cardInfo: [
            {
               desig: "Chairperson",
               name: "Adil Rafeek",
               email: "adilrafeek.mec@gmail.com",
               phno: "+919895529739",
               img: adil,
               insta: "https://www.instagram.com/_adilrafeek_/?hl=en",
               linkln: "https://www.linkedin.com/in/adil-rafeek",
            },
            {
               desig: "General Secretary",
               name: "Ameen Azeez",
               email: "ameenazeez.mec@gmail.com",
               phno: "+919633288284",
               img: ameen,
               insta: "https://www.instagram.com/_ibn_azeez_/?utm_medium=copy_link",
               linkln: "https://www.linkedin.com/in/ameen-azeez",
            },
            {
               desig: "Joint Secretary",
               name: "Denil Geo Daise",
               email: "denilgeodaise.mec@gmail.com",
               phno: "+917902394958",
               img: denil,
               insta: "https://www.instagram.com/thedenildaise/?hl=en",
               linkln: "https://www.linkedin.com/in/denil-daise-3215521a7",
            },
            {
               desig: "Event Manager",
               name: "Abhishek S Kurup",
               email: "abhishekskurup.mec@gmail.com",
               phno: "+916282531021",
               img: kurup,
               insta: "https://instagram.com/abhiishek_satya?utm_medium=copy_link",
               linkln: "https://www.linkedin.com/in/abhishek-s-kurup-449981167",
            },
            {
               desig: "Event Manager",
               name: "Al Ameen N",
               email: "alameenn.mec@gmail.com",
               phno: "+916238834398",
               img: al_ameen,
               insta: "https://instagram.com/rainman.99?utm_medium=copy_link",
               linkln: "https://www.linkedin.com/in/al-ameen-n-203b10190",
            },
            {
               desig: "Finance Manager",
               name: "Jishnudev V V",
               email: "jishnudev.mec@gmail.com",
               phno: "+919495416121",
               img: jishnudev,
               insta: "https://www.instagram.com/jishnu_7777/",
               linkln: "https://www.linkedin.com/in/jishnudev-v-v-a133a11ba/",
            },

            {
               desig: "Marketing Manager",
               name: "Anjali Joy",
               email: "anjalijoy.mec@gmail.com",
               phno: "+919497530894",
               img: anjali,
               insta: "https://instagram.com/_._anjalii_.__?utm_medium=copy_link",
               linkln: "https://www.linkedin.com/in/anjali-joy-699b571a4",
            },

            {
               desig: "Web Manager",
               name: "Annu Jolly",
               email: "annujolly17@gmail.com",
               phno: "+919188058865",
               img: annu,
               insta: "",
               linkln: "https://www.linkedin.com/in/annu-jolly/",
            },
            {
               desig: "Conference and Workshops Manager",
               name: "Sreerenjima Menon",
               email: "sreerenjima.mec@gmail.com",
               phno: "+917902287560",
               img: sreerenjima,
               insta: "https://www.instagram.com/_._sree_._/",
               linkln: "https://www.linkedin.com/in/sreerenjimam-238b32136/",
            },
            {
               desig: "Publicity Manager",
               name: "Varadha M S",
               email: "varadhasalar.mec@gmail.com",
               phno: "+919072234345",
               img: varadha,
               insta: "https://instagram.com/happy._.beee?utm_medium=copy_link",
               linkln: "https://www.linkedin.com/in/varadha-m-s-0b9a281ba",
            },
            {
               desig: "Non-Tech Manager",
               name: "Geetika PS",
               email: "mailto:geetikaps.mec@gmail.com",
               phno: "+919020228610",
               img: geetika,
               insta: "https://instagram.com/_geetika_muth_?utm_medium=copy_link",
               linkln: "https://www.linkedin.com/in/geetika-ps-3928221a0/",
            },
            {
               desig: "CS-Tech Manager",
               name: "Afnan Navaz",
               email: "afnannavaz.mec@gmail.com",
               phno: "+917994062437",
               img: afnan,
               insta: "https://www.instagram.com/afnannavaz/",
               linkln: "https://www.linkedin.com/in/afnan-navaz/",
            },

            {
               desig: "Media Relations Manager",
               name: "Sandra Sam",
               email: "sandrasam.mec@gmail.com",
               phno: "+919496494841",
               img: sandra,
               insta: "https://www.instagram.com/sandra._.sam/ ",
               linkln: "https://www.linkedin.com/in/sandra-sam-42034b1b7",
            },

            {
               desig: "General Tech Manager",
               name: "Hari Govind P K",
               email: "harigovindpk2000.mec@gmail.com",
               phno: "+919400426744",
               img: hari,
               insta: "",
               linkln: "https://www.linkedin.com/in/hari-govind-p-k-76b262178/",
            },
            {
               desig: "Online Media Relations Manager",
               name: "Pranav Mohan",
               email: "pranavmohan.mec@gmail.com",
               phno: "+917012030292",
               img: pranav,
               insta: "https://instagram.com/pranav___mohan?utm_medium=copy_link",
               linkln: "https://www.linkedin.com/in/pranav-mohan-a628b01ab",
            },
            {
               desig: "Social Initiatives Manager",
               name: "Prithvi Chandra",
               email: "prithvichandra.mec@gmail.com",
               phno: "+917034156677",
               img: prithvi,
               insta: "https://www.instagram.com/prithvi._.chandra/",
               linkln: "https://www.linkedin.com/in/prithvi-chandra-119bb4184",
            },
            {
               desig: "Talks Manager",
               name: "Aiswarya S Menon",
               email: "rosebinoymechery.mec@gmail.com",
               phno: "+918078201072",
               img: aiswarya,
               insta: "https://instagram.com/r._.o._.se?igshid=1v2mkyxe8w7mm",
               linkln: "https://www.linkedin.com/in/rose-binoy-mechery-215582160",
            },
            {
               desig: "Marketing Manager (PC)",
               name: "M A Ravishankar",
               email: "maravishankar.mec@gmail.com",
               phno: "+919847117176",
               img: ravi,
               insta: "",
               linkln: "https://www.linkedin.com/in/ma-ravishankar",
            },
            {
               desig: "Design Manager",
               name: "Antony Santhosh",
               email: "antonysanthosh.mec@gmail.com",
               phno: "+919188600226",
               img: antony,
               insta: "https://www.instagram.com/___.antony/",
               linkln: "https://www.linkedin.com/in/antonysanthosh/",
            },
            {
               desig: "Content Manager",
               name: "Malavika S Menon",
               email: "malavikasmenon.mec@gmail.com",
               phno: "+919745637193",
               img: malavika,
               insta: "https://www.instagram.com/viksmals/",
               linkln: "https://www.linkedin.com/in/malavika-s-menon",
            },
            {
               desig: "Conferences Manager (PC)",
               name: "Kim Abel Thomas",
               email: "kimabelthomas.mec@gmail.com",
               phno: "+919745203920",
               img: kim,
               insta: "https://instagram.com/_kimabel_?utm_medium=copy_link",
               linkln: "https://www.linkedin.com/in/kimabelthomas",
            },

            {
               desig: "Ambience Manager",
               name: "Shreyas P",
               email: "shreyasp.mec@gmail.com",
               phno: "+918943838906",
               img: shreyas,
               insta: "https://instagram.com/sh_re_yas/",
               linkln: "https://www.linkedin.com/in/shreyas-p-7a3a451ba/",
            },
         ],
      };
   }
   componentDidMount() {
      window.scrollTo(0, 0);
   }
   render() {
      var grid = [];
      for (var i = 0; i < this.state.cardInfo.length; i++) {
         var gridItem = <ContactCard key={i} details={this.state.cardInfo[i]} />;
         grid.push(gridItem);
      }
      return (
         <div className='contacts contacts-body '>
            <div>
               <h2 className='page-title'>Contacts</h2>
               <div className='container'>{grid}</div>
            </div>
         </div>
      );
   }
}

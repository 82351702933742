import EventsContainer from "./events-container";

const Events = () => {
  return (
    <div className="events-page" style={{ position: "relative" }}>
      <div id="stars"></div>
      <div id="stars3"></div>

      <EventsContainer />
    </div>
  );
};

export default Events;

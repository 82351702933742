import { useState } from "react";
import Popup from "reactjs-popup";
import ProfilePopup from "./ProfilePopup";

import "./index.css";
import loggedIn from "../../assets/png/loggedIn.png";
import profilepic from "../../assets/svg/profilepic.svg";
import AccountHelper from "../../utils/accountHelper";

const NavProfileIcon = ({ isMobile = false }) => {
   const [profile, setProfile] = useState(null);
   let token = window.localStorage.getItem("refreshToken");

   let onProfileClicked = () => {
      if (!AccountHelper.isUserLoggedIn()) {
         AccountHelper.logUserIn();
      }
   };

   if (profile == null) {
      AccountHelper.asyncGetUserProfile()
         .then((res) => {
            if (res != null) setProfile(res);
         })
         .catch(() => console.log("Failed to fetch profile pic"));
   }

   if (token == null) {
      return (
         <div className='navbarProfile smallSidebarLink login_status' onClick={onProfileClicked}>
            <img className='navbarProfileIcon ' alt='default' src={profilepic} />
            <span className='toolTip1'>
               <p className='toolTipText1'>Login</p>
            </span>
         </div>
      );
   }
   if (token != null) {
      return (
         <div className='navbarProfile smallSidebarLink login_status' onClick={onProfileClicked}>
            <Popup trigger={<div>{profile ? <img className='navbarProfileIcon ' alt={profile.name} src={profile.picture} /> : <img className='navbarProfileIcon ' alt='default' src={loggedIn} />}</div>}>{(close) => <ProfilePopup userprofile={profile} token={token} close={close} />}</Popup>
         </div>
      );
   }
};

export default NavProfileIcon;

import { NavLink } from "react-router-dom";

import "./SmallSidebar.css";
import home from "../../assets/svg/home.svg";
import competitions from "../../assets/svg/competitions.svg";
import events from "../../assets/svg/events.svg";
import schedule from "../../assets/svg/schedule.svg";
import contacts from "../../assets/svg/contacts.svg";

import NavProfileIcon from "./../NavProfileIcon";

const SmallSidebar = () => {
   return (
      <div className='smallSidebarContainer '>
         <div className='smallSidebarInnerContainer'>
            <NavLink exact to='/' style={{ textDecoration: "none" }} activeClassName='smallSidebarActive'>
               <div className='smallSidebarLink'>
                  <img alt='home' src={home} className='smallSidebarIcon' />
                  <span className='toolTip'>
                     <p className='toolTipText'>Home</p>
                  </span>
               </div>
            </NavLink>

            <NavLink to='/competitions' style={{ textDecoration: "none" }} activeClassName='smallSidebarActive'>
               <div className='smallSidebarLink'>
                  <img alt='competitions' src={competitions} className='smallSidebarIcon' />
                  <span className='toolTip '>
                     <p className='toolTipText'>Competitions</p>
                  </span>
               </div>
            </NavLink>

            <NavLink to='/events' style={{ textDecoration: "none" }} activeClassName='smallSidebarActive'>
               <div className='smallSidebarLink'>
                  <img alt='events' src={events} className='smallSidebarIcon' />
                  <span className='toolTip '>
                     <p className='toolTipText'>Events</p>
                  </span>
               </div>
            </NavLink>
            <NavLink to='/schedule' style={{ textDecoration: "none" }} activeClassName='smallSidebarActive'>
               <div className='smallSidebarLink'>
                  <img alt='schedule' src={schedule} className='smallSidebarIcon' />
                  <span className='toolTip'>
                     <p className='toolTipText'>Schedule</p>
                  </span>
               </div>
            </NavLink>
            <NavLink to='/contacts' style={{ textDecoration: "none" }} activeClassName='smallSidebarActive'>
               <div className='smallSidebarLink'>
                  <img alt='contacts' src={contacts} className='smallSidebarIcon' />
                  <span className='toolTip'>
                     <p className='toolTipText'>Contacts</p>
                  </span>
               </div>
            </NavLink>
            <NavProfileIcon isMobile={false} />
         </div>
      </div>
   );
};

export default SmallSidebar;

import React from "react";

import mec_logo from "../../../src/assets/png/mec_logo.png";
import excel2021_logo from "../../../src/assets/svg/logo.svg";
import insta_logo from "../../../src/assets/png/instagram3.png";
import linkedin_logo from "../../../src/assets/png/linkedin1.png";
import yt_logo from "../../../src/assets/png/youtube.png";
import twitter_logo from "../../../src/assets/png/twitter.png";

import "./footer.css";
function Footer() {
   return (
      <div>
         <div className='footer_div '>
            <span className='desktopViewMecLogo'>
               <a onClick={() => window.open("https://www.mec.ac.in/")}>
                  <img className='footerLogo mec_logo' src={mec_logo} alt='mec_logo' />
               </a>
            </span>
            <a onClick={() => window.open("https://www.instagram.com/excelmec/")}>
               <img className='footerLogo social_logo insta_log' src={insta_logo} alt='insta' />
            </a>
            <a onClick={() => window.open("https://twitter.com/excelmec/")}>
               <img className='footerLogo social_logo' src={twitter_logo} alt='twitter' />
            </a>
            <a onClick={() => window.open("https://www.linkedin.com/company/excelmec/")}>
               <img className='footerLogo social_logo' src={linkedin_logo} alt='linkedin' />
            </a>
            <a onClick={() => window.open("https://www.youtube.com/c/excelmec")}>
               <img className='footerLogo social_logo' src={yt_logo} alt='youtube' />
            </a>

            <span className='desktopViewMecLogo'>
               {" "}
               <a onClick={() => window.open("https://www.excelmec.org/")}>
                  <img className='footerLogo excel2021_logo' src={excel2021_logo} alt='excel' />
               </a>
            </span>
            <div className='row mobileViewMecLogo'>
               <br />
               <span>
                  <a onClick={() => window.open("https://www.excelmec.org/")}>
                     <img className='footerLogo mobileViewMecLogoexcel2021_logo' src={mec_logo} alt='mec' />
                  </a>
               </span>
               <a onClick={() => window.open("https://www.excelmec.org/")}>
                  <img className='footerLogo mobileViewMecLogoexcel2021_logo' src={excel2021_logo} alt='excel' />
               </a>
            </div>
            <p className='descrip text-center'>Made with ♥ Excel 2021</p>
         </div>
      </div>
   );
}

export default Footer;
